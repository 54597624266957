<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :content-classes="MODAL_CONFIG.contentClasses"
  >
    <template #content>
      <div class="flex flex-col items-center justify-center">
        <p class="text-truegray-500 mb-6 text-sm">
          This is for people who have received a voting paper in the mail due to
          no email address listed. If you have an email address listed with your
          school, check your emails for your unique voting link.
        </p>
        <FormKit
          v-model="form"
          type="form"
          :config="{
            classes: {
              form: 'w-2/3',
              outer: '$remove:max-w-[20em] w-full',
            },
          }"
          :submit-attrs="{
            inputClass: 'mx-auto brand__button--primary mt-6',
          }"
          @submit="onSubmit"
        >
          <FormKit
            type="text"
            name="lastName"
            label="Last Name"
            placeholder="Enter your last name"
            validation="required"
          />
          <FormKit
            type="text"
            name="votingToken"
            label="Voting Token"
            placeholder="Enter your voting token"
            validation="required"
          />
        </FormKit>
        <p
          v-if="attemptsLeft !== null"
          class="text-red-500 text-center text-sm mt-6"
        >
          We could not find an eligible voter with that token and last name. You
          have
          {{ attemptsLeft }} attempt<span v-if="attemptsLeft !== 1"></span> left
          before being timed out. If you have checked your token thoroughly and
          feel this is in error, reach out to us via the contact button.
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import Modal from './Modal.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'ModalManualVoting',

  components: {
    Modal,
  },

  props: {},

  setup() {
    const store = useStore()

    const MODAL_CONFIG = {
      trigger: {
        text: 'Vote',
        as: 'button',
        classes: 'brand__button--primary justify-self-end',
      },

      contentClasses:
        'max-h-[85vh] overflow-y-auto p-16 bg-primary flex flex-col items-center justify-center',
    }

    const selectedElection = computed(
      () => store.getters['elections/getCurrentElection'],
    )

    const router = useRouter()
    const route = useRoute()
    const attemptsLeft = computed(() => store.getters['elections/attemptsLeft'])
    const timedOut = computed(() => store.getters['elections/isTimedOut'])

    watch(timedOut, (val) => {
      if (val) {
        alert(
          'You have been timed out due to too many failed attempts. Please try again later.',
        )
      }
    })

    const form = ref({
      lastName: '',
      votingToken: '',
      electionId: selectedElection.value.id,
    })

    const onSubmit = async () => {
      const payload = {
        lastName: form.value.lastName,
        votingToken: form.value.votingToken,
        electionId: form.value.electionId,
      }
      const response = await store.dispatch(
        'elections/dispatchAuthoriseVoter',
        payload,
      )

      if (response) {
        router.push({
          name: 'schoolVote',
          params: {
            clientSlug: route.params.clientSlug,
            electionId: form.value.electionId,
          },
          query: {
            votingToken: form.value.votingToken,
          },
        })
      }
    }

    return {
      MODAL_CONFIG,
      form,
      onSubmit,
      attemptsLeft,
    }
  },
})
</script>

<template>
  <Modal
    :content-classes="MODAL_CONFIG.contentClasses"
    :is-dialog-open="isModalOpen"
    @update-modal-state="updateIsModalOpen"
  >
    <template #trigger>
      <DialogTrigger>
        <slot name="trigger" />
      </DialogTrigger>
    </template>

    <template #content>
      <span v-html="eligibilityTerms" />
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import Modal from '../../../components/modal/Modal.vue'
import { DialogTrigger } from 'radix-vue'

export default defineComponent({
  name: 'EditorLinkModal',

  components: {
    Modal,
    DialogTrigger,
  },

  setup() {
    const store = useStore()
    const eligibilityTerms = computed(
      () => store.getters['elections/getCurrentElectionEligibilityTerms'],
    )

    const MODAL_CONFIG = {
      contentClasses: 'max-h-[85vh] overflow-y-auto p-14',
    }

    const isModalOpen = ref(true)

    const updateIsModalOpen = async (val) => {
      isModalOpen.value = val
    }

    return {
      MODAL_CONFIG,
      eligibilityTerms,
      isModalOpen,
      updateIsModalOpen,
    }
  },
})
</script>

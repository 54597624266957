<template>
  <div ref="componentParent" :class="['flex flex-col gap-2', wrapperClass]">
    <div class="w-full">
      <h6 class="text-primary text-sm mb-2">
        {{ title }}
      </h6>
    </div>
    <div v-if="hasMembers" ref="listParent" class="flex flex-col gap-2">
      <div
        v-for="member in members"
        :key="member.email"
        :class="[
          'flex flex-row gap-2 items-center border rounded-lg px-3 py-2 relative',
          {
            'cursor-pointer hover:text-gray-700 hover:bg-gray-50': isEditable,
            'bg-truegray-100': member.deletedAt,
          },
        ]"
        @click="() => handleClick(member)"
      >
        <Logo
          :show-placeholder="true"
          :is-school="false"
          :src="member.logo"
          wrapper-class="h-8 w-8"
          placeholder-wrapper-class="h-8 w-8"
        />

        <div class="flex flex-col">
          <span :title="member.title" class="text-gray-500">
            {{ member.title }}
          </span>
          <span v-if="member.boardRole" :class="['brand__text--info']">
            {{ member.boardRole }}
          </span>

          <span
            v-if="member.deletedAt"
            :class="['text-xxs leading-3 text-red-400']"
          >
            Member removed on {{ member.deletedAt }}
          </span>
        </div>

        <div
          :class="[
            'absolute top-[-4px] right-[-1px] flex items-center justify-center rounded-full w-3 h-3 text-white',
            member.attending ? 'bg-green-500' : 'bg-red-400',
          ]"
        >
          <svg-icon
            :icon-name="member.attending ? 'check' : 'close'"
            class="w-2 h-2"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <span class="text-xs">No </span>
      <span class="lowercase">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { defineComponent, computed } from 'vue'
import Logo from './Logo.vue'

export default defineComponent({
  name: 'MeetingMembersList',

  components: {
    Logo,
  },

  props: {
    title: { type: String, default: '' },
    members: { type: Array, required: true },
    isEditable: { type: Boolean, default: false },
    wrapperClass: { type: String, default: '' },
  },

  emits: ['on-click'],

  setup(props, { emit }) {
    const [componentParent] = useAutoAnimate()
    const [listParent] = useAutoAnimate()
    const hasMembers = computed(() => !!props.members.length)

    const handleClick = (user) => {
      if (props.isEditable) {
        emit('on-click', user)
      }
    }
    return { componentParent, listParent, hasMembers, handleClick }
  },
})
</script>

<template>
  <main
    class="w-full lg:bg-[url('/img/bgpath.svg')] lg:bg-[center_top_5%] lg:bg-norepeat bg-cover sm:p-6 sm:py-14 border-t border-white overflow-y-scroll"
  >
    <SchoolHome v-if="school" />
    <div v-else class="h-full flex flex-col items-center justify-center gap-2">
      <h1 class="m-0">Welcome to the Schooled Election Manager.</h1>
      <h2 class="text-base text-tertiary m-0">
        Click below to find your school to get started.
      </h2>

      <ModalFindMySchool>
        <template #trigger>
          <button
            type="button"
            aria-label="Contact"
            class="brand__button--primary mt-4"
          >
            Find My School
          </button>
        </template>
      </ModalFindMySchool>
    </div>
  </main>
</template>

<script>
import { defineComponent, computed, watch } from 'vue'
import SchoolHome from './SchoolHome.vue'
import ModalFindMySchool from './ModalFindMySchool.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ElectionsHome',
  components: { SchoolHome, ModalFindMySchool },

  setup() {
    const store = useStore()
    const router = useRouter()
    const school = computed(() => store.getters['elections/getSchool'])

    watch(school, () => {
      if (school.value) {
        router.push({
          name: 'schoolHome',
          params: { clientSlug: school.value.slug },
        })
      }
    })
    return { school }
  },
})
</script>

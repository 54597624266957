<template>
  <div ref="parentRef" :class="[CLASS.WRAPPER, 'flex-shrink-0', wrapperClass]">
    <!-- Actual image -->
    <img v-if="imageSrc" :class="CLASS.INNER" :src="imageSrc" :alt="alt" />
    <!-- /Actual image -->

    <!-- Placeholder image -->
    <svg
      v-else-if="showPlaceholder"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      :class="[CLASS.WRAPPER, 'text-primary', placeholderWrapperClass]"
    >
      <path
        fill-rule="evenodd"
        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
        clip-rule="evenodd"
      />
    </svg>
    <!-- /Placeholder image -->
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'
import { useAutoAnimate } from '@formkit/auto-animate/vue'

export default defineComponent({
  name: 'ProfileImage',

  components: {
    Vue3Lottie,
  },

  props: {
    showPlaceholder: { type: Boolean, default: false },
    wrapperClass: { type: String, default: '' },
    placeholderWrapperClass: { type: String, default: '' },

    src: {
      type: String,
      default: '',
    },

    alt: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const CLASS = {
      WRAPPER: 'w-16 h-16',
      INNER: 'w-full h-full rounded-full object-cover',
    }
    const [parentRef] = useAutoAnimate()

    const imageSrc = computed(() => {
      return props.src || null
    })

    return {
      CLASS,
      parentRef,
      imageSrc,
    }
  },
})
</script>

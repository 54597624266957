<template>
  <div v-if="editor" class="flex flex-col w-full">
    <div class="editor-menu items-center px-2 py-1 bg-truegray-100">
      <div class="flex items-center">
        <!-- Headings dropdown -->
        <button
          v-if="isFeatureActive('headings')"
          type="button"
          class="editor-menu__button has-children"
          :class="{
            'is-active':
              activeDropdown === 'headings' || editor.isActive('heading'),
          }"
          @click="toggleDropdown('headings')"
        >
          <EditorIcon name="headings" title="Headings" :has-children="true" />
          <div
            v-if="activeDropdown === 'headings'"
            class="top-[105%] left-[50%] transform -translate-x-1/2 rounded-sm bg-white border border-truegray-300 flex space-x-2 p-2 absolute z-40"
          >
            <button
              v-if="isFeatureActive('h1')"
              type="button"
              class="editor-menu__button justify-center items-center"
              :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            >
              <EditorIcon name="h1" title="Heading 1" />
            </button>

            <button
              v-if="isFeatureActive('h2')"
              type="button"
              class="editor-menu__button justify-center items-center font-primary"
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            >
              <EditorIcon name="h2" title="Heading 2" />
            </button>

            <button
              v-if="isFeatureActive('h3')"
              type="button"
              class="editor-menu__button justify-center items-center font-primary"
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
              <EditorIcon name="h3" title="Heading 3" />
            </button>
          </div>
        </button>
        <!-- /Headings dropdown -->

        <!-- Divider -->
        <!-- <div class="w-[1px] h-[1.2rem] mx-2 bg-truegray-300" /> -->

        <button
          v-if="isFeatureActive('bold')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <EditorIcon name="bold" title="Bold" />
        </button>
        <button
          v-if="isFeatureActive('italic')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <EditorIcon name="italic" title="Italic" />
        </button>
        <!--
          <button
          v-if="isFeatureActive('underline')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()"
          >
          <EditorIcon name="underline" title="Underline" />
          </button> 
        -->

        <!-- Divider -->
        <div
          v-if="isFeatureActive('link')"
          class="w-[1px] h-[1.2rem] mx-2 bg-truegray-300"
        />

        <EditorLinkModal
          v-if="isFeatureActive('link')"
          :original-link-url="currentLinkUrl"
          :original-link-title="currentLinkTitle"
          @update:link="updateLink"
        >
          <template #trigger>
            <button
              type="button"
              class="editor-menu__button"
              :class="{ 'is-active': editor.isActive('link') }"
              @click="handleSetLink"
            >
              <EditorIcon name="link" title="Add or update a link" />
            </button>
          </template>
        </EditorLinkModal>

        <button
          v-if="isFeatureActive('link')"
          type="button"
          class="editor-menu__button"
          :disabled="!editor.can().unsetLink()"
          @click="handleUnsetLink"
        >
          <EditorIcon name="remove_link" title="Unset/remove a link" />
        </button>

        <!-- Lists dropdown -->
        <!--
          <button
          v-if="isFeatureActive('lists')"
          type="button"
          class="editor-menu__button has-children"
          :class="{
          'is-active':
          activeDropdown === 'lists' ||
          editor.isActive('bulletList') ||
          editor.isActive('orderedList'),
          }"
          @click="toggleDropdown('lists')"
          >
          <EditorIcon name="ol" title="Lists" :has-children="true" />
          <div
          v-if="activeDropdown === 'lists'"
          class="top-[105%] left-[50%] transform -translate-x-1/2 rounded-sm bg-white border border-truegray-300 flex space-x-2 p-2 absolute z-40"
          >
          <button
          v-if="isFeatureActive('bulletList')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click="editor.chain().focus().toggleBulletList().run()"
          >
          <EditorIcon name="ul" title="Bullet List" />
          </button>

          <button
          v-if="isFeatureActive('orderedList')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click="editor.chain().focus().toggleOrderedList().run()"
          >
          <EditorIcon name="ol" title="Numbered List" />
          </button>
          </div>
          </button> 
        -->

        <!-- Alignment -->
        <!--
          <button
          v-if="isFeatureActive('alignments')"
          type="button"
          class="editor-menu__button has-children"
          :class="{
          'is-active':
          activeDropdown === 'alignments' ||
          editor.isActive({ textAlign: 'center' }) ||
          editor.isActive({ textAlign: 'right' }),
          }"
          @click="toggleDropdown('alignments')"
          >
          <EditorIcon
          name="align_right"
          title="Text Alignment"
          :has-children="true"
          />
          <div
          v-if="activeDropdown === 'alignments'"
          class="top-[105%] left-[50%] transform -translate-x-1/2 rounded-sm bg-white border border-truegray-300 flex space-x-2 p-2 absolute z-40"
          >
          <button
          v-if="isFeatureActive('alignLeft')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          @click="handleTextAlign('left')"
          >
          <EditorIcon name="align_left" title="Align Left" />
          </button>

          <button
          v-if="isFeatureActive('alignCenter')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          @click="handleTextAlign('center')"
          >
          <EditorIcon name="align_center" title="Align Center" />
          </button>

          <button
          v-if="isFeatureActive('alignRight')"
          type="button"
          class="editor-menu__button"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          @click="handleTextAlign('right')"
          >
          <EditorIcon name="align_right" title="Align Right" />
          </button>
          </div>
          </button> 
        -->
      </div>
    </div>

    <EditorContent :editor="editor" />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import EditorIcon from './EditorIcon.vue'
import EditorLinkModal from './EditorLinkModal.vue'
import Bold from '@tiptap/extension-bold'
// import BulletList from '@tiptap/extension-bullet-list'
import Document from '@tiptap/extension-document'
// NB: https://github.com/ueberdosis/tiptap/issues/2150
// import Focus from '@tiptap/extension-focus'
import Gapcursor from '@tiptap/extension-gapcursor'
// import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import Italic from '@tiptap/extension-italic'
// import ListItem from '@tiptap/extension-list-item'
// import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
// import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
// import TextAlign from '@tiptap/extension-text-align'
// import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'

export default {
  components: {
    EditorContent,
    EditorIcon,
    EditorLinkModal,
  },

  props: {
    activeFeatures: {
      type: Array,

      default: () => [
        'headings',
        'h1',
        'h2',
        'h3',
        'bold',
        'italic',
        // 'underline',
        // 'link',
        // 'remove_link',
        // 'alignments',
        // 'alignLeft',
        // 'alignCenter',
        // 'alignRight',
        // 'lists',
        // 'bulletList',
        // 'orderedList',
      ],
    },
  },

  emits: ['update:content'],

  setup(props, { emit }) {
    const editor = useEditor({
      editorProps: {
        attributes: {
          class: 'prose',
        },
      },

      extensions: [
        Bold,
        // BulletList,
        Document,
        // Focus.configure({
        //   className: 'has-focus',
        //   // focus all so we can select/focus images in tables, etc.
        //   mode: 'all',
        // }),
        Gapcursor,
        // HardBreak,
        Heading,
        Italic,
        // ListItem,
        // OrderedList,
        Paragraph,
        // Placeholder.configure({
        //   placeholder: 'Enter post content...',
        // }),
        Text,
        // TextAlign.configure({
        //   types: ['heading', 'paragraph', 'image', 'h1', 'h2', 'h3'],
        //   alignments: ['left', 'center', 'right'],
        // }),
        // Underline,
        Link.configure({
          autolink: false,
          openOnClick: false,
        }),
      ],

      onUpdate: () => onUpdateContent(),
    })

    const state = computed(() => {
      const { state } = editor.value
      return state
    })

    const selection = computed(() => {
      return state.value?.selection
    })

    const onUpdateContent = () => {
      const html = editor.value.getHTML()
      emit('update:content', html)
    }

    const activeDropdown = ref(null)
    const toggleDropdown = (dropdown) => {
      if (activeDropdown.value === dropdown) {
        activeDropdown.value = null
      } else {
        activeDropdown.value = dropdown
      }
    }

    const isFeatureActive = (feature) => {
      return props.activeFeatures.includes(feature)
    }

    const updateLink = (data) => {
      if (data) {
        const { url: href, title } = data
        const { from, to } = selection.value

        // Replace/insert current selection/empty with link/title
        editor.value
          .chain()
          .focus()
          .insertContentAt({ from, to }, title, {
            updateSelection: true,
          })
          .setTextSelection({ from, to: from + title.length })
          .setLink({ href })
          .run()

        editor.value.commands.focus()
      }
    }

    const currentLinkUrl = computed(() => {
      return editor.value.getAttributes('link').href || ''
    })

    const currentLinkTitle = computed(() => {
      return (
        state.value.doc.textBetween(
          selection.value?.from,
          selection.value?.to,
          ' ',
        ) || ''
      )
    })

    const handleSetLink = async () => {
      editor.value.chain().focus().extendMarkRange('link').run()
    }

    const handleUnsetLink = () => {
      editor.value.chain().focus().extendMarkRange('link').unsetLink().run()
    }

    // const handleTextAlign = (type) => {
    //   editor.value.chain().focus().setTextAlign(type).run()
    // }

    return {
      editor,
      activeDropdown,
      toggleDropdown,
      isFeatureActive,
      currentLinkUrl,
      currentLinkTitle,
      handleSetLink,
      handleUnsetLink,
      updateLink,
      // handleTextAlign,
    }
  },
}
</script>

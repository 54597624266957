<template>
  <div
    :class="[alert ? 'right-8' : 'translate-x-full right-0']"
    class="transition duration-150 ease-in-out transform rounded-full fixed top-4 lg:top-8 flex z-90 space-x-2 bg-white shadow h-12 min-w-52 px-3 justify-start items-center py-2"
    @click="toggleAlert"
  >
    <div
      v-if="alert?.status"
      class="h-full pr-2 border-r border-gray-300 flex justify-center items-center"
    >
      <svg
        v-if="alert.status === 'success'"
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 text-tertiary"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"
        />
      </svg>

      <svg
        v-else-if="alert.status === 'error'"
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 text-red-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <svg
        v-else-if="alert.status === 'warning'"
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 text-yellow-300"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        />
      </svg>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 text-blue-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    <span v-if="alert?.message" class="text-sm">{{ alert.message }}</span>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'AlertToast',

  setup() {
    const store = useStore()

    const alert = computed(() => {
      return store.getters['global/getAlertToast']
    })

    const toggleAlert = () => {
      if (alert.value) {
        // reset alert
        store.dispatch('global/dispatchUpdateAlertToast')
      }
    }

    return {
      alert,
      toggleAlert,
    }
  },
})
</script>

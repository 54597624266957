<template>
  <!-- @todo convert to use Modal.vue with content/trigger slots -->
  <div
    v-if="isVisible"
    class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
  >
    <div
      class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
    >
      <div class="mt-3">
        <div class="p-6 max-w-md mx-auto bg-white rounded-xl relative">
          <button
            type="button"
            class="absolute top-0 right-6 flex items-center justify-center text-black hover:text-ces-teal"
            @click="closeModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <form ref="form" @submit.prevent="handleSubmit">
            <h2 class="text-lg font-bold mb-4 text-center">
              Delete file {{ file.title }}?
            </h2>
            <!-- Submit Button -->
            <button
              type="submit"
              :disabled="!canSubmit"
              :class="[
                'w-full flex justify-center items-center bg-ces-blue text-white py-2 mt-4 rounded-lg',
                { 'cursor-not-allowed': !canSubmit },
              ]"
            >
              <div v-if="isSubmitting" class="h-6 w-6">
                <Vue3Lottie :animation-data="ANIMATION_LOADING.DEFAULT" />
              </div>
              <span v-else> Confirm </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'

export default defineComponent({
  name: 'ModalFileDelete',

  components: {
    Vue3Lottie,
  },

  props: {
    isVisible: Boolean,

    file: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore()
    const { isVisible } = toRefs(props)
    const form = ref(null)
    const isSubmitting = ref(false)

    function closeModal() {
      emit('update:is-visible', false)
    }

    const payload = computed(() => {
      return {
        file_id: props.file.id,
      }
    })

    const canSubmit = computed(() => {
      return !isSubmitting.value
    })

    const handleSubmit = async () => {
      isSubmitting.value = true
      const response = await store.dispatch(
        'portal/dispatchDeleteFile',
        payload.value,
      )
      isSubmitting.value = false

      if (response) {
        closeModal()
      }
    }

    return {
      ANIMATION_LOADING,
      isSubmitting,
      canSubmit,
      closeModal,
      form,
      handleSubmit,
    }
  },
})
</script>

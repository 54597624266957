export function UPDATE_SCHOOL(state, data) {
  state.school = data
}

export function CLEAR_SCHOOL(state) {
  state.school = null
}

export function UPDATE_SETTINGS(state, data) {
  state.settings = data
}

export function CLEAR_SETTINGS(state) {
  state.settings = null
}

export function IS_UPDATING_LOGO(state, data) {
  state.isUpdatingLogo = data
}

export function UPDATE_SCHOOLS(state, data) {
  state.schools = data
}

export function CLEAR_SCHOOLS(state) {
  state.schools = []
}

export function UPDATE_MEETINGS(state, data) {
  state.meetings = data
}

export function CLEAR_MEETINGS(state) {
  state.meetings = null
}

export function UPDATE_CURRENT_MEETING(state, data) {
  state.currentMeeting = data
}

export function CLEAR_CURRENT_MEETING(state) {
  state.currentMeeting = null
}

export function UPDATE_DEFAULT_ATTENDEES(state, data) {
  state.defaultAttendees = data
}

export function CLEAR_DEFAULT_ATTENDEES(state) {
  state.defaultAttendees = []
}

export function UPDATE_REPORTS(state, data) {
  state.reports = data
}

export function CLEAR_REPORTS(state) {
  state.reports = []
}

export function UPDATE_LIBRARY_MEETING_FILES(state, data) {
  state.libraryMeetingFiles = data
}

export function CLEAR_LIBRARY_MEETING_FILES(state) {
  state.libraryMeetingFiles = []
}

export function UPDATE_LIBRARY_RESOURCE_FILES(state, data) {
  state.libraryResourceFiles = data
}

export function CLEAR_LIBRARY_RESOURCE_FILES(state) {
  state.libraryResourceFiles = []
}

export function UPDATE_LIBRARY_MISC_FILES(state, data) {
  state.libraryMiscFiles = data
}

export function CLEAR_LIBRARY_MISC_FILES(state) {
  state.libraryMiscFiles = []
}

export function CLEAR_LIBRARY_FILES(state) {
  state.libraryMeetingFiles = []
  state.libraryResourceFiles = []
  state.libraryMiscFiles = []
}

export function UPDATE_LIBRARY_MEETING_FILE(state, data) {
  const files = state.libraryMeetingFiles
  const index = files.findIndex((file) => file.id === data.id)
  if (index !== -1) {
    files[index] = { ...files[index], ...data }
  }
}

export function UPDATE_LIBRARY_RESOURCE_FILE(state, data) {
  const files = state.libraryResourceFiles
  const index = files.findIndex((file) => file.id === data.id)
  if (index !== -1) {
    files[index] = { ...files[index], ...data }
  }
}

export function UPDATE_LIBRARY_MISC_FILE(state, data) {
  const files = state.libraryMiscFiles
  const index = files.findIndex((file) => file.id === data.id)
  if (index !== -1) {
    files[index] = { ...files[index], ...data }
  }
}

export function DELETE_LIBRARY_MEETING_FILE(state, data) {
  const files = state.libraryMeetingFiles
  const index = files.findIndex((file) => file.id === data)
  if (index !== -1) {
    files.splice(index, 1)
  }
}

export function DELETE_LIBRARY_RESOURCE_FILE(state, data) {
  const files = state.libraryResourceFiles
  const index = files.findIndex((file) => file.id === data)
  if (index !== -1) {
    files.splice(index, 1)
  }
}

export function DELETE_LIBRARY_MISC_FILE(state, data) {
  const files = state.libraryMiscFiles
  const index = files.findIndex((file) => file.id === data)
  if (index !== -1) {
    files.splice(index, 1)
  }
}

export function UPDATE_OWNERS(state, data) {
  state.owners = data
}

export function CLEAR_OWNERS(state) {
  state.owners = []
}

export function UPDATE_MEMBERS(state, data) {
  state.members = data
}

export function CLEAR_MEMBERS(state) {
  state.members = []
}

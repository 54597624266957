<template>
  <main class="flex flex-col bg-gray-primary">
    <PageHeader title="Board Meetings" :breadcrumbs="breadcrumbs">
      <template v-if="isUpserting" #action>
        <button
          type="button"
          class="brand__button--primary gap-2"
          @click="routeToMeetings"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z"
              clip-rule="evenodd"
            />
          </svg>
          Back to Meetings
        </button>
      </template>
    </PageHeader>

    <PageContent>
      <template #default>
        <template v-if="isUpserting">
          <div class="flex flex-col w-full">
            <CardMeetingUpsert />
          </div>
        </template>
        <template v-else>
          <div class="relative">
            <!-- Drawers -->
            <PageDrawer
              :key="primaryDrawerItems.length + 1"
              :is-loading="isLoading"
              :primary-drawer-title="PRIMARY_DRAWER_CONFIG.TITLE"
              :primary-drawer-title-collapsed="
                PRIMARY_DRAWER_CONFIG.TITLE_COLLAPSED
              "
              :primary-drawer-icon="PRIMARY_DRAWER_CONFIG.ICON"
              :primary-drawer-items="primaryDrawerItems"
              no-items-text="Add a new meeting to get started..."
              @update:activeItemValue="setActiveMeetingValue"
            >
              <!-- Example slot if you want to style primary list items differently to default! -->
              <!--
                <template
                #primary-item="{ item, isPrimaryDrawerCollapsed, isActive }"
                >
                <div
                :class="[
                'some classes you want',
                {
                'some class': isPrimaryDrawerCollapsed,
                'some other class': isActive,
                },
                ]"
                >
                {{ item.whatever }} and anything else you want customised
                depending on drawer props/state!
                </div>
                </template> 
              -->
              <template #primary-action="{ isPrimaryDrawerCollapsed }">
                <button
                  v-if="!isPrimaryDrawerCollapsed && isAdmin"
                  type="button"
                  class="brand__button--primary"
                  @click="routeToAddMeeting"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"
                    />
                  </svg>

                  Add meeting
                </button>
              </template>
            </PageDrawer>
            <!-- /Drawers -->

            <!-- Active meeting -->
            <div
              ref="activeMeetingParent"
              class="flex flex-grow h-full pb-12 pl-12"
            >
              <CardMeeting
                v-if="activeMeeting"
                :key="activeMeeting.title"
                :data="activeMeeting"
                @meeting-deleted="meetingDeletedResponse"
              />
            </div>
            <!-- /Active meeting -->
          </div>
        </template>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import PageDrawer from '../../components/PageDrawer.vue'
import CardMeeting from '../../components/CardMeeting.vue'
import CardMeetingUpsert from '../../components/CardMeetingUpsert.vue'

export default defineComponent({
  name: 'PortalBoardMeetings',

  components: {
    PageHeader,
    PageContent,
    PageDrawer,
    CardMeeting,
    CardMeetingUpsert,
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(true)
    const isAdmin = computed(() => store.getters['auth/isAdmin'])

    const MEETINGS_ROUTE_NAME = 'boardMeetings'
    const ADD_MEETING_ROUTE_NAME = 'boardMeeting'

    const contentWrapperClasses = computed(() => {
      return `flex flex-row gap-8 h-full ${
        !isUpserting.value ? 'pt-8 pl-0 lg:pl-0 pb-0' : ''
      }`
    })

    const meetingId = computed(() => parseInt(route.query?.id) || null)
    const isUpserting = computed(() => route.name === ADD_MEETING_ROUTE_NAME)
    const breadcrumbs = ref([])

    const meetingDeletedResponse = () => {
      activeMeetingValue.value = null
    }

    // Drawer config/state
    const PRIMARY_DRAWER_CONFIG = {
      ICON: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
              <path fill-rule="evenodd" d="M5.75 2a.75.75 0 0 1 .75.75V4h7V2.75a.75.75 0 0 1 1.5 0V4h.25A2.75 2.75 0 0 1 18 6.75v8.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-8.5A2.75 2.75 0 0 1 4.75 4H5V2.75A.75.75 0 0 1 5.75 2Zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75Z" clip-rule="evenodd" />
            </svg>`,

      TITLE: 'Year',
      TITLE_COLLAPSED: 'Meetings',
    }

    const primaryDrawerItems = computed(
      () => store.getters['portal/getMeetingsForDrawer'],
    )

    // Active meeting
    const [activeMeetingParent] = useAutoAnimate()
    const activeMeetingValue = ref(null)

    const activeMeeting = computed(() => {
      const findActiveSecondaryItem = (item) =>
        item.value === activeMeetingValue.value

      const primaryItem =
        primaryDrawerItems.value?.find((primaryItem) =>
          primaryItem.secondaryItems.some(findActiveSecondaryItem),
        ) || null

      const secondaryItem =
        primaryItem?.secondaryItems.find(findActiveSecondaryItem) || null

      return secondaryItem
        ? {
            ...secondaryItem,
            primaryData: primaryItem,
          }
        : null
    })

    const setActiveMeetingValue = (data) => {
      initBreadcrumbs()

      if (data.primary) {
        // Set primary drawer item and update breadcrumbs
        const primaryItem = primaryDrawerItems.value.find(
          (item) => item.value === data.value,
        )
        if (primaryItem) {
          breadcrumbs.value.push(primaryItem.title)
        }
      } else if (data.secondary) {
        // Set secondary drawer item and update breadcrumbs
        activeMeetingValue.value = data.value
        const secondaryTitle = activeMeeting.value?.title

        if (secondaryTitle) {
          breadcrumbs.value.push(secondaryTitle)
          breadcrumbs.value.unshift(activeMeeting.value?.primaryData?.title)
        }
      }
    }

    const routeToMeetings = () => {
      router.push({
        name: MEETINGS_ROUTE_NAME,
        params: { id: route.params.id },
        query: { meeting: activeMeetingValue.value },
      })
    }

    const routeToAddMeeting = () => {
      router.push({
        name: ADD_MEETING_ROUTE_NAME,
        params: { id: route.params.id },
      })
    }

    const initBreadcrumbs = () => {
      breadcrumbs.value = []
      if (isUpserting.value) {
        if (!meetingId.value) {
          breadcrumbs.value.push('New meeting')
        }
      }
    }

    // @TODO Portal 2.0 - Refreshing on a meeting route doesn't work as getters haven't
    // run for meeting data yet. Implement proper routing to specific meetings to fix this
    // and the hacky routing/active meeting setting below.
    const init = async () => {
      isLoading.value = true
      // Kick out losers without access trying to pull a sneak
      if (meetingId.value && !isAdmin.value) {
        router.push({ name: 'dashboard', params: { id: route.params.id } })
      }
      let id = parseInt(route.query?.meeting) || null
      if (id) {
        // Check we can find this meeting!
        const meetingExists = primaryDrawerItems.value.some((primaryItem) =>
          primaryItem.secondaryItems.some(
            (secondaryItem) => secondaryItem.value === id,
          ),
        )

        if (!meetingExists) {
          id = null
        }
      }

      activeMeetingValue.value = id
      if (route.name === 'boardMeetings') {
        // @TODO Portal 2.0 - Hack to immediately wipe the meeting route to fix refresh issues.
        router.push({ name: route.name, params: { ...route.params } })
      }
      initBreadcrumbs()

      if (meetingId.value) {
        setActiveMeetingValue({
          secondary: true,
          value: meetingId.value,
        })
      }

      isLoading.value = false
    }

    watch(
      () => route.name,
      async () => {
        await init()
      },
    )

    onMounted(async () => {
      await init()
    })

    return {
      isAdmin,
      isLoading,
      breadcrumbs,
      contentWrapperClasses,
      meetingDeletedResponse,

      PRIMARY_DRAWER_CONFIG,
      primaryDrawerItems,
      meetingId,
      isUpserting,

      activeMeeting,
      activeMeetingParent,
      setActiveMeetingValue,

      routeToMeetings,
      routeToAddMeeting,
    }
  },
})
</script>

export function UPDATE_IS_LOADING(state) {
  state.isLoading = true
}

export function CLEAR_IS_LOADING(state) {
  state.isLoading = false
}

export function UPDATE_ALERT_TOAST(state, data) {
  state.alertToast = data
}

export function CLEAR_ALERT_TOAST(state) {
  state.alertToast = null
}

<template>
  <main class="bg-gray-primary">
    <PageHeader title="Portal Resources" />

    <PageContent>
      <template #default>
        <div class="w-full">
          <h5 class="mb-8">Articles</h5>
          <div
            class="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 sm:mx-0 lg:max-w-none xl:grid-cols-3"
          >
            <Card v-for="(card, index) in cards" :key="index" :data="card" />
          </div>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import Card from '../../components/Card.vue'

export default defineComponent({
  name: 'PortalResources',

  components: {
    Vue3Lottie,
    PageHeader,
    PageContent,
    Card,
  },

  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const route = useRoute()

    const cards = [
      {
        title: 'Board Culture',
        href: 'https://hail.to/canterbury-education-services/article/Dne48vc',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
        image: '/img/portal/articles/board-culture.jpg',
      },
      {
        title: 'What is Strategic Planning in Education?',
        href: 'https://hail.to/canterbury-education-services/article/GfXjgK4',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
        image: '/img/portal/articles/strategic-planning.jpg',
      },
      {
        title: 'Te Whakangārahu Ngātahi | Planning Together',
        href: 'https://hail.to/canterbury-education-services/article/xE86JFY',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
        image: '/img/portal/articles/planning-together.jpg',
      },
      {
        title: 'What Skills Do Board Members Need?',
        href: 'https://hail.to/canterbury-education-services/article/ENIbHFC',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
        image: '/img/portal/articles/board-members-skills.jpg',
      },
      {
        title: 'Why Join a School Board?',
        href: 'https://hail.to/canterbury-education-services/article/q124e9L',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
        image: '/img/portal/articles/why-join.jpg',
      },
      {
        title: 'What Are Board Minutes?',
        href: 'https://hail.to/canterbury-education-services/article/zuJipOM',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
        image: '/img/portal/articles/board-minutes.jpg',
      },
    ]

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      route,
      cards,
    }
  },
})
</script>

import {
  getNationalities,
  getSchools,
  getElections,
  getSchoolElections,
  getSchoolElection,
  upsertCandidate,
  authoriseVoter,
  processVote,
  sendContactForm,
} from '../../api/elections.js'
import { triggerAlert } from '../util.js'

const handleError = (data = null) => {
  if (data?.context) {
    if (data.mutation) {
      data.context?.commit(data.mutation)
    }

    if (data.alert) {
      triggerAlert(data.context, { data })
    }
  }

  // @todo trigger error messaging
  return false
}

export async function dispatchUpdateNationalities(context) {
  try {
    const response = await getNationalities()

    const data = response.data
    if (data?.success) {
      context.commit('UPDATE_NATIONALITIES', data.data)
      return true
    } else {
      handleError({
        context,
        mutation: 'CLEAR_NATIONALITIES',
      })
    }

    return handleError({
      context,
      mutation: 'CLEAR_NATIONALITIES',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_NATIONALITIES',
    })
  }
}

export async function dispatchUpdateSchools(context) {
  try {
    const response = await getSchools()

    const data = response.data
    if (data?.success) {
      context.commit('UPDATE_SCHOOLS', data.data)
      return true
    } else {
      handleError({
        context,
        mutation: 'CLEAR_SCHOOLS',
      })
    }

    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  }
}

export async function dispatchUpdateElections(context) {
  try {
    const response = await getElections()

    const data = response.data
    if (data?.success) {
      context.commit('UPDATE_ELECTIONS', data.data)
      return true
    } else {
      handleError({
        context,
        mutation: 'CLEAR_ELECTIONS',
      })
    }

    return handleError({
      context,
      mutation: 'CLEAR_ELECTIONS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_ELECTIONS',
    })
  }
}

export async function dispatchUpdateSchool(context, data) {
  try {
    if (data) {
      context.commit('UPDATE_SCHOOL', data)
      return true
    }

    context.commit('CLEAR_SCHOOL')
    return true
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SCHOOL',
    })
  }
}

export async function dispatchUpdateSchoolElections(context) {
  try {
    const clientId = context.getters.getSchoolId
    const response = await getSchoolElections(clientId)

    const data = response.data
    if (data?.success) {
      context.commit('UPDATE_SCHOOL_ELECTIONS', data.data)
      return true
    } else {
      handleError({
        context,
        mutation: 'CLEAR_SCHOOL_ELECTIONS',
      })
    }

    return handleError({
      context,
      mutation: 'CLEAR_SCHOOL_ELECTIONS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SCHOOL_ELECTIONS',
    })
  }
}

export async function dispatchUpdateCurrentElection(context, electionId) {
  try {
    const clientId = context.getters.getSchoolId

    if (clientId && electionId) {
      const response = await getSchoolElection(clientId, electionId)
      const data = response.data

      if (data?.success) {
        context.commit('UPDATE_CURRENT_ELECTION', data.data)
        return true
      } else {
        return handleError({
          context,
          mutation: 'CLEAR_CURRENT_ELECTION',
        })
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_CURRENT_ELECTION',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_CURRENT_ELECTION',
    })
  }
}

export async function dispatchUpsertCandidate(context, payload) {
  try {
    const clientId = context.getters.getSchoolId
    const electionId = context.getters.getCurrentElectionId

    if (clientId && electionId) {
      const response = await upsertCandidate(clientId, electionId, payload)
      const data = response.data

      if (data?.success) {
        triggerAlert(context, response)
        return true
      } else {
        handleError({
          context,
          ...response.data,
        })
      }
    }

    return handleError(null)
  } catch {
    return handleError(null)
  }
}

export async function dispatchAuthoriseVoter(context, payload) {
  try {
    const electionId = payload.electionId

    if (electionId) {
      const response = await authoriseVoter(electionId, payload)
      const data = response.data

      if (data?.success) {
        context.commit('UPDATE_VOTING_DATA', {
          name: data.data.name,
          authorised: true,
          tokenUsed: data.data?.tokenUsed,
        })
      } else {
        context.commit('UPDATE_VOTING_DATA', {
          name: '',
          authorised: false,
          attemptsLeft: data?.attemptsLeft || 0,
          timedOut: !!data?.timeout,
          tokenUsed: false,
        })
      }
      return data?.success
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchAuthorisedVote(_context, payload) {
  try {
    const electionId = payload.electionId

    if (electionId) {
      const response = await processVote(electionId, payload)
      return response
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function toggleSelectedCandidates(context, candidate) {
  try {
    const index = context.state.selectedCandidates.findIndex(
      (c) => c.id === candidate.id,
    )

    // Check if the candidate is already selected
    if (index === -1) {
      // Only add if canSelectAdditionalCandidate is true
      if (context.getters.canSelectAdditionalCandidate) {
        context.commit('UPDATE_SELECTED_CANDIDATES', [
          ...context.state.selectedCandidates,
          candidate,
        ])
      }
    } else {
      // Always allow removal
      const updatedCandidates = context.state.selectedCandidates.filter(
        (c) => c.id !== candidate.id,
      )
      context.commit('UPDATE_SELECTED_CANDIDATES', updatedCandidates)
    }
    return true
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SELECTED_CANDIDATES',
    })
  }
}

export async function dispatchSendContactForm(context, payload) {
  try {
    const clientId = context.getters.getSchoolId
    const electionId = context.getters.getCurrentElectionId

    const response = await sendContactForm(clientId, electionId, payload)
    const data = response.data

    if (data?.success) {
      triggerAlert(context, response)
      return true
    } else {
      handleError({
        context,
        ...response.data,
      })
    }

    return handleError(null)
  } catch {
    return handleError(null)
  }
}

export function dispatchUpdateAccessibilityMode(context, value) {
  context.commit('UPDATE_ACCESSIBILITY_MODE', value)
}

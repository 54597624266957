<template>
  <div class="flex flex-col min-h-full justify-center">
    <div
      class="grid grid-cols-1 sm:grid-cols-12 sm:space-x-12 sm:space-y-8 min-h-full"
    >
      <div
        v-if="!isNominating"
        class="flex flex-col p-10 rounded-none lg:rounded-xl justify-start lg:justify-center items-center lg:items-start col-span-6 relative"
      >
        <a
          v-if="hasElectionsMultiple && selectedElection"
          class="lg:absolute lg:top-12 lg:left-10 flex flex-row items-center gap-2 text-xs mb-12 mr-auto text-truegray-500 hover:text-tertiary"
          tabindex="0"
          @click="() => selectElection(null)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
          All school elections
        </a>
        <!--
          <component
          :is="schoolURL ? 'a' : 'div'"
          v-if="schoolLogo"
          class="h-32 w-32 rounded-full overflow-hidden mb-6"
          :href="schoolURL"
          >
          <img
          :src="schoolLogo"
          :alt="`${school.name} Logo`"
          class="w-full h-full object-cover"
          />
          </component> 
        -->
        <div class="">
          <h5
            :class="[
              selectedElection ? 'text-base' : 'text-4xl',
              accessibilityMode
                ? 'text-primary'
                : selectedElection
                ? 'text-tertiary'
                : 'text-primary',
            ]"
            class="text-center lg:text-left mb-2 transition-all"
          >
            {{ school?.name }}
          </h5>
          <h4
            v-if="selectedElection"
            class="text-primary text-center lg:text-left"
          >
            {{ selectedElection.election_name }}
          </h4>
        </div>

        <p
          v-if="periodSubText"
          class="my-4 text-sm text-center lg:text-left text-truegray-500"
        >
          {{ periodSubText }}
        </p>

        <!-- CTAs -->
        <div
          class="flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0 items-center justify-center mx-auto lg:mx-0 mt-8"
        >
          <div v-if="currentPeriod === 'Nomination Period'">
            <button
              type="button"
              class="brand__button--primary justify-self-end"
              @click="routeToNominationForm"
            >
              Submit a nomination
            </button>
          </div>
          <div v-if="currentPeriod === 'Voting Period'">
            <ModalManualVoting />
          </div>
          <div v-if="currentPeriod === 'Results Released'">
            <button
              type="button"
              class="brand__button--primary justify-self-end"
              @click="scrollToResults"
            >
              View Results
            </button>
          </div>
          <ContactFormModal>
            <template #trigger>
              <button
                type="button"
                class="brand__button--trans-primary justify-self-end"
              >
                Contact Us
              </button>
            </template>
          </ContactFormModal>
        </div>
        <!--
          <p class="text-primary mb-2 text-sm">
          Candidates using this portal that are parents of the school do not need
          to complete a separate paper nomination form.
          </p>
          <p class="text-primary mb-0 text-sm">
          Candidates who are not on the parent roll at the school can also use the
          portal but must also provide us with a paper nomination form
          countersigned by a parent who is on the parent roll. This form can be
          sent to the Returning Officer by email.
          </p> 
        -->
      </div>

      <div
        :class="[
          'flex flex-col gap-4 ',
          isNominating ? 'col-span-12' : 'col-span-6',
        ]"
      >
        <!-- Voting view -->
        <ElectionVoting v-if="isVoting" />
        <!-- /Voting view -->

        <!-- Nomination form -->
        <template v-else-if="isNominating">
          <a
            class="flex flex-row items-center gap-2 text-sm"
            @click="() => selectElection(selectedElection)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
            Back to election details
          </a>
          <ElectionNomination />
        </template>
        <!-- /Nomination form -->

        <!-- Multiple school elections list -->
        <template v-else-if="showingElectionsList">
          <div
            class="bg-white rounded-2xl border border-truegray-200 p-10 flex flex-col justify-center space-y-4 lg:my-auto h-[max-content] lg:w-[max-content] mx-4 lg:mx-0"
          >
            <h4 class="text-primary">Active Elections:</h4>
            <a
              v-for="election in elections"
              :key="election.id"
              class="text-white hover:text-secondary bg-primary px-4 py-2 rounded-xl lg:w-[max-content] text-center lg:text-left"
              @click="() => selectElection(election)"
            >
              {{ election.election_name }}
            </a>
          </div>
        </template>
        <!-- /Multiple school elections list -->

        <!-- Election details card -->
        <template v-else>
          <div
            v-if="selectedElection"
            class="brand__card rounded-none lg:rounded-xl bg-primary shadow-lg text-white flex-grow"
          >
            <div class="flex flex-row text-white scheduleWrapper">
              <div class="flex flex-col">
                <div class="mt-6 mb-12 border-white pb-2">
                  <p
                    v-if="selectedElection"
                    style="width: max-content"
                    class="rounded bg-secondary px-2 py-1 text-xs font-bold text-primary text-center mx-auto mb-6"
                  >
                    {{ currentPeriod }}
                  </p>

                  <p class="text-secondary text-center p-0 m-0">
                    {{ nextEventTitle }}
                    <span v-if="currentPeriod !== 'Results Released'">:</span>
                  </p>
                  <h5
                    class="flex justify-center items-center gap-2 text-center text-white"
                  >
                    {{ timeUntilNextEvent }}
                  </h5>
                </div>

                <div class="flex flex-col lg:border-none rounded-xl">
                  <h5
                    class="text-white text-base uppercase text-center lg:text-left mb-2 lg:mb-0"
                  >
                    Election Schedule
                  </h5>

                  <div
                    :class="[
                      'py-4 border-b border-tertiary',
                      currentPeriod === 'Nomination Period' ? 'active' : '',
                      currentPeriod !== 'Nomination Period' &&
                      isPeriodPassed('Nomination Period')
                        ? 'line-through text-tertiary'
                        : '',
                    ]"
                  >
                    <p
                      class="m-0 text-sm text-tertiary text-center lg:text-left"
                    >
                      Nomination period:
                    </p>
                    <div
                      class="flex flex-col items-center lg:items-start text-sm"
                    >
                      <span class="text-tertiary text-center lg:text-left">
                        {{ selectedElection.nominations_open_long }} to
                        {{ selectedElection.nominations_close_long }}
                      </span>
                    </div>
                  </div>

                  <div
                    :class="[
                      'py-4 border-b border-tertiary',
                      currentPeriod === 'Voting Period' ? 'active' : '',
                      currentPeriod !== 'Voting Period' &&
                      isPeriodPassed('Voting Period')
                        ? 'line-through text-tertiary'
                        : '',
                    ]"
                  >
                    <p
                      class="m-0 text-sm text-tertiary text-center lg:text-left"
                    >
                      Voting period:
                    </p>
                    <div
                      class="flex flex-col items-center lg:items-start text-sm"
                    >
                      <span class="text-tertiary text-center lg:text-left">
                        {{ selectedElection.voting_open_long }} to
                        {{ selectedElection.voting_close_long }}
                      </span>
                    </div>
                  </div>
                  <div
                    :class="[
                      'py-4',
                      currentPeriod === 'Results Released' ? 'active' : '',
                      currentPeriod !== 'Results Released' &&
                      isPeriodPassed('Results Released')
                        ? 'line-through text-tertiary'
                        : '',
                    ]"
                  >
                    <p
                      class="m-0 text-sm text-tertiary text-center lg:text-left"
                    >
                      Results due:
                    </p>
                    <div
                      class="flex flex-col items-center lg:items-start text-sm"
                    >
                      <span class="text-tertiary text-center lg:text-left">
                        {{ selectedElection.results_released_long }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- /Election details card -->
      </div>
    </div>
    <div
      v-if="selectedElection?.candidates"
      id="candidateComponent"
      class="mt-6"
    >
      <ListCandidates
        :candidates="selectedElection.candidates"
        :voting="false"
        :results="currentPeriod === 'Results Released'"
      />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ElectionVoting from './ElectionVoting.vue'
import ListCandidates from '../../../components/elections/ListCandidates.vue'
import ElectionNomination from './ElectionNomination.vue'
import ModalManualVoting from '../../../components/modal/ModalManualVoting.vue'
import ContactFormModal from './ContactFormModal.vue'

export default defineComponent({
  name: 'SchoolHome',

  components: {
    ElectionNomination,
    ElectionVoting,
    ListCandidates,
    ModalManualVoting,
    ContactFormModal,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const store = useStore()

    const accessibilityMode = computed(
      () => store.getters['elections/accessibilityMode'] || null,
    )

    const isVoting = computed(() =>
      store.getters['elections/isVoting'](route.fullPath),
    )
    const isNominating = computed(() =>
      store.getters['elections/isNominating'](route.fullPath),
    )
    const school = computed(() => store.getters['elections/getSchool'] || null)
    const schoolLogo = computed(
      () => store.getters['elections/getSchoolLogo'] || null,
    )
    const schoolURL = computed(
      () => store.getters['elections/getSchoolURL'] || null,
    )

    const hasElections = computed(
      () => store.getters['elections/hasSchoolElections'],
    )
    const hasElectionsMultiple = computed(
      () => store.getters['elections/hasSchoolElectionsMultiple'],
    )
    const elections = computed(
      () => store.getters['elections/getSchoolElections'],
    )
    // schedule computed props
    const currentPeriod = computed(() => {
      if (!selectedElection.value) {
        return null
      }
      const now = new Date()
      const nominationsOpen = new Date(
        Date.parse(selectedElection.value.nominations_open),
      )
      const nominationsClose = new Date(
        Date.parse(selectedElection.value.nominations_close),
      )
      const votingOpen = new Date(
        Date.parse(selectedElection.value.voting_open),
      )
      const votingClose = new Date(
        Date.parse(selectedElection.value.voting_close),
      )
      const resultsReleased = new Date(
        Date.parse(selectedElection.value.results_released),
      )

      if (now < nominationsOpen) {
        return 'Pre Nominations'
      } else if (now < nominationsClose) {
        return 'Nomination Period'
      } else if (now < votingOpen) {
        return 'After Nomination Period'
      } else if (now < votingClose) {
        return 'Voting Period'
      } else if (now < resultsReleased) {
        return 'After Voting Period'
      } else {
        return 'Results Released'
      }
    })

    const isPeriodPassed = (period) => {
      if (!selectedElection.value) {
        return null
      }
      const now = new Date()
      switch (period) {
        case 'nominationsStart':
          return now > new Date(selectedElection.value.nominations_open)
        case 'Nomination Period':
          return now > new Date(selectedElection.value.nominations_close)
        case 'Voting Period':
          return now > new Date(selectedElection.value.voting_close)
        case 'Results Released':
          return now > new Date(selectedElection.value.results_released)
        default:
          return false
      }
    }

    const nextEventTitle = ref('')
    const timeUntilNextEvent = ref('')
    const periodSubText = ref('')

    const updateCountdown = () => {
      const now = new Date()
      let nextEventDate

      switch (currentPeriod.value) {
        case 'Pre Nominations':
          nextEventDate = new Date(
            Date.parse(selectedElection.value.nominations_open),
          )
          nextEventTitle.value = 'Nominations open in'
          periodSubText.value =
            'The election has not yet started. Eligible candidates will be notified by email when nominations open.'
          break
        case 'Nomination Period':
          nextEventDate = new Date(
            Date.parse(selectedElection.value.nominations_close),
          )
          nextEventTitle.value = 'Nominations close in'
          periodSubText.value =
            'Nominations have opened. Once you have completed your nomination, it will be reviewed by the team asap. You will receive confirmation of acceptance via email.'
          break
        case 'After Nomination Period':
          nextEventDate = new Date(
            Date.parse(selectedElection.value.voting_open),
          )
          nextEventTitle.value = 'Voting opens in'
          periodSubText.value =
            'The nomination period has closed. When voting opens, all eligible voters will receive an email with their unique voting token.'
          break
        case 'Voting Period':
          nextEventDate = new Date(
            Date.parse(selectedElection.value.voting_close),
          )
          nextEventTitle.value = 'Voting closes in'
          periodSubText.value =
            'If you have an email listed with your school, check your emails for your voting link. Otherwise if you received a voting paper by mail, click the vote button below to enter your unique voting token.'
          break
        case 'After Voting Period':
          nextEventDate = new Date(
            Date.parse(selectedElection.value.results_released),
          )
          nextEventTitle.value = 'Results due in'
          periodSubText.value =
            'Voting has concluded. The results will be released soon.'
          break
        case 'Results Released':
          nextEventTitle.value = 'Election concluded'
          timeUntilNextEvent.value = ''
          periodSubText.value =
            'The election has concluded, view the results below!'
          break

        default:
          nextEventTitle.value = ''
          timeUntilNextEvent.value = ''
          periodSubText.value = ''

          return
      }

      const timeDiff = nextEventDate - now
      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        )
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)
        timeUntilNextEvent.value = `${days}d ${hours}h ${minutes}m ${seconds}s`
      } else {
        nextEventTitle.value = 'Election concluded'
        timeUntilNextEvent.value = ''
      }
    }

    let intervalId // Declared here for scope access

    const selectedElection = computed(
      () => store.getters['elections/getCurrentElection'],
    )
    const showingElectionsList = computed(
      // () => !selectedElection.value && hasElectionsMultiple.value,
      // @todo turn back on if/when auto routinng works
      () => !selectedElection.value,
    )

    const selectElection = (election) => {
      if (election) {
        router.push({
          name: 'schoolElection',
          params: {
            ...route.params,
            electionId: election.id,
          },
        })
      } else {
        router.push({
          name: 'schoolHome',
          params: {
            clientSlug: route.params.clientSlug,
          },
        })
      }
    }

    const routeToNominationForm = () => {
      const clientSlug = route.params.clientSlug
      const electionId = route.params.electionId

      if (clientSlug && electionId) {
        router.push({
          name: 'schoolNominate',
          params: { clientSlug, electionId },
        })
      }
    }

    const watchElectionId = async () => {
      const electionId = route.params.electionId
      if (electionId) {
        await store.dispatch(
          'elections/dispatchUpdateCurrentElection',
          electionId,
        )
      } else {
        store.dispatch('elections/dispatchUpdateCurrentElection')
      }
    }

    const scrollToResults = () => {
      const element = document.getElementById('candidateComponent')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }

    onMounted(async () => {
      await watchElectionId()

      updateCountdown()
      intervalId = setInterval(updateCountdown, 1000)
    })

    onUnmounted(() => {
      clearInterval(intervalId)
    })

    watch(() => route.params?.electionId, watchElectionId)

    return {
      accessibilityMode,
      isVoting,
      isNominating,
      currentPeriod,
      isPeriodPassed,
      nextEventTitle,
      timeUntilNextEvent,
      periodSubText,
      updateCountdown,
      school,
      schoolLogo,
      schoolURL,
      selectedElection,
      elections,
      hasElections,
      hasElectionsMultiple,
      showingElectionsList,
      selectElection,
      routeToNominationForm,
      scrollToResults,
    }
  },
})
</script>

<style scoped>
.scheduleWrapper .active * {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.scheduleWrapper .active p {
  font-weight: 600;
}
</style>

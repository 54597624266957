<template>
  <main
    class="brand__page--background w-full p-6 py-16 border-t border-white lg:pb-6 lg:px-12 overflow-y-scroll"
  >
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-16">
      <div class="brand__card max-w-xl bg-primary shadow-lg">
        <a class="w-full -mt-6" href="https://www.schooled.nz/">
          <img
            src="/img/logo/schooled-logo-trans.svg"
            alt="Schooled Logo"
            class="h-32 -ml-4"
          />
        </a>
        <h5 class="text-white mb-2">Welcome to the Schooled Portal.</h5>
        <p class="text-white mb-0">
          In your Schooled Portal, you will find the latest Board documents and
          resources to assist the Board, as well as more information about
          Schooled and the services we offer.
        </p>
      </div>

      <div class="brand__card max-w-xl items-start bg-white shadow-lg pr-4">
        <template v-if="nextMeeting">
          <p class="brand__text--info">Next upcoming meeting:</p>
          <div class="flex flex-col flex-grow gap-5 ml-4">
            <h5>
              {{ nextMeeting.title }}
            </h5>
            <div class="flex flex-col w-full gap-2">
              <!-- Date -->
              <div
                v-if="nextMeeting.date"
                class="flex flex-row items-center gap-3 w-full"
              >
                <svg-icon
                  class="flex-shrink-0"
                  size="20"
                  icon-name="calendar"
                />
                <span class="text-xs font-bold">
                  {{ formatDate(nextMeeting.date) }}
                </span>
              </div>
              <!-- /Date -->

              <!-- Time -->
              <div
                v-if="nextMeeting.time"
                class="flex flex-row items-center gap-3 w-full"
              >
                <svg-icon class="flex-shrink-0" size="20" icon-name="time" />
                <span class="text-xs font-bold">
                  {{ nextMeeting.time }}
                </span>
              </div>
              <!-- /Time -->

              <!-- Location -->
              <div
                v-if="nextMeeting.location_title"
                class="flex flex-row items-start gap-3 w-full"
              >
                <svg-icon
                  class="flex-shrink-0"
                  size="20"
                  icon-name="location"
                />
                <div class="flex flex-col">
                  <span class="text-xs font-bold">
                    {{ nextMeeting.location_title }}
                  </span>
                  <a
                    v-if="nextMeeting.location_online"
                    :href="nextMeeting.location_online"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="font-light"
                  >
                    {{ nextMeeting.location_online }}
                  </a>
                </div>
              </div>
              <!-- /Location -->
            </div>
            <router-link
              :to="{
                name: 'boardMeetings',
                params: { id: route.params.id },
                query: { meeting: nextMeeting.id },
              }"
              class="flex flex-row items-center gap-1 mt-1"
            >
              <span>View details</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </router-link>
          </div>
        </template>

        <div v-else class="flex flex-col gap-4">
          <p class="brand__text--info">No upcoming meetings...</p>
          <button
            v-if="isAdmin"
            type="button"
            class="brand__button--primary"
            @click="routeToAddMeeting"
          >
            <span>Add a meeting</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col sm:flex-row justify-between sm:items-center mb-4">
      <h4 class="mb-0">News and Information</h4>
      <router-link
        :to="{ name: 'boardResources', params: { id: route.params.id } }"
        class="flex flex-row items-center gap-1"
      >
        <span>View more</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-4 h-4"
        >
          <path
            fill-rule="evenodd"
            d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </router-link>
    </div>

    <div
      class="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4 sm:mx-0 lg:max-w-none lg:grid-cols-3"
    >
      <div
        v-for="card in cards"
        :key="card.id"
        class="brand__card justify-between relative bg-white shadow-md pl-6 pt-6 pr-3 pb-6 group hover:shadow-xl"
      >
        <a
          :href="card.href"
          target="_blank"
          rel="noopener noreferrer"
          class="absolute inset-0"
        />
        <h6>{{ card.title }}</h6>
        <span v-if="card.date" class="brand__text--info mb-2">
          {{ card.date }}
        </span>
        <p class="text-sm">
          {{ card.description }}
        </p>
        <a class="flex flex-row items-center gap-1">
          <span class="text-xs">{{ card.CTAText }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-3 h-3"
          >
            <path
              fill-rule="evenodd"
              d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { formatDate } from '../../store/util.js'

export default defineComponent({
  components: {},

  setup() {
    const route = useRoute()
    const router = useRouter()

    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const nextMeeting = computed(() => store.getters['portal/getNextMeeting'])

    const routeToMeeting = (meetingId) => {
      router.push({
        name: 'boardMeetings',
        params: { id: route.params.id },
        query: { meeting: meetingId },
      })
    }

    const routeToAddMeeting = () => {
      router.push({
        name: 'boardMeeting',
        params: { id: route.params.id },
      })
    }

    const cards = [
      {
        id: 1,
        title: 'Admin Conferences',
        href: 'https://www.schooled.nz/training-and-events/',
        description:
          'The School Admin Conferences are a great way to upskill your school Admin Office. Conferences in Auckland, Wellington and Christchurch are available.',
        date: '2024',
        CTAText: 'Learn more',
      },
      {
        id: 2,
        title: 'Guidance',
        href: 'https://www.schooled.nz/tag/tips-and-tricks/',
        description:
          'Schooled has prepared an article on how to run a board meeting sharing tips and tricks on assist new board members.',
        date: '',
        CTAText: 'Learn more',
      },
      {
        id: 3,
        title: 'Services',
        href: 'https://www.schooled.nz/services/asset-management/',
        description:
          'Schools are required to undertake an asset stocktake to determine the accuracy of schools assets as well maintain an accurate registrar for your annual accounts.',
        date: '',
        CTAText: 'Learn more',
      },
    ]
    return {
      route,
      cards,
      nextMeeting,
      routeToMeeting,
      routeToAddMeeting,
      isAdmin,
      formatDate,
    }
  },
})
</script>

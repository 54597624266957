<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :content-classes="MODAL_CONFIG.contentClasses"
    :is-dialog-open="isModalOpen"
    @update-modal-state="updateIsModalOpen"
  >
    <template #trigger>
      <DialogTrigger>
        <slot name="trigger" />
      </DialogTrigger>
    </template>

    <template #content>
      <div v-if="showSuccessState" class="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-12 w-12 mx-auto mb-4 text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M5 13l4 4L19 7"
          />
        </svg>
        <h4>Submission received!</h4>
        <p>Thank you for contacting us. We will get back to you shortly!</p>
      </div>

      <template v-else>
        <div class="p-12">
          <div class="text-center">
            <h4 class="mb-4">Contact Us</h4>
            <p>Please fill out the contact form and we'll get back to you!</p>
          </div>

          <FormKit
            id="form"
            v-model="form"
            type="form"
            :config="{
              classes: {
                input: 'w-full',
                outer: '$remove:max-w-[20em]',
              },
            }"
            :submit-attrs="{
              inputClass: '$remove:w-full mx-auto brand__button--primary mt-6',
              outerClass: '$remove:mb-4 mb-0',
            }"
            submit-label="Send"
            @submit="onSubmit"
          >
            <FormKit
              name="contactName"
              label="Your full name *"
              placeholder="Please provide us with your full name"
              validation="required|length:3"
              validation-label="Full name"
            />

            <FormKit
              type="tel"
              name="contactPhone"
              label="Phone number"
              validation="number"
            />

            <FormKit
              type="email"
              name="contactEmail"
              label="Email address *"
              placeholder="Please provide a valid email to respond to"
              validation="required|email"
              validation-label="Email address"
            />

            <FormKit
              type="select"
              name="reasonForEnquiry"
              label="Reason for enquiry *"
              :options="enquiryOptions"
              select-icon="caretDown"
              placeholder="Select an option"
              validation="required"
              validation-label="Reason for enquiry"
            />

            <FormKit
              type="textarea"
              name="message"
              label="Message *"
              validation="required|length:5,350"
              validation-label="Message"
            />
          </FormKit>
        </div>
      </template>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import Modal from '../../../components/modal/Modal.vue'
import { DialogTrigger } from 'radix-vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { reset } from '@formkit/core'

export default defineComponent({
  name: 'ContactFormModal',

  components: {
    Modal,
    DialogTrigger,
  },

  setup() {
    const store = useStore()

    const MODAL_CONFIG = {
      trigger: {
        text: '',
        as: 'a',
        classes: '',
      },

      contentClasses: 'max-h-[85vh] overflow-y-auto p-20',
    }

    const isModalOpen = ref(true)

    const updateIsModalOpen = async (val) => {
      isModalOpen.value = val
    }

    const ENQUIRY_OPTIONS = [
      'General enquiry',
      'My eligibility to place a nomination',
      'I want to retract a nomination',
      'I want to change my nomination information',
    ]

    const enquiryOptions = computed(() => {
      return ENQUIRY_OPTIONS.map((opt) => ({
        label: opt,
        value: opt,
      }))
    })

    // Google recaptcha
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const runRecaptcha = async () => {
      // Wait until recaptcha has been loaded
      await recaptchaLoaded()

      // Execute reCAPTCHA with form action
      const action = 'contact_form_submitted'
      const token = await executeRecaptcha(action)
      return { token, action }
    }

    const FORM_INITIAL_STATE = {
      contactName: null,
      contactEmail: null,
      contactPhone: null,
      reasonForEnquiry: null,
      message: null,
    }

    const form = ref(FORM_INITIAL_STATE)

    const showSuccessState = ref(false)
    const onSubmit = async () => {
      const recaptchaData = await runRecaptcha()

      const payload = new FormData()
      Object.keys(form.value).forEach((key) => {
        if (form.value[key] !== null) {
          payload.append(key, form.value[key])
        }
      })

      payload.append('recaptchaData', JSON.stringify(recaptchaData))

      const success = await store.dispatch(
        'elections/dispatchSendContactForm',
        payload,
      )

      if (success) {
        showSuccessState.value = true
        reset('form')
      }
    }

    watch(isModalOpen, () => {
      reset('form')
      showSuccessState.value = false
    })

    return {
      MODAL_CONFIG,
      isModalOpen,
      updateIsModalOpen,

      showSuccessState,
      form,
      enquiryOptions,
      onSubmit,
    }
  },
})
</script>

<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :content-classes="MODAL_CONFIG.contentClasses"
    :is-dialog-open="isModalOpen"
    :no-padding="true"
    @update-modal-state="updateIsModalOpen"
  >
    <template #trigger>
      <DialogTrigger>
        <slot name="trigger" />
      </DialogTrigger>
    </template>

    <template #content>
      <div>
        <div
          class="w-full bg-primary text-white p-6 mb-4 flex items-center space-x-4 rounded-t-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              d="M8.25 10.875a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0Z"
            />
            <path
              fill-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.125 4.5a4.125 4.125 0 1 0 2.338 7.524l2.007 2.006a.75.75 0 1 0 1.06-1.06l-2.006-2.007a4.125 4.125 0 0 0-3.399-6.463Z"
              clip-rule="evenodd"
            />
          </svg>

          <h1 class="text-base text-white mb-0">Search for your school</h1>
        </div>
        <div class="p-8">
          <FormKit
            id="schoolSearch"
            v-model="searchQuery"
            type="text"
            name="schoolSearch"
            label=""
            placeholder="Start typing to find your school"
          />
          <div v-if="matchedSchools.length && searchQuery.length > 2">
            <h5 class="text-xs font-primary font-bold mb-2">
              Matched Results:
            </h5>
            <ul
              class="max-h-[250px] overflow-scroll no-scrollbar w-full flex flex-col gap-2"
            >
              <li
                v-for="school in matchedSchools"
                :key="school.value"
                class="bg-primary rounded-full text-white px-4 py-1 flex items-center justify-between w-full group cursor-pointer"
                @click="() => routeToSchool(school)"
              >
                <span
                  class="group-hover:text-secondary"
                  v-html="highlightMatch(school.label)"
                ></span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-4 text-tertiary group-hover:text-secondary"
                >
                  <path
                    d="M12.232 4.232a2.5 2.5 0 0 1 3.536 3.536l-1.225 1.224a.75.75 0 0 0 1.061 1.06l1.224-1.224a4 4 0 0 0-5.656-5.656l-3 3a4 4 0 0 0 .225 5.865.75.75 0 0 0 .977-1.138 2.5 2.5 0 0 1-.142-3.667l3-3Z"
                  />
                  <path
                    d="M11.603 7.963a.75.75 0 0 0-.977 1.138 2.5 2.5 0 0 1 .142 3.667l-3 3a2.5 2.5 0 0 1-3.536-3.536l1.225-1.224a.75.75 0 0 0-1.061-1.06l-1.224 1.224a4 4 0 1 0 5.656 5.656l3-3a4 4 0 0 0-.225-5.865Z"
                  />
                </svg>
              </li>
            </ul>
          </div>
          <div v-if="!matchedSchools.length && searchQuery.length > 2" class="">
            <p class="italic text-truegray-500">
              Sorry, we could not find any schools matching your search.
            </p>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import Modal from '../../../components/modal/Modal.vue'
import { DialogTrigger } from 'radix-vue'
import { reset } from '@formkit/core'

export default defineComponent({
  name: 'ContactFormModal',

  components: {
    Modal,
    DialogTrigger,
  },

  setup() {
    const store = useStore()

    const MODAL_CONFIG = {
      trigger: {
        text: '',
        as: 'a',
        classes: '',
      },

      contentClasses: 'max-h-[85vh] overflow-y-auto p-0',
    }

    const isModalOpen = ref(true)

    const updateIsModalOpen = async (val) => {
      isModalOpen.value = val
    }

    const searchQuery = ref('')

    const schools = computed(() => store.getters['elections/getSchools'])
    const schoolsOptions = computed(() =>
      schools.value
        .map((school) => ({
          value: school.id,
          label: school.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    )

    const matchedSchools = computed(() =>
      schoolsOptions.value.filter((school) =>
        school.label.toLowerCase().includes(searchQuery.value.toLowerCase()),
      ),
    )

    const highlightMatch = (label) => {
      const query = searchQuery.value.toLowerCase()
      const regex = new RegExp(`(${query})`, 'gi')
      return label.replace(regex, '<span class="text-secondary">$1</span>')
    }

    const routeToSchool = (selectedSchool) => {
      const schoolObj = schools.value.find(
        (school) => school.id === selectedSchool.value,
      )
      store.dispatch('elections/dispatchUpdateSchool', schoolObj)
      updateIsModalOpen(false)
    }

    return {
      MODAL_CONFIG,
      isModalOpen,
      updateIsModalOpen,
      searchQuery,
      matchedSchools,
      routeToSchool,
      highlightMatch,
    }
  },
})
</script>

<template>
  <div
    class="flex flex-col min-h-full justify-center bg-[url('/img/bgpath.svg')] bg-[center_top_10%] bg-norepeat bg-cover]"
  >
    <div
      class="grid grid-cols-1 sm:grid-cols-12 sm:space-x-12 sm:space-y-8 min-h-full"
    >
      <div
        v-if="canView && selectedElection"
        class="flex flex-col p-10 rounded-none lg:rounded-xl justify-start lg:justify-center items-center lg:items-start col-span-4 relative"
      >
        <a
          class="lg:absolute lg:top-12 lg:left-10 flex flex-row items-center gap-2 text-xs mb-12 mr-auto text-truegray-500 hover:text-tertiary"
          @click="backToElectionSchedule"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
          Back to election schedule
        </a>
        <div class="">
          <h5
            :class="
              selectedElection
                ? 'text-tertiary text-base'
                : 'text-primary text-4xl'
            "
            class="text-center lg:text-left mb-2 transition-all"
          >
            {{ school?.name }}
          </h5>
          <h4
            v-if="selectedElection"
            class="text-primary text-center lg:text-left"
          >
            {{ selectedElection.election_name }}
            <span class="text-secondary bg-primary font-bold px-2">Voting</span>
          </h4>
        </div>

        <div v-if="canVote" class="">
          <p class="my-4 text-sm text-center lg:text-left text-truegray-500">
            Hi <span class="font-bold text-primary">{{ voterName }}.</span>
          </p>
          <p class="my-4 text-sm text-center lg:text-left text-truegray-500">
            <span class="font-bold text-green-500">
              You are eligable to vote in this election for up to
              {{ selectedElection.min_candidates }} candidates
            </span>
            . Please select up to {{ selectedElection.min_candidates }} and
            submit your vote. You may choose to vote for less than the maximum
            if you choose.
          </p>
          <div v-if="selectedCandidates.length" class="">
            <ModalVoting />
          </div>
        </div>

        <div v-if="hasVoted" class="">
          <p class="my-4 text-sm text-center lg:text-left text-truegray-500">
            Hi <span class="font-bold text-primary">{{ voterName }}.</span>
          </p>
          <p class="my-4 text-sm text-center lg:text-left text-truegray-500">
            <span class="font-bold text-red-500">
              Your vote has already been cast </span
            >.
          </p>
        </div>
      </div>
      <div
        v-if="canView && selectedElection?.candidates"
        :class="[
          'flex flex-col gap-4 col-span-12 lg:col-span-8 items-center justify-center',
          canVote ? 'opacity-100' : 'opacity-50',
        ]"
      >
        <ListCandidates
          :candidates="selectedElection.candidates"
          :voting="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ListCandidates from '../../../components/elections/ListCandidates.vue'
import ModalVoting from '../../../components/modal/ModalVoting.vue'

export default defineComponent({
  name: 'ElectionVoting',
  components: { ListCandidates, ModalVoting },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const store = useStore()

    const canView = computed(() => store.getters['elections/canView'])

    const canVote = computed(() => store.getters['elections/canVote'])

    const hasVoted = computed(() => store.getters['elections/hasVoted'])

    const voterName = computed(() => store.getters['elections/getVoterName'])

    const school = computed(() => store.getters['elections/getSchool'] || null)

    const selectedCandidates = computed(
      () => store.getters['elections/getSelectedCandidates'],
    )

    const remainingCandidateSelections = computed(
      () => store.getters['elections/remainingCandidateSelections'],
    )

    const selectedElection = computed(
      () => store.getters['elections/getCurrentElection'],
    )

    const watchElectionId = async () => {
      const electionId = route.params.electionId
      if (electionId) {
        await store.dispatch(
          'elections/dispatchUpdateCurrentElection',
          electionId,
        )
      } else {
        store.dispatch('elections/dispatchUpdateCurrentElection')
      }
    }

    const backToElectionSchedule = () => {
      router.push({
        name: 'schoolElection',
        params: {
          clientSlug: route.params.clientSlug,
          electionId: route.params.electionId,
        },
      })
    }

    onMounted(async () => {
      await watchElectionId()
      const electionId = route.params.electionId
      const votingToken = route.query.votingToken

      if (electionId && votingToken) {
        const payload = {
          electionId,
          votingToken,
        }

        const response = await store.dispatch(
          'elections/dispatchAuthoriseVoter',
          payload,
        )
        if (!response) {
          backToElectionSchedule()
          alert(
            'Incorrect voting token. You have been timed out. Please use the link in your email and try again later.',
          )
        }
      } else {
        backToElectionSchedule()
        alert('Missing election ID or voting token.')
      }
    })

    return {
      canView,
      selectedElection,
      school,
      voterName,
      canVote,
      hasVoted,
      backToElectionSchedule,
      selectedCandidates,
      remainingCandidateSelections,
    }
  },
})
</script>

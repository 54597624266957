<template>
  <main class="bg-gray-primary">
    <PageHeader title="Settings" :breadcrumbs="currentBreadcrumbs">
    </PageHeader>

    <PageContent>
      <template #default>
        <div class="relative">
          <!-- Drawers -->
          <PageDrawer
            :is-loading="isLoading"
            :primary-drawer-icon="PRIMARY_DRAWER_ICON"
            primary-drawer-title="Settings"
            :primary-drawer-items="primaryDrawerItems"
            @update:activeItemValue="setActiveSettingsCategory"
          >
            <!-- Example slot if you want to style primary list items differently to default! -->
            <!--
              <template
              #primary-item="{ item, isPrimaryDrawerCollapsed, isActive }"
              >
              <div
              :class="[
              'some classes you want',
              {
              'some class': isPrimaryDrawerCollapsed,
              'some other class': isActive,
              },
              ]"
              >
              {{ item.whatever }} and anything else you want customised
              depending on drawer props/state!
              </div>
              </template> 
            -->
          </PageDrawer>
          <!-- /Drawers -->

          <!-- Content -->
          <div v-if="isLoading" class="mx-auto w-12 h-12">
            <Vue3Lottie :animation-data="ANIMATION_LOADING.DARK" />
          </div>
          <div v-else>
            <div ref="componentParent" class="w-full pl-12">
              <component
                :is="currentComponent"
                @emit-route-change="setActiveSettingsCategory"
              />
            </div>
          </div>
          <!-- /Content -->
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { ANIMATION_LOADING } from '../../constants.js'
import {
  DRAWER_SVG_PRIMARY,
  DRAWER_SVG_SETTINGS,
  DRAWER_SVG_USER,
} from '../../constants'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import PageDrawer from '../../components/PageDrawer.vue'
import SettingsGeneral from './PortalSettingsGeneral.vue'
import SettingsMeetings from './PortalSettingsMeetings.vue'
import SettingsMyBoard from './PortalSettingsMyBoard.vue'
import SettingsAddBoardMember from './PortalSettingsAddBoardMember.vue'

export default defineComponent({
  components: {
    Vue3Lottie,
    PageHeader,
    PageContent,
    PageDrawer,
    SettingsGeneral,
    SettingsMeetings,
    SettingsMyBoard,
    SettingsAddBoardMember,
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const [componentParent] = useAutoAnimate()

    const ROUTE_NAMES = {
      BOARD_MEMBERS: 'myBoard',
      ADD_BOARD_MEMBER: 'addBoardMember',
    }
    const currentComponent = ref(null)

    const isNestedRoute = computed(() =>
      Object.values(ROUTE_NAMES).includes(route.name),
    )
    const routeToChild = () => {
      if (isNestedRoute.value) {
        setActiveSettingsCategory({ value: route.name, primary: true })
      }
    }

    watch(
      () => route.name,
      () => routeToChild(),
    )

    const currentBreadcrumbs = ref([])

    const setActiveSettingsCategory = (data) => {
      currentBreadcrumbs.value = []

      // Find the object in primaryDrawerItems that matches the value
      const drawerItem = primaryDrawerItems.find(
        (item) => item.value === data.value,
      )

      if (drawerItem) {
        if (drawerItem.route) {
          const query = drawerItem.clearQuery ? {} : { ...route.query }

          router.push({
            name: data.value,
            params: { id: route.params.id },
            query,
          })
          currentComponent.value = route.meta.childComponent
        } else {
          router.push({
            name: 'settings',
            params: { id: route.params.id },
          })
          currentComponent.value = data.value
        }

        currentBreadcrumbs.value.push(drawerItem.title)
        // If the object has a parent, unshift it to currentBreadcrumbs
        if (drawerItem.parent) {
          currentBreadcrumbs.value.unshift(drawerItem.parent)
        }
      }
    }

    const PRIMARY_DRAWER_ICON = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>`
    // Drawers config
    const primaryDrawerItems = [
      {
        title: 'General',
        value: 'SettingsGeneral',
        iconLeft: DRAWER_SVG_SETTINGS.LEFT,
        iconRight: DRAWER_SVG_SETTINGS.RIGHT,
        breadcrumbTitle: 'General',
        adminOnly: true,
        clearQuery: true,
      },
      {
        title: 'Meeting Defaults',
        value: 'SettingsMeetings',
        iconLeft: DRAWER_SVG_PRIMARY.LEFT,
        iconRight: DRAWER_SVG_PRIMARY.RIGHT,
        breadcrumbTitle: 'Meetings',
        adminOnly: true,
        clearQuery: true,
      },
      {
        title: 'My Board',
        value: 'myBoard',
        route: true,
        iconLeft: DRAWER_SVG_USER.LEFT,
        iconRight: DRAWER_SVG_USER.RIGHT,
        adminOnly: false,
        clearQuery: true,
      },
      {
        title: 'Add Board Member',
        value: 'addBoardMember',
        route: true,
        hidden: true,
        parent: 'My Board',
        clearQuery: false,
      },
    ]

    onMounted(() => routeToChild())

    return {
      ANIMATION_LOADING,
      componentParent,
      PRIMARY_DRAWER_ICON,
      primaryDrawerItems,
      setActiveSettingsCategory,
      currentBreadcrumbs,
      isLoading,
      isAdmin,
      route,
      currentComponent,
    }
  },
})
</script>

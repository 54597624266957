<template>
  <Modal
    :content-classes="MODAL_CONFIG.contentClasses"
    :is-dialog-open="isModalOpen"
    @update-modal-state="updateIsModalOpen"
  >
    <template #trigger>
      <DialogTrigger>
        <slot name="trigger" />
      </DialogTrigger>
    </template>

    <template #content>
      <FormKit
        type="form"
        :actions="false"
        :config="{
          classes: {
            label: 'text-primary',
          },
        }"
        @submit="onSubmit"
      >
        <div class="flex flex-col items-center justify-center py-4 px-2">
          <div
            :style="{ width: '300px' }"
            class="flex flex-col w-full gap-3 mb-4"
          >
            <FormKit
              v-model="linkUrl"
              type="url"
              label="Link URL"
              placeholder="https://www.example.com..."
              validation="required|url"
              validation-visibility="submit"
            />

            <FormKit
              v-model="linkTitle"
              label="Link title"
              :placeholder="'e.g., Click here to read more'"
              validation="required"
              validation-visibility="submit"
            />
          </div>

          <div class="flex items-center justify-center">
            <FormKit
              type="submit"
              label="Save"
              outer-class="$remove:mb-4 mb-0"
            />
          </div>
        </div>
      </FormKit>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import Modal from '../../../components/modal/Modal.vue'
import { DialogTrigger } from 'radix-vue'

export default defineComponent({
  name: 'EditorLinkModal',

  components: {
    Modal,
    DialogTrigger,
  },

  props: {
    originalLinkUrl: {
      type: String,
      default: '',
    },

    originalLinkTitle: {
      type: String,
      default: '',
    },
  },

  emits: ['update:link'],

  setup(props, { emit }) {
    const MODAL_CONFIG = {
      contentClasses: 'max-h-[85vh] overflow-y-auto p-10',
    }

    const isModalOpen = ref(true)

    const updateIsModalOpen = async (val) => {
      isModalOpen.value = val
    }

    const linkUrl = ref(props.originalLinkUrl || '')
    const linkTitle = ref(props.originalLinkTitle || '')

    const onSubmit = () => {
      emit('update:link', {
        title: linkTitle.value,
        url: linkUrl.value,
      })

      isModalOpen.value = false
    }

    watch(
      () => props.originalLinkUrl,
      () => {
        linkUrl.value = props.originalLinkUrl
      },
    )

    watch(
      () => props.originalLinkTitle,
      () => {
        linkTitle.value = props.originalLinkTitle
      },
    )

    return {
      MODAL_CONFIG,
      isModalOpen,
      updateIsModalOpen,

      linkUrl,
      linkTitle,
      onSubmit,
    }
  },
})
</script>

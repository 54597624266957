<template>
  <!-- @todo convert to use Modal.vue with content/trigger slots -->
  <div
    v-if="isVisible"
    class="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
  >
    <div
      class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
    >
      <div class="mt-3">
        <div class="p-6 max-w-md mx-auto bg-white rounded-xl relative">
          <button
            type="button"
            class="absolute top-0 right-6 flex items-center justify-center text-black hover:text-ces-teal"
            @click="closeModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <form ref="form" @submit.prevent="handleSubmit">
            <h2 class="text-lg font-bold mb-4 text-center">
              <span v-if="!edit">Upload</span> <span v-else></span> File
            </h2>

            <!-- Toggle for File Type -->
            <div v-show="!edit" class="mb-4 text-center">
              <label class="block text-xs font-medium leading-6 text-gray-900"
                >File type:</label
              >
              <div class="mt-2">
                <button
                  type="button"
                  :class="{
                    'bg-ces-blue text-white border': fileType === 'File',
                  }"
                  class="px-4 py-2 rounded-l-lg border border-ces-blue"
                  @click="changeFileType('File')"
                >
                  File
                </button>
                <button
                  type="button"
                  :class="{
                    'bg-ces-blue text-white border': fileType === 'Link',
                  }"
                  class="px-4 py-2 rounded-r-lg border border-ces-blue"
                  @click="changeFileType('Link')"
                >
                  Link
                </button>
              </div>
            </div>

            <!-- Title Input -->
            <div class="mb-4">
              <label
                class="block text-xs font-medium leading-6 text-gray-900"
                for="title"
                >Title:</label
              >
              <input
                id="title"
                v-model="title"
                type="text"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
              />
            </div>

            <div class="mb-4">
              <label
                class="block text-xs font-medium leading-6 text-gray-900"
                for="category"
                >Category:</label
              >
              <select
                id="category"
                v-model="category"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
              >
                <option disabled value="">Please select a category</option>
                <option>Board Financials</option>
                <option>Property Document</option>
                <option>Board Meeting Minutes</option>
                <option>Other</option>
              </select>
            </div>

            <!-- Date Input -->
            <div class="mb-4">
              <label
                class="block text-xs font-medium leading-6 text-gray-900"
                for="date"
                >Date:</label
              >
              <input
                id="date"
                v-model="date"
                type="date"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
              />
            </div>

            <!-- Author Input -->
            <div class="mb-4">
              <label
                class="block text-xs font-medium leading-6 text-gray-900"
                for="author"
                >Author:</label
              >
              <input
                id="author"
                v-model="author"
                type="text"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
              />
            </div>

            <div v-show="!edit">
              <!-- Conditional Link URL Input -->
              <div v-if="fileType === 'Link'" class="mb-4">
                <label
                  class="block text-xs font-medium leading-6 text-gray-900"
                  for="linkUrl"
                  >Link URL:</label
                >
                <input
                  id="linkUrl"
                  v-model="linkUrl"
                  type="text"
                  :required="fileType === 'Link' && !edit"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-blue sm:text-sm sm:leading-6"
                />
              </div>

              <!-- Conditional File Input -->
              <div v-if="fileType === 'File'" class="mb-4">
                <label
                  class="block text-xs font-medium leading-6 text-gray-900"
                  for="file"
                  >File:</label
                >
                <input
                  id="file"
                  type="file"
                  :required="fileType === 'File' && !edit"
                  class="w-full px-3 py-2 border rounded-lg mt-1"
                  @change="handleFileChange"
                />
                <p class="text-xs text-truegray-400 italic pt-2">Maximum file size: 5mb</p>
              </div>
            </div>

            <!-- Submit Button -->
            <button
              type="submit"
              :disabled="!canSubmit"
              :class="[
                'w-full flex justify-center items-center bg-ces-blue text-white py-2 mt-4 rounded-lg',
                { 'cursor-not-allowed': !canSubmit },
              ]"
            >
              <div v-if="isSubmitting" class="h-6 w-6">
                <Vue3Lottie :animation-data="ANIMATION_LOADING.DEFAULT" />
              </div>
              <span v-else> Submit </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, ref, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'

export default defineComponent({
  name: 'ModalFileUpload',

  components: {
    Vue3Lottie,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    fileModel: {
      type: Object,
      default: () => ({}),
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const store = useStore()
    const { isVisible } = toRefs(props)
    const { edit } = toRefs(props)
    const { fileModel } = toRefs(props)
    const form = ref(null)
    const isSubmitting = ref(false)

    function closeModal() {
      resetForm()
      emit('update:isVisible', false)
      emit('modalClosed')
    }

    const fileType = ref('File')
    const title = ref('')
    const category = ref('')
    const date = ref(new Date().toISOString().slice(0, 10))
    const author = ref('')
    const file = ref(null)
    const linkUrl = ref('')

    const resetForm = () => {
      // @todo there must be a way to use the form ref to reset fields
      fileType.value = 'File'
      title.value = ''
      category.value = ''
      date.value = new Date().toISOString().slice(0, 10)
      author.value = ''
      file.value = null
      linkUrl.value = ''
    }

    watch(fileModel, async (newVal) => {
      if (edit.value) {
        await nextTick()
        // @TODO - MVP: BE is still expecting video_url even though FE has changed name to link
        fileType.value = newVal?.video_url ? 'Link' : 'File'
        title.value = newVal.title || ''
        category.value = newVal.category || ''
        const dateStr =
          newVal.created_at || new Date().toISOString().slice(0, 10)
        date.value = date.value = dateStr.split('/').join('-')

        author.value = newVal.author || ''
      }
    })

    const changeFileType = (type) => {
      fileType.value = type
    }

    const handleFileChange = (event) => {
      file.value = event.target.files[0]
    }

    const payload = computed(() => {
      const data = new FormData()
      data.append('title', title.value)
      data.append('category', category.value)
      data.append('date', date.value)
      data.append('author', author.value)
      if (edit.value && props.fileModel?.id) {
        // editting
        data.append('id', props.fileModel?.id)
      } else {
        // creating - file inputs needed
        data.append('file', file.value)
        data.append('video_url', linkUrl?.value)
      }
      return data
    })

    const canSubmit = computed(() => {
      // @todo form.value?.checkValidity()
      return !isSubmitting.value
    })

    const handleSubmit = async () => {
      isSubmitting.value = true
      const action = edit.value
        ? 'portal/dispatchEditFile'
        : 'portal/dispatchAddFile'

      const response = await store.dispatch(action, payload.value)
      isSubmitting.value = false

      if (response) {
        closeModal()
      }
    }

    return {
      ANIMATION_LOADING,
      isSubmitting,
      canSubmit,
      closeModal,
      form,
      fileType,
      title,
      category,
      date,
      author,
      file,
      linkUrl,
      handleFileChange,
      handleSubmit,
      changeFileType,
    }
  },
})
</script>

import {
  getUser,
  resetPassword,
  validateResetPassword,
} from '../../api/auth.js'

const handleError = (type) => {
  // @todo trigger error messaging
  return type
}

export async function dispatchGetUser() {
  try {
    const response = await getUser()

    if (response?.data?.portWebSession) {
      auth.portWebSession(
        response.data.access_token,
        response.data.user,
        response.data.expires,
      )
    }

    if (response?.data?.success) {
      return response.data?.user
    }

    return handleError(null)
  } catch {
    return handleError(null)
  }
}

export async function dispatchUpdateUser(context, user) {
  try {
    if (user) {
      context.commit('UPDATE_USER', user)
    } else {
      context.commit('CLEAR_USER')
    }

    return true
  } catch {
    return handleError(null)
  }
}

export async function dispatchResetPassword(_context, payload) {
  try {
    const response = await resetPassword(payload)

    if (response?.data?.success) {
      return true
    }

    return handleError(false)
  } catch {
    return handleError(false)
  }
}

export async function dispatchValidateResetPassword(_context, payload) {
  try {
    const response = await validateResetPassword(payload)

    if (response?.data) {
      return response.data
    }

    return handleError(false)
  } catch {
    return handleError(false)
  }
}

<template>
  <main class="bg-gray-primary">
    <div>
      <div v-if="isLoading" class="mx-auto w-12 h-12">
        <Vue3Lottie :animation-data="ANIMATION_LOADING.DARK" />
      </div>
      <div v-else>
        <div v-if="isAdmin" class="w-full flex items-end justify-end mb-8">
          <button class="brand__button--primary" @click="emitRouteChange">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"
              />
            </svg>
            <span>Add Member</span>
          </button>
        </div>
        <div
          v-if="owners.length"
          class="mb-8 pb-8 border-b border-truegray-300"
        >
          <ul
            role="list"
            class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            <li
              v-for="owner in owners"
              :key="owner.id"
              class="brand__card p-0 bg-white justify-between col-span-1 divide-y divide-lightgray"
            >
              <div class="flex w-full px-6 py-5">
                <div
                  class="flex items-center justify-between space-x-6 p-2 w-full"
                >
                  <div class="flex-1 w-full">
                    <div
                      class="flex w-full space-x-3 items-end justify-between"
                    >
                      <h5 class="truncate text-h6 w-10/12" :title="owner.name">
                        {{ owner.name }}
                      </h5>
                      <img
                        src="/img/logo/schooled-logo-inverted.svg"
                        alt="Schooled Representative Logo"
                        class="w-14 h-14"
                      />
                    </div>
                    <span class="truncate brand__text--info text-xs mt-1">
                      Schooled Representative
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div class="-mt-px flex divide-x divide-lightgray">
                  <div class="flex w-0 flex-1">
                    <a
                      class="brand__link--primary text-sm relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-4"
                      @click="() => copyToClipboard(owner.email, 'Email')"
                    >
                      <svg-icon
                        aria-hidden="true"
                        size="16"
                        icon-name="email"
                      />
                      Email
                    </a>
                  </div>
                  <div class="-ml-px flex w-0 flex-1">
                    <a
                      class="brand__link--primary text-sm relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-4"
                      @click="
                        () => copyToClipboard(owner.phone, 'Phone number')
                      "
                    >
                      <svg-icon
                        aria-hidden="true"
                        size="16"
                        icon-name="phone"
                      />
                      Call
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="!members.length">
          <p>No board members of {{ selectedSchoolName }}</p>
        </div>
        <ul
          v-else
          role="list"
          class="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
        >
          <li
            v-for="member in members"
            :key="member.id"
            class="brand__card p-0 bg-white justify-between col-span-1 divide-y divide-lightgray"
          >
            <div class="flex justify-between w-full h-full px-5 py-6 relative">
              <div class="flex items-center flex-grow space-x-4">
                <Logo
                  :show-placeholder="true"
                  :is-school="false"
                  :src="member.logo"
                  wrapper-class="h-16 w-16"
                />

                <div class="flex flex-col flex-grow items-start">
                  <h5
                    class="whitespace-normal truncate text-h6 mb-0"
                    :class="!member.isAdmin ? 'w-full' : 'w-9/12'"
                    :title="member.name"
                  >
                    {{ member.name }}
                  </h5>

                  <span
                    v-if="member.boardRole"
                    class="brand__text--info whitespace-normal truncate mt-1"
                  >
                    {{ member.boardRole }}
                  </span>
                  <span
                    v-if="member.isAdmin"
                    class="mt-2 inline-flex flex-shrink-0 items-center rounded-full px-2 py-0.5 text-xs font-medium text-tertiary ring-1 ring-inset ring-tertiary"
                  >
                    Admin
                  </span>
                </div>
              </div>

              <div v-if="canActionMember()">
                <PopoverRoot>
                  <PopoverTrigger
                    class="rounded-full w-[25px] h-[25px] inline-flex items-center justify-center bg-white cursor-pointer border border-gray-300 hover:border-gray-400"
                    aria-label="Member actions"
                  >
                    <svg-icon
                      icon-name="navbar"
                      aria-hidden="true"
                      size="14"
                      class="flex text-gray-500 hover:text-gray-600"
                    />
                  </PopoverTrigger>
                  <PopoverPortal>
                    <PopoverContent
                      side="bottom"
                      :side-offset="0"
                      class="rounded-lg w-auto bg-white border border-gray-200 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                    >
                      <div class="flex flex-col">
                        <button
                          type="button"
                          class="flex justify-start rounded-t-lg px-6 py-3 border-b border-gray-100 font-semibold hover:text-tertiary"
                          @click="() => editMember(member)"
                        >
                          Edit
                        </button>
                        <AlertDialogRoot>
                          <AlertDialogTrigger
                            class="flex justify-start rounded-b-lg px-6 py-3 font-semibold hover:text-red-400"
                          >
                            Delete
                          </AlertDialogTrigger>
                          <AlertDialogPortal>
                            <AlertDialogOverlay
                              class="bg-gray-600 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-80"
                            />
                            <AlertDialogContent
                              class="z-[100] text-[15px] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
                            >
                              <AlertDialogTitle class="m-0">
                                <h5>Delete {{ member.name }}?</h5>
                              </AlertDialogTitle>
                              <AlertDialogDescription
                                class="text-sm my-6 leading-normal"
                              >
                                Board member {{ member.name }} will be removed
                                from your board and deleted. Please be sure
                                before proceeding!
                              </AlertDialogDescription>
                              <div class="flex justify-end gap-[25px]">
                                <AlertDialogCancel
                                  class="brand__button--trans-primary"
                                >
                                  Cancel
                                </AlertDialogCancel>
                                <AlertDialogAction
                                  class="brand__button bg-red-400 hover:bg-red-500 text-white"
                                  @click="() => deleteMember(member)"
                                >
                                  Yes, delete member
                                </AlertDialogAction>
                              </div>
                            </AlertDialogContent>
                          </AlertDialogPortal>
                        </AlertDialogRoot>
                      </div>
                      <PopoverArrow class="fill-gray-200" />
                    </PopoverContent>
                  </PopoverPortal>
                </PopoverRoot>
              </div>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-lightgray">
                <div class="flex w-0 flex-1">
                  <a
                    class="brand__link--primary text-sm relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-4"
                    @click="() => copyToClipboard(member.email, 'Email')"
                  >
                    <svg-icon aria-hidden="true" size="16" icon-name="email" />
                    Email
                  </a>
                </div>
                <div class="-ml-px flex w-0 flex-1">
                  <a
                    class="brand__link--primary text-sm relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-4"
                    @click="() => copyToClipboard(member.phone, 'Phone number')"
                  >
                    <svg-icon aria-hidden="true" size="16" icon-name="phone" />
                    Call
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
  AlertDialogTrigger,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from 'radix-vue'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'
import Logo from '../../components/Logo.vue'

export default defineComponent({
  name: 'PortalSettingsMyBoard',

  components: {
    Vue3Lottie,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogRoot,
    AlertDialogTitle,
    AlertDialogTrigger,
    PopoverArrow,
    PopoverContent,
    PopoverPortal,
    PopoverRoot,
    PopoverTrigger,
    Logo,
  },

  emits: ['emit-route-change'],

  setup(_props, { emit }) {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const router = useRouter()
    const route = useRoute()

    const emitRouteChange = () => {
      emit('emit-route-change', {
        primary: true,
        value: 'addBoardMember',
      })
    }

    const selectedSchoolName = computed(
      () => store.getters['portal/getSchool']?.name,
    )

    const owners = computed(() => store.getters['portal/getOwnersMapped'])
    const members = computed(() => store.getters['portal/getMembersMapped'])

    const canActionMember = () => {
      return isAdmin.value
    }

    const editMember = (member) => {
      router.push({
        name: 'addBoardMember',
        params: { id: route.params.id },
        query: { member: member.id },
      })
    }

    const deleteMember = async (member) => {
      store.dispatch('global/dispatchUpdateIsLoading', true)
      await store.dispatch('portal/dispatchDeleteMember', member.id)
      store.dispatch('global/dispatchUpdateIsLoading')
    }

    const copyToClipboard = (item, type) => {
      navigator.clipboard?.writeText(item)
      store.dispatch('global/dispatchUpdateAlertToast', {
        status: 'success',
        message: `${type} copied to clipboard`,
      })
    }

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      route,
      emitRouteChange,
      selectedSchoolName,
      owners,
      members,
      canActionMember,
      editMember,
      deleteMember,
      copyToClipboard,
    }
  },
})
</script>

<style scoped>
/* For some reason required to show the popover */
[data-radix-popper-content-wrapper] {
  z-index: 10;
}
</style>

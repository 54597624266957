<template>
  <div class="flex flex-col bg-white p-6 py-16 lg:pt-24 lg:pb-6 lg:px-12">
    <div class="flex flex-col sm:flex-row justify-between items-center gap-x-4">
      <div class="flex flex-col flex-grow gap-2">
        <h3 class="mb-4">{{ title }}</h3>
        <slot name="desc" />
      </div>

      <slot name="action" />
    </div>

    <div
      v-if="breadcrumbs.length > 0"
      ref="breadcrumbParent"
      class="brand__text--info text-xs font-bold flex space-x-2 items-center ml-2"
    >
      <div
        v-for="(crumbycrumb, index) in breadcrumbs"
        :key="crumbycrumb"
        :class="{ underline: index === breadcrumbs.length - 1 }"
        class="flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          class="w-4 h-4 mr-2"
        >
          <path
            fill-rule="evenodd"
            d="M2 8c0 .414.336.75.75.75h8.69l-1.22 1.22a.75.75 0 1 0 1.06 1.06l2.5-2.5a.75.75 0 0 0 0-1.06l-2.5-2.5a.75.75 0 1 0-1.06 1.06l1.22 1.22H2.75A.75.75 0 0 0 2 8Z"
            clip-rule="evenodd"
          />
        </svg>
        {{ crumbycrumb }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useAutoAnimate } from '@formkit/auto-animate/vue'

export default defineComponent({
  name: 'PageHeader',

  props: {
    title: {
      type: String,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const [breadcrumbParent] = useAutoAnimate()

    return {
      breadcrumbParent,
    }
  },
})
</script>

export const API_BASE = '/api'
export const API_BASE_AUTH = '/auth'
export const API_BASE_PORTAL = '/portal'

export async function call(requestType, url, data = null) {
  const response = await axios[requestType](url, data)

  if (response?.status === 401) {
    auth.clearUserSession()
  }

  return response
}

export async function get(url) {
  return await call('get', url)
}

export async function post(url, data = null) {
  return await call('post', url, data)
}

export async function put(url, data = null) {
  return await call('put', url, data)
}

export async function remove(url) {
  return await call('delete', url)
}

export default {
  get,
  post,
  put,
}

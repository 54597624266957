import { accessibilityMode } from "./getters";

export default function () {
  return {
    nationalities: [],
    // @TODO hardcoded in FormKit format - should really be returned from the backend and mapped
    optionsGender: [
      {
        label: 'Female',
        value: 0,
      },
      {
        label: 'Male',
        value: 1,
      },
      {
        label: 'Gender Diverse',
        value: 2,
      },
    ],
    optionsPriorExperience: [
      { label: 'Current representative standing for re-election', value: 1 },
      {
        label:
          'Current co-opted or appointed board member standing for election',
        value: 2,
      },
      {
        label:
          'Not a current member but have previously been a member of a school board',
        value: 3,
      },
      { label: 'No previous board experience', value: 4 },
      { label: 'Other', value: 5 },
    ],

    // All the clients Schooled supports
    schools: [],
    // Current school user is accessing
    school: null,
    schoolElections: [],
    currentElection: null,
    // Voting
    votingData: {
      name: '',
      attemptsLeft: null,
      timedOut: false,
      authorised: false,
      tokenUsed: false,
    },
    selectedCandidates: [],
    accessibilityMode: false,
  }
}

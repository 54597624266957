<template>
  <div
    ref="formParent"
    class="flex flex-wrap lg:flex-nowrap w-full bg-white shadow rounded-xl overflow-hidden items-stretch"
  >
    <div ref="toomanyzoozParent" class="w-full lg:w-5/12 bg-primary">
      <div class="divide-y divide-truegray-300">
        <div
          v-for="(step, index) in MEETING_STEPS"
          :key="index"
          class="transition-all p-8 cursor-pointer"
          :class="isActiveStep(index) ? 'bg-white py-6' : 'py-4 bg-primary'"
          @click="() => setActiveStep(index)"
        >
          <p
            class="transition-all mb-0 text-xs font-bold"
            :class="
              isActiveStep(index) ? 'text-primary pl-4' : 'pl-0 text-white'
            "
          >
            Step {{ index + 1 }}
          </p>
          <h5
            class="transition-all mb-0 text-h6"
            :class="
              isActiveStep(index) ? 'text-primary pl-4' : 'pl-0 text-white'
            "
          >
            {{ step.name }}
          </h5>
        </div>
      </div>

      <div v-if="isMeetingFormFilled" class="pb-6">
        <p
          class="text-sm font-bold text-white pb-2 mb-2 border-b border-white mt-6 mx-8 pr-2 w-[max-content]"
        >
          Meeting fields
        </p>
        <div ref="sickofanimationsParent" class="mx-8">
          <div v-if="meetingForm.title" class="flex mb-1">
            <p class="text-xs text-white font-bold mb-0">Meeting title:</p>
            <p class="text-xs text-white ml-4 mb-0">{{ meetingForm.title }}</p>
          </div>

          <div v-if="meetingForm.date" class="flex mb-1">
            <p class="text-xs text-white font-bold mb-0">Meeting date:</p>
            <p class="text-xs text-white ml-4 mb-0">
              {{ formatDate(meetingForm.date) }}
            </p>
          </div>

          <div v-if="meetingForm.time" class="flex mb-1">
            <p class="text-xs text-white font-bold mb-0">Meeting time:</p>
            <p class="text-xs text-white ml-4 mb-0">{{ meetingForm.time }}</p>
          </div>

          <div v-if="meetingForm.locationTitle" class="flex mb-1">
            <p class="text-xs text-white font-bold mb-0">Location:</p>
            <p class="text-xs text-white ml-4 mb-0">
              {{ meetingForm.locationTitle }}
            </p>
          </div>
          <div v-if="meetingForm.locationAddress" class="flex mb-1">
            <p class="text-xs text-white font-bold mb-0">Address:</p>
            <p class="text-xs text-white ml-4 mb-0">
              {{ meetingForm.locationAddress }}
            </p>
          </div>

          <div v-if="meetingForm.locationOnline" class="flex mb-1">
            <p class="text-xs text-white font-bold mb-0">Online link:</p>
            <p class="text-xs text-white ml-4 mb-0">
              {{ meetingForm.locationOnline }}
            </p>
          </div>

          <div v-if="attendees.length" class="flex mb-1 mt-4">
            <p class="text-xs text-white font-bold mb-0">
              Attending: {{ attendees.length }}, Apologies:
              {{ apologies.length }}
            </p>
          </div>

          <div v-if="selectedFiles.length" class="flex mb-1">
            <p class="text-xs text-white font-bold mb-0">
              Files: {{ selectedFiles.length }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-7/12 p-12">
      <FormKit
        v-model="meetingForm"
        type="form"
        :actions="false"
        @submit="onSubmitMeetingForm"
      >
        <div
          :class="['fade-in-out', { show: !isAnimating }]"
          class="transition-opacity duration-300 grid grid-cols-2 w-full gap-4"
        >
          <div v-show="activeStep === 0" class="col-span-2">
            <FormKit
              type="text"
              name="title"
              label="Meeting name (required)"
              validation="required"
              validation-label="Meeting name"
            />
            <FormKit
              type="date"
              name="date"
              label="Meeting date (required)"
              validation="required"
              validation-label="Meeting date"
            />

            <FormKit
              name="time"
              label="Meeting time (required)"
              placeholder="7 pm - 9 pm"
              validation="required"
              validation-label="Meeting time"
              help="Meeting start and end time"
            />
          </div>
          <div v-show="activeStep === 1" class="col-span-2">
            <template v-if="hasDefaultLocations">
              <FormKit
                v-model="selectedLocation"
                placeholder="Select from location defaults"
                type="select"
                name="location"
                label="Default location(s)"
                :options="defaultLocationsMapped"
                select-icon="caretDown"
              />

              <div class="w-full my-6 max-w-[20em] min-w-0 grow">
                <h5 class="text-h6 italic text-center">Or...</h5>
                <p class="brand__text--info text-center m-0">
                  Fill in manually
                </p>
              </div>
            </template>

            <FormKit
              type="text"
              name="locationTitle"
              label="Meeting location name (required)"
              validation="required"
              validation-label="Location name"
            />

            <FormKit
              type="text"
              name="locationAddress"
              label="Meeting location address (required)"
              validation="required"
              validation-label="Location address"
            />

            <FormKit
              type="url"
              name="locationOnline"
              label="Online link (optional)"
              placeholder="https://www.zoom.com"
              validation="url"
              validation-label="Online link"
            />
          </div>
          <div v-show="activeStep === 2" class="col-span-2">
            <div class="flex flex-row items-center gap-3 w-full">
              <div class="overflow-hidden text-xs text-gray-400 w-full">
                <div class="grid grid-cols-2 gap-3 px-1">
                  <MeetingMembersList
                    title="Attendees"
                    :members="attendees"
                    :is-editable="true"
                    wrapper-class="col-span-1"
                    @on-click="toggleUser"
                  />

                  <MeetingMembersList
                    title="Apologies"
                    :members="apologies"
                    :is-editable="true"
                    wrapper-class="col-span-1"
                    @on-click="toggleUser"
                  />
                </div>
                <div class="flex flex-wrap items-center justify-between my-6">
                  <div class="flex items-center">
                    <button
                      type="button"
                      class="rounded-full mr-4 p-2 bg-primary hover:bg-tertiary text-white flex items-center justify-center w-8 h-8"
                      @click="toggleManualAttendeeFormVisible"
                    >
                      <svg
                        v-if="!manualAttendeeFormVisible"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-3 h-3"
                      >
                        <path
                          d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"
                        />
                      </svg>

                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-3 h-3"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75A.75.75 0 0 1 4 10Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    Add External Attendee
                  </div>

                  <ModalAddMissingMembers
                    :existing-members="[...attendees, ...apologies]"
                    @add-missing-members="addMissingMembers"
                  />
                </div>
                <div class=""></div>

                <div
                  ref="manualAttendeeFormParent"
                  class="flex flex-col gap-3 px-1 w-full mt-6"
                >
                  <div
                    v-if="manualAttendeeFormVisible"
                    class="flex flex-col w-full gap-2 mb-8"
                  >
                    <FormKit
                      id="manualAttendeeForm"
                      v-model="manualAttendeeForm"
                      type="form"
                      :actions="false"
                      @submit="onSubmitManualAttendeeForm"
                    >
                      <div class="grid grid-cols-2 w-full gap-4">
                        <div class="col-span-1">
                          <FormKit
                            type="text"
                            name="name"
                            label="Name (required)"
                            validation="required"
                            validation-label="Name"
                          />

                          <FormKit
                            type="text"
                            name="boardRole"
                            label="Role (required)"
                            validation="required"
                            validation-label="Attendee role"
                          />
                        </div>

                        <div class="col-span-1">
                          <FormKit
                            type="email"
                            name="email"
                            label="Email address (required)"
                            validation="required|email|unique"
                            :validation-rules="{
                              unique: isManualEmailUnique,
                            }"
                            :validation-messages="{
                              unique:
                                'A user with this email is already associated with this meeting.',
                            }"
                            placeholder="example@gmail.com"
                            validation-label="Email"
                          />

                          <!--
                            @TODO Portal 2.0
                            phone number validation!
                            :validation-messages="{
                            matches: 'Invalid phone number',
                            }" 
                          -->
                          <FormKit
                            type="tel"
                            name="phone"
                            label="Phone number"
                            placeholder="Home or mobile number"
                          />
                        </div>
                      </div>

                      <div class="flex col-span-2 items-end justify-end gap-4">
                        <div>
                          <FormKit
                            type="button"
                            label="Reset"
                            @click="resetManualAttendeeForm"
                          />
                        </div>
                        <div>
                          <FormKit type="submit" label="Add" />
                        </div>
                      </div>
                    </FormKit>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeStep === 3" class="col-span-2">
            <div class="flex flex-row items-center gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="w-5 h-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
                  clip-rule="evenodd"
                />
              </svg>
              <h6 class="text-primary text-sm">Attachments</h6>
            </div>
            <div v-if="selectedFiles.length" class="flex flex-col pt-4 ml-4">
              <FilesList
                :files="selectedFiles"
                :is-orderable="true"
                @update:selectedFiles="updateSelectedFiles"
              />
            </div>
            <div v-else>
              <p class="text-sm text-truegray-500 mt-4">No files attached...</p>
            </div>
            <div class="w-full mt-6">
              <ModalFileLibrary
                :attached-file-ids="selectedFileIds"
                @update:selectedFiles="updateSelectedFiles"
              />
            </div>
          </div>
          <div
            ref="buttonsParent"
            :key="activeStep + 1"
            :class="[
              'fade-in-out',
              { show: !isAnimating },
              activeStep === 3 ? 'mb-24' : '',
            ]"
            class="col-span-2 mt-8 flex items-center justify-between mr-6"
          >
            <button
              :class="[
                activeStep === 0
                  ? 'opacity-0 pointer-events-none'
                  : 'opacity-100',
                'brand__button--trans-primary',
              ]"
              type="button"
              label="Previous"
              @click="prevStep"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.78 4.22a.75.75 0 0 1 0 1.06L7.06 8l2.72 2.72a.75.75 0 1 1-1.06 1.06L5.47 8.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0Z"
                  clip-rule="evenodd"
                />
              </svg>
              Back
            </button>
            <button
              :class="[
                activeStep === MEETING_STEPS.length - 1
                  ? 'opacity-0 pointer-events-none'
                  : 'opacity-100',
                'brand__button--primary',
              ]"
              type="button"
              label="Next"
              @click="nextStep"
            >
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          v-show="activeStep === MEETING_STEPS.length - 1"
          :class="['fade-in-out', { show: !isAnimating }]"
          class="flex flex-grow justify-start items-end w-full"
        >
          <FormKit
            type="submit"
            label="Save Meeting"
            outer-class="$reset flex justify-center w-full"
            wrapper-class="w-3/4"
            input-class="w-full"
          />
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  toRaw,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { reset } from '@formkit/core'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { formatDate, mapAttendee } from '../store/util.js'
import FilesList from './FilesList.vue'
import MeetingMembersList from './MeetingMembersList.vue'
import ModalFileLibrary from './modal/ModalFileLibrary.vue'
import ModalAddMissingMembers from './modal/ModalAddMissingMembers.vue'

export default defineComponent({
  name: 'CardMeetingUpsert',

  components: {
    FilesList,
    MeetingMembersList,
    ModalFileLibrary,
    ModalAddMissingMembers,
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const [formParent] = useAutoAnimate()
    const [sickofanimationsParent] = useAutoAnimate()
    const [manualAttendeeFormParent] = useAutoAnimate()
    const [toomanyzoozParent] = useAutoAnimate()

    const MEETING_KEYS_MAP = {
      title: 'title',
      date: 'date',
      time: 'time',
      locationTitle: 'location_title',
      locationAddress: 'location_address',
      locationOnline: 'location_online',
    }

    const MEETING_STEPS = [
      {
        name: 'Meeting Details',
      },
      {
        name: 'Location',
      },
      {
        name: 'Attendees',
      },
      {
        name: 'Attachments',
      },
    ]

    const activeStep = ref(0) // Initialize the first step as the active step

    const setActiveStep = (index) => {
      isAnimating.value = true
      activeStep.value = index
      setTimeout(() => {
        isAnimating.value = false
      }, 300)
    }

    const nextStep = () => {
      let index = activeStep.value + 1
      setActiveStep(index)
    }

    const prevStep = () => {
      let index = activeStep.value - 1
      setActiveStep(index)
    }

    const isAnimating = ref(false)

    const isActiveStep = computed(() => (index) => index === activeStep.value)

    const isMeetingFormFilled = computed(() => {
      return Object.values(meetingForm.value).some((value) => value !== null)
    })

    // always close the manual attendee form as it causes a validation error for the main form if active and not filled out
    watch(activeStep, () => {
      manualAttendeeFormVisible.value = false
    })

    const hasDefaultLocations = computed(() => defaultLocations.value?.length)
    const defaultLocations = computed(
      () => store.getters['portal/getMeetingLocationDefaults'],
    )

    const defaultLocationsMapped = computed(() => {
      return (
        [...defaultLocations.value]
          // Default location first
          .sort((a, b) => b.default - a.default)
          .map((location) => ({
            label: location.default
              ? `${location.title} (default)`
              : location.title,
            value: location,
          }))
      )
    })

    // 'value.value' because the first 'value' is to get the computed value, and the second 'value' is to get the 'value' property of the location object
    const selectedLocation = ref(null)

    watch(selectedLocation, () => {
      meetingForm.value.locationAddress = selectedLocation.value.address
      meetingForm.value.locationTitle = selectedLocation.value.title
    })

    const meetingId = computed(() => route.query?.id || null)
    const meeting = computed(() => store.getters['portal/getCurrentMeeting'])
    const meetingForm = ref(
      // Initialise meeting form values to null
      Object.keys(MEETING_KEYS_MAP).reduce((acc, key) => {
        acc[key] = null
        return acc
      }, {}),
    )

    const users = ref([])
    const attendees = computed(() => users.value.filter((m) => m.attending))
    const apologies = computed(() => users.value.filter((m) => !m.attending))
    const toggleUser = (userObj) => {
      users.value = users.value.map((user) => {
        if (userObj.manual) {
          // If manual, use email as the key
          return user.email === userObj.email
            ? { ...user, attending: !user.attending }
            : user
        } else {
          // If not manual, use value (id) as the key
          return user.value === userObj.value
            ? { ...user, attending: !user.attending }
            : user
        }
      })
    }

    const addMissingMembers = (members) => {
      const rawMembers = toRaw(members)
      const mappedMembers = rawMembers.map((member) => {
        const mappedMember = mapAttendee(member)
        return { ...mappedMember, attending: true }
      })
      users.value = [...users.value, ...mappedMembers]
    }

    const manualAttendeeForm = ref({
      name: null,
      email: null,
      phone: null,
      boardRole: null,
    })

    const manualAttendeeFormVisible = ref(false)
    const toggleManualAttendeeFormVisible = () => {
      manualAttendeeFormVisible.value = !manualAttendeeFormVisible.value
    }

    const resetManualAttendeeForm = () => {
      reset('manualAttendeeForm')
    }

    const isManualEmailUnique = (node) => {
      const trimmedVal = node?.value?.trim()?.toLowerCase()
      return !users.value.some(
        (user) => user.email.trim().toLowerCase() === trimmedVal,
      )
    }

    const onSubmitManualAttendeeForm = () => {
      const data = manualAttendeeForm.value
      const user = {
        value: null,
        title: data.name,
        boardRole: data.boardRole,
        email: data.email,
        phone: data.phone,
        attending: true,
        manual: true,
      }

      users.value.push(user)
      resetManualAttendeeForm()
      toggleManualAttendeeFormVisible()
    }

    // Files
    const selectedFiles = ref([])
    const selectedFileIds = computed(() => selectedFiles.value.map((f) => f.id))
    const updateSelectedFiles = (files) => {
      selectedFiles.value = files.map((file, index) => {
        return {
          ...file,
          order: index,
        }
      })
    }

    // JSON stringified user data to store on the meeting model itself,
    // where each key is the user email and value is the user details
    const usersPayloadManual = computed(() => {
      // Only bother keeping manual users who are actually attending
      let manualUsers = users.value?.filter(
        (user) => user.manual && user.attending,
      )

      if (!manualUsers?.length) {
        return null
      }

      manualUsers = manualUsers.reduce((acc, user) => {
        acc[user.email] = {
          id: user.value,
          name: user.title,
          boardRole: user.boardRole,
          email: user.email,
          phone: user.phone,
        }
        return acc
      }, {})

      return JSON.stringify(manualUsers)
    })

    // All we need are the user IDs and attending statuses
    const usersPayload = computed(() => {
      return (
        users.value
          ?.filter((user) => !user.manual)
          .map((user) => {
            return {
              id: user.value,
              attending: user.attending,
            }
          }) || null
      )
    })

    const payload = computed(() => {
      return {
        ...Object.keys(MEETING_KEYS_MAP).reduce((acc, key) => {
          acc[MEETING_KEYS_MAP[key]] = meetingForm.value[key]
          return acc
        }, {}),
        // Add the meeting ID if it already exists (updating not creating)!
        ...(meeting.value?.id ? { id: meeting.value.id } : {}),
        // Add the board member users
        users: usersPayload.value,
        // Add any manual users (defaults to null)
        manualUsers: usersPayloadManual.value,
        // Only send the file data required - id and order
        files: selectedFiles.value.map((file) => {
          return {
            id: file.id,
            order: file.order,
          }
        }),
      }
    })

    const routeToMeetings = () => {
      router.push({ name: 'boardMeetings', params: { id: route.params.id } })
    }

    const onSubmitMeetingForm = async () => {
      await store.dispatch('portal/dispatchUpsertMeeting', payload.value)
      routeToMeetings()
    }

    const initMeetingFormData = () => {
      Object.keys(MEETING_KEYS_MAP).forEach((key) => {
        meetingForm.value[key] = meeting.value[MEETING_KEYS_MAP[key]]
      })
      users.value = meeting.value.users
      initSelectedFilesData()
    }

    const initSelectedFilesData = () => {
      if (meeting.value?.files) {
        // Pre-ordered by 'order' property in the backend
        selectedFiles.value = meeting.value.files
      }
    }

    onMounted(async () => {
      if (meetingId.value) {
        // Existing meeting
        await store.dispatch('portal/dispatchGetMeeting', {
          meetingId: meetingId.value,
        })

        if (meeting.value) {
          // Initialise form with existing meeting data
          initMeetingFormData()
        } else {
          // Meeting wasn't found for some reason - route back to meetings
          routeToMeetings()
        }
      } else {
        // New meeting - get default board member attendees and set users
        await store.dispatch('portal/dispatchGetMeetingDefaultAttendees')
        users.value = store.getters['portal/getDefaultAttendees']
      }
    })

    onUnmounted(() => {
      store.dispatch('portal/dispatchClearMeeting')
    })

    return {
      // Autoanimate
      formParent,
      sickofanimationsParent,
      manualAttendeeFormParent,
      toomanyzoozParent,

      // Steps & step animations
      MEETING_STEPS,
      activeStep,
      setActiveStep,
      nextStep,
      prevStep,
      isActiveStep,
      isAnimating,
      isMeetingFormFilled,
      formatDate,

      // Meeting/form
      meetingId,
      meeting,
      meetingForm,
      onSubmitMeetingForm,

      // locations
      hasDefaultLocations,
      defaultLocations,
      defaultLocationsMapped,
      selectedLocation,

      // Users
      attendees,
      apologies,
      toggleUser,
      addMissingMembers,

      // Manual users
      manualAttendeeForm,
      manualAttendeeFormVisible,
      toggleManualAttendeeFormVisible,
      resetManualAttendeeForm,
      isManualEmailUnique,
      onSubmitManualAttendeeForm,

      // Files
      selectedFiles,
      selectedFileIds,
      updateSelectedFiles,
    }
  },
})
</script>

<style scoped>
.fade-in-out {
  transition: opacity 0ms;
  opacity: 0;
}

.fade-in-out.show {
  opacity: 1;
  transition: opacity 300ms;
}
</style>

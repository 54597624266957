<template>
  <div class="h-screen flex bg-white text-ces-blue">
    <div class="flex min-h-full flex-1 relative">
      <div
        class="w-full flex flex-1 flex-col items-center sm:items-start sm:justify-center px-8 py-12 sm:px-12 lg:flex-none lg:px-32"
      >
        <img
          src="/img/logo/schooled-logo-inverted.svg"
          alt="Schooled Logo"
          class="w-60 mb-10 sm:hidden"
        />

        <!-- Is resetting password -->
        <div
          v-if="hasResetPassword"
          class="w-full z-10 relative space-y-4 max-w-xs"
        >
          <div class="flex flex-col items-center sm:items-start gap-6 mb-12">
            <h3 class="leading-10 text-center sm:text-left">Thank you!</h3>
            <p class="mb-0">
              Instructions to reset your password have been sent to {{ email }}.
            </p>
            <button
              type="button"
              class="brand__button--tertiary"
              @click="resetSignIn"
            >
              Login
            </button>
          </div>
        </div>
        <!-- /Is resetting password -->

        <div v-else class="flex flex-col w-full z-10 relative gap-4 max-w-xs">
          <div class="flex flex-col">
            <router-link
              :to="{ name: 'welcome' }"
              class="text-sm flex flex-row items-center gap-2 mb-6"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
              <span>Back</span>
            </router-link>
            <h3 class="leading-10 mb-4">
              {{ titleText }}
            </h3>
            <p v-if="isResettingPassword" class="text-sm mb-0">
              Enter your email address to receive instructions for resetting
              your password.
            </p>
          </div>

          <div>
            <label for="email" class="block sm:mt-px"> Email address </label>
            <div class="mt-2">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                class="block w-full rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-tertiary focus:border-tertiary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div v-if="!isResettingPassword">
            <div class="flex justify-between items-center">
              <label for="password" class="block sm:mt-px"> Password </label>
            </div>
            <div class="mt-2">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                class="block w-full rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-tertiary focus:border-tertiary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div v-if="validationHint" class="py-2">
            <p class="text-red-600 text-xs">
              {{ validationHint }}
            </p>
          </div>

          <div class="flex space-x-2 mt-5">
            <button
              :class="[{ 'opacity-50 cursor-not-allowed': !canSubmit }]"
              type="button"
              class="brand__button--tertiary"
              @click="onSubmit"
            >
              <div v-if="isSubmitting" class="h-full w-8">
                <Vue3Lottie :animation-data="ANIMATION_LOADING.DEFAULT" />
              </div>
              <div v-else-if="isResettingPassword">Reset password</div>
              <span v-else> Login </span>
            </button>
          </div>
        </div>
        <!-- Show the enquiry form -->
      </div>
      <div
        class="absolute bottom-0 left-0 w-full flex flex-1 flex-col items-center sm:items-start sm:justify-center px-8 sm:px-12 lg:px-32 py-6"
      >
        <div class="w-full max-w-xs">
          <p class="text-xs mb-0">
            Login as a
            <a class="text-xs" href="/admin-panel"> Schooled admin </a>
          </p>
        </div>
      </div>
    </div>
    <div
      class="hidden w-0 flex-1 sm:flex items-center justify-center w-full h-full bg-primary"
    >
      <img
        src="/img/logo/schooled-logo-trans.svg"
        alt="Schooled Logo"
        class="w-80 h-80"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING, GENERIC_ERROR_MESSAGE } from '../../constants.js'

export default defineComponent({
  components: {
    Vue3Lottie,
  },

  props: {
    // routed here with query (e.g., password reset for this email)
    emailQuery: {
      type: String,
      default: '',
    },

    resetPassword: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    // Form
    const titleText = computed(() =>
      isResettingPassword.value ? 'Reset your password' : 'Welcome back!',
    )
    const password = ref(null)
    const email = ref(null)
    const validationHint = ref(null)
    const emailValid = computed(() => {
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      const valid = !!regex.test(email.value)
      return valid
    })
    const passwordValid = computed(() => {
      const valid = password.value
      return valid
    })

    const isSubmitting = ref(false)
    const isResettingPassword = ref(props.resetPassword)
    const canSubmit = computed(() => {
      if (isResettingPassword.value) {
        return canResetPassword.value
      }

      return emailValid.value && passwordValid.value
    })
    const canResetPassword = computed(() => {
      return (
        isResettingPassword.value && !isSubmitting.value && emailValid.value
      )
    })

    const hasResetPassword = ref(false)
    const resetSignIn = () => {
      router.push({ path: '/signin' })
      email.value = null
      password.value = null
      validationHint.value = null
      isSubmitting.value = false
      isResettingPassword.value = false
      hasResetPassword.value = false
    }

    const onSubmit = async () => {
      if (!canSubmit.value) {
        return false
      }

      isSubmitting.value = true
      validationHint.value = null

      if (isResettingPassword.value) {
        await resetPassword()
        isSubmitting.value = false
        return
      }

      const response = await store.dispatch('portal/dispatchSignIn', {
        email: email.value,
        password: password.value,
      })

      isSubmitting.value = false

      if (response) {
        router.push({ path: '/portal' })
      } else {
        validationHint.value = response.alert?.message || GENERIC_ERROR_MESSAGE
      }
    }

    const resetPassword = async () => {
      const payload = { email: email.value }
      const response = await store.dispatch(
        'auth/dispatchResetPassword',
        payload,
      )
      isResettingPassword.value = false

      if (response) {
        hasResetPassword.value = true
      }
    }

    const resetValidationHint = () => {
      validationHint.value = null
    }

    watch(email, resetValidationHint)
    watch(password, resetValidationHint)

    onMounted(() => {
      if (props.emailQuery) {
        email.value = props.emailQuery
      }
    })

    return {
      ANIMATION_LOADING,
      titleText,
      email,
      password,
      validationHint,
      hasResetPassword,
      canSubmit,
      canResetPassword,
      isSubmitting,
      isResettingPassword,
      onSubmit,
      resetPassword,
      resetSignIn,
    }
  },
})
</script>

<style scoped>
/* Remove yellow background on input autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db;
}
</style>

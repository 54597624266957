<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    :aria-labelledby="iconName"
    role="presentation"
    class="inline-block align-baseline"
  >
    <g :fill="iconColor">
      <path :d="iconPath" />
    </g>
  </svg>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ICONS from '../../../public/img/icons/icons.js'

export default defineComponent({
  name: 'svg-icon',

  props: {
    iconName: {
      type: String,
      default: 'email',
    },

    size: {
      type: [Number, String],
      default: 32,
    },

    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },

  setup(props) {
    const icon = computed(() => {
      return ICONS[props.iconName]
    })
    const iconPath = icon.value

    return {
      iconPath,
    }
  },
})
</script>

export function UPDATE_NATIONALITIES(state, data) {
  state.nationalities = data
}

export function CLEAR_NATIONALITIES(state) {
  state.nationalities = []
}

export function UPDATE_SCHOOL(state, data) {
  state.school = data
}

export function CLEAR_SCHOOL(state) {
  state.school = null
}

export function UPDATE_SCHOOLS(state, data) {
  state.schools = data
}

export function CLEAR_SCHOOLS(state) {
  state.schools = []
}

export function UPDATE_ELECTIONS(state, data) {
  state.elections = data
}

export function CLEAR_ELECTIONS(state) {
  state.elections = []
}

export function UPDATE_SCHOOL_ELECTIONS(state, data) {
  state.schoolElections = data
}

export function CLEAR_SCHOOL_ELECTIONS(state) {
  state.schoolElections = []
}

export function UPDATE_CURRENT_ELECTION(state, data) {
  state.currentElection = data
}

export function CLEAR_CURRENT_ELECTION(state) {
  state.currentElection = null
}

export function UPDATE_VOTING_DATA(state, data) {
  state.votingData = { ...state.votingData, ...data };
}

export function UPDATE_SELECTED_CANDIDATES(state, data) {
  state.selectedCandidates = data
}

export function CLEAR_SELECTED_CANDIDATES(state) {
  state.selectedCandidates = []
}

export function UPDATE_ACCESSIBILITY_MODE(state, data) {
  state.accessibilityMode = data
}
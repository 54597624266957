import { API_BASE, get, post } from './api.js'

export async function getBenchmarkOptions() {
  return await get(`${API_BASE}/get-benchmark-options`)
}

export async function getWelcomeReport(payload) {
  return await post(`${API_BASE}/generate-welcome-report`, payload)
}

export async function submitEnquiry(payload) {
  return await post(`${API_BASE}/submit-enquiry`, payload)
}

<template>
  <div class="h-screen flex bg-white">
    <div class="flex min-h-full flex-1 relative">
      <div
        class="w-full flex flex-1 flex-col items-center sm:items-start sm:justify-center px-8 py-12 sm:px-12 lg:flex-none lg:px-32"
      >
        <img
          src="/img/logo/schooled-logo-inverted.svg"
          alt="Schooled Logo"
          class="w-80 h-80 sm:hidden"
        />

        <div class="w-full z-10 relative space-y-4 w-4/5">
          <div class="flex flex-col mb-6">
            <h3 class="leading-10 mb-8">
              Welcome to the Schooled Board Portal
            </h3>
            <p class="text-sm mb-1">
              To login please click on the login button below.
            </p>
            <p class="text-sm mb-0">
              If you have forgotten your password click
              <router-link
                :to="{ name: 'signIn', query: { resetPassword: true } }"
                class="text-sm"
              >
                here
              </router-link>
              to reset.
            </p>
          </div>
          <div class="flex items-center justify-center flex-col">
            <router-link :to="{ name: 'signIn' }" class="w-full">
              <button
                class="brand__button--tertiary w-full p-4 !h-12"
                type="button"
              >
                Login
              </button>
            </router-link>
          </div>
          <div>
            <p class="mb-0 text-xs mt-4">
              If you have any problems you can contact our Support Team at
              support@hail.to
            </p>
          </div>
        </div>
        <!-- Show the enquiry form -->
      </div>
      <div class="absolute bottom-0 left-0 w-full px-8 sm:px-12 lg:px-32 py-6">
        <p class="text-xs mb-0">
          Login as a
          <a class="w-full text-xs" href="/admin-panel"> Schooled admin </a>
        </p>
      </div>
    </div>
    <div
      class="hidden w-0 flex-1 sm:flex items-center justify-center w-full h-full bg-primary"
    >
      <img
        src="/img/logo/schooled-logo-trans.svg"
        alt="Schooled Logo"
        class="w-80 h-80"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ANIMATION_LOADING } from '../../constants.js'

export default defineComponent({
  setup() {
    return {
      ANIMATION_LOADING,
    }
  },
})
</script>

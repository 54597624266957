<template>
  <div class="flex-grow p-16">
    <div class="flex flex-col">
      <p class="text-tertiary font-bold text-sm mb-2">Candidate nomination</p>
      <h3 class="text-primary">{{ selectedElection?.election_name }}</h3>
    </div>

    <FormNomination />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import FormNomination from './FormNomination.vue'

export default defineComponent({
  name: 'ElectionNomination',
  components: { FormNomination },

  setup() {
    const store = useStore()

    const selectedElection = computed(
      () => store.getters['elections/getCurrentElection'],
    )

    return {
      selectedElection,
    }
  },
})
</script>

<template>
  <div :ref="orderableRef" class="flex flex-col gap-3 w-max">
    <div
      v-for="(file, index) in orderedFiles"
      :key="file.id"
      :class="['flex flex-row items-center gap-3 w-full text-sm']"
    >
      <span v-if="isOrderable">{{ index + 1 }}.</span>

      <!-- Card component - downloadable/clickable vs display-only -->
      <component
        :is="isOrderable || isFileBeingModified(file.id) ? 'div' : 'a'"
        :href="
          !isOrderable && canViewFile(file) ? file.file || file.video_url : null
        "
        rel="noopener noreferrer"
        target="_blank"
        :title="isOrderable ? '' : 'Download file'"
        :class="[
          'brand__link--primary flex flex-grow flex-row items-center justify-between border rounded-lg relative bg-white group w-max',
          {
            'cursor-grab active:cursor-grabbing hover:bg-gray-50': isOrderable,
          },
          isBoardResourcesContext ? 'px-5 py-3 shadow-md' : 'px-3 py-2',
          file.public_excluded && !isFileLord ? 'opacity-50' : '',
        ]"
      >
        <!-- Icon and text -->
        <div ref="iconParent" :class="['flex items-center gap-4']">
          <div>
            <!-- Delete icon -->
            <svg
              v-if="isFileBeingDeleted(file.id)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                clip-rule="evenodd"
              />
            </svg>
            <!-- /Delete icon -->

            <!-- Edit icon -->
            <svg
              v-else-if="isFileBeingEdited(file.id)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                d="m2.695 14.762-1.262 3.155a.5.5 0 0 0 .65.65l3.155-1.262a4 4 0 0 0 1.343-.886L17.5 5.501a2.121 2.121 0 0 0-3-3L3.58 13.419a4 4 0 0 0-.885 1.343Z"
              />
            </svg>
            <!-- /Edit icon -->

            <!-- Draggable icon -->
            <svg
              v-else-if="isOrderable"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M2 3.75A.75.75 0 0 1 2.75 3h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75Zm0 4.167a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Zm0 4.166a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Zm0 4.167a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z"
                clip-rule="evenodd"
              />
            </svg>
            <!-- /Draggable icon -->

            <!-- Document icon -->
            <svg
              v-else-if="isBoardResourcesContext"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              :class="['w-4 h-4']"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            <!-- /Document icon -->

            <!-- Attachment icon -->
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              :class="['w-4 h-4']"
            >
              <path
                fill-rule="evenodd"
                d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
                clip-rule="evenodd"
              />
            </svg>
            <!-- /Attachment icon -->
          </div>

          <!-- Edit input -->
          <div
            v-if="isFileBeingEdited(file.id)"
            class="flex items-center space-x-8"
          >
            <div class="flex flex-col space-y-2">
              <FormKit
                :ref="(el) => (editInput[file.id] = el)"
                :key="editedFile.id"
                v-model="editedFile.title"
                type="text"
                outer-class="$remove:mb-4 mb-0 text-sm font-semibold"
                inner-class="$reset"
                input-class="$reset border-0 p-0 text-sm font-semibold focus:ring-0"
              />
            </div>
          </div>
          <!-- /Edit input -->

          <!-- File title -->
          <span v-else-if="file.public_excluded && !isFileLord">
            Title removed - Confidential
          </span>

          <span
            v-else
            :class="[
              'text-sm',
              {
                'text-red-300 line-through': isFileBeingDeleted(file.id),
              },
            ]"
          >
            {{ file.title }}
          </span>
          <!-- /File title -->
        </div>
        <!-- /Icon and text -->

        <!-- Public excluded icon -->
        <div v-if="file.public_excluded" title="Private file (public excluded)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4 text-gray-400"
          >
            <path
              fill-rule="evenodd"
              d="M10 1a4.5 4.5 0 0 0-4.5 4.5V9H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-.5V5.5A4.5 4.5 0 0 0 10 1Zm3 8V5.5a3 3 0 1 0-6 0V9h6Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <!-- /Public excluded icon -->
      </component>
      <!-- /Card component -->

      <!-- File actions/CRUD (for Board Resources) -->
      <div v-if="isBoardResourcesContext" ref="actionsParent">
        <!-- Buttons - cancel and save/delete -->
        <div
          v-if="isModifyingFile"
          :class="[
            'flex space-x-2',
            { invisible: !isFileBeingModified(file.id) },
          ]"
        >
          <div
            title="Cancel"
            class="rounded-xl text-black hover:text-white cursor-pointer w-8 h-8 hover:bg-primary opacity-100 hover:opacity-60 flex items-center justify-center"
            @click.stop.prevent="resetFileActions"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"
              />
            </svg>
          </div>

          <div
            :title="isEditingFile ? 'Save' : 'Delete'"
            :class="[
              'rounded-xl hover:text-white transition-all cursor-pointer w-8 h-8 opacity-100 hover:opacity-30 flex items-center justify-center',
              {
                'text-green-500 hover:bg-green-500': isEditingFile,
                'text-red-400 hover:bg-red-400': isDeletingFile,
              },
            ]"
            @click.stop.prevent="onSubmitFileAction"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <!-- /Buttons - cancel and save/delete -->

        <!-- Buttons - edit and delete -->
        <div
          v-else-if="!isFileBeingModified(file.id)"
          :class="['flex space-x-2', { invisible: isModifyingFile }]"
        >
          <div
            title="Edit name"
            class="rounded-xl text-black hover:text-white cursor-pointer w-8 h-8 hover:bg-primary opacity-100 hover:opacity-60 flex items-center justify-center"
            @click.stop.prevent="() => editFile(file)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z"
              />
              <path
                d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z"
              />
            </svg>
          </div>

          <div
            title="Delete file"
            class="rounded-xl text-red-400 hover:text-white transition-all cursor-pointer w-8 h-8 hover:bg-red-400 opacity-100 hover:opacity-30 flex items-center justify-center"
            @click.stop.prevent="() => deleteFile(file.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <!-- /Buttons - edit and delete -->
      </div>
      <!-- /File actions/CRUD -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useDragAndDrop } from '@formkit/drag-and-drop/vue'
import { useAutoAnimate } from '@formkit/auto-animate/vue'

export default defineComponent({
  name: 'FilesList',

  props: {
    files: { type: Array, default: () => [] },
    isOrderable: { type: Boolean, default: false },
    isBoardResourcesContext: { type: Boolean, default: false },
    wrapperClass: { type: String, default: '' },
  },

  emits: ['update:selectedFiles'],

  setup(props, { emit }) {
    const store = useStore()
    const isFileLord = computed(() => store.getters['auth/isFileLord'])
    const canViewFile = (file) => !file.public_excluded || isFileLord.value

    const [iconParent] = useAutoAnimate()
    const [actionsParent] = useAutoAnimate()

    // #region Orderable data
    const [parentRef, orderedFiles] = useDragAndDrop(props.files)
    const orderableRef = computed(() =>
      props.isOrderable ? 'parentRef' : null,
    )
    const isUpdatingComponentData = ref(false)
    // #endregion

    // #region File actions/CRUD (Board Resources)
    const LIBRARY_TYPE = 'RESOURCE'
    const isModifyingFile = computed(
      () => isEditingFile.value || isDeletingFile.value,
    )

    const isFileBeingModified = (fileId) =>
      isFileBeingEdited(fileId) || isFileBeingDeleted(fileId)

    const resetFileActions = () => {
      if (isEditingFile.value) {
        resetEditedFile()
      } else if (isDeletingFile.value) {
        resetDeletedFile()
      }
    }

    const onSubmitFileAction = () => {
      if (isEditingFile.value) {
        onSubmitEditFile()
      } else if (isDeletingFile.value) {
        onSubmitDeleteFile()
      }
    }

    // #region Edit file
    const editInput = ref({})
    const editedFile = ref(null)
    const isEditingFile = computed(() => !!editedFile.value)
    const isFileBeingEdited = (fileId) => {
      return editedFile.value?.id === fileId
    }

    const editFile = (file) => {
      editedFile.value = {
        originalTitle: file.title,
        ...file,
      }
    }

    const resetEditedFile = () => {
      editedFile.value = null
    }

    const onSubmitEditFile = async () => {
      if (editedFile.value?.title?.length) {
        const payload = {
          id: editedFile.value.id,
          title: editedFile.value.title,
          libraryType: LIBRARY_TYPE,
        }

        const success = await store.dispatch('portal/dispatchEditFile', payload)
        if (success) {
          resetEditedFile()
        }
      }
    }
    // #endregion

    // #region Delete file
    const deletedFileId = ref(null)
    const deletedFile = computed(() => {
      if (deletedFileId.value) {
        return orderedFiles.value.find(
          (file) => file.id === deletedFileId.value,
        )
      }

      return null
    })

    const isDeletingFile = computed(() => !!deletedFileId.value)
    const isFileBeingDeleted = (fileId) => {
      return deletedFileId.value === fileId
    }

    const resetDeletedFile = () => {
      deletedFileId.value = null
    }

    const deleteFile = (fileId) => {
      deletedFileId.value = fileId
    }

    const onSubmitDeleteFile = async () => {
      const payload = {
        id: deletedFileId.value,
        libraryType: LIBRARY_TYPE,
      }

      const success = await store.dispatch('portal/dispatchDeleteFile', payload)

      if (success) {
        resetDeletedFile()
      }
    }
    // #endregion
    // #endregion

    // #region Data watchers
    // isUpdatingComponentData and nextTick are very important to
    // prevent recursion updating parent/child files data!
    // Otherwise these watchers will loop infinitely.
    watch(orderedFiles, async (files) => {
      if (!isUpdatingComponentData.value) {
        isUpdatingComponentData.value = true
        emit('update:selectedFiles', files)
        await nextTick()
        isUpdatingComponentData.value = false
      }
    })

    watch(
      () => props.files,
      async () => {
        if (!isUpdatingComponentData.value) {
          isUpdatingComponentData.value = true
          orderedFiles.value = props.files
          await nextTick()
          isUpdatingComponentData.value = false
        }
      },
    )
    // #endregion

    return {
      canViewFile,
      isFileLord,

      iconParent,
      actionsParent,

      orderableRef,
      parentRef,
      orderedFiles,

      isModifyingFile,
      isFileBeingModified,
      resetFileActions,
      onSubmitFileAction,

      editedFile,
      isEditingFile,
      editInput,
      editFile,
      isFileBeingEdited,
      resetEditedFile,
      onSubmitEditFile,

      deletedFile,
      isDeletingFile,
      isFileBeingDeleted,
      deleteFile,
      onSubmitDeleteFile,
    }
  },
})
</script>

<template>
  <div :class="['w-full p-6 py-12 lg:px-12', wrapperClasses]">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageContent',

  props: {
    wrapperClasses: {
      type: String,
      default: '',
    },
  },
})
</script>

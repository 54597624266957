export default function () {
  return {
    // all the schools this user has access to
    schools: [],
    // current school user is accessing
    school: null,
    // current settings for school user is accessing
    // {
    //   uuid : 1,
    //   name : 'School 1'
    // },
    settings: null,
    isUpdatingLogo: false,
    meetings: null,
    currentMeeting: null,
    defaultAttendees: [],
    reports: [],
    owners: [],
    members: [],
    libraryMeetingFiles: [],
    libraryResourceFiles: [],
    libraryMiscFiles: [],
  }
}

import { createApp } from 'vue'
import { GOOGLE_RECAPTCHA_SITE_KEY_LOCAL } from './constants.js'

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
  // } else {
  //   console.error(
  //     'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token',
  //   )
}

// Create an Event Bus...
// eventBus.js
// import emitter from './emitter'

import Auth from './auth.js'
window.auth = new Auth()

import router from './router'
import Axios from 'axios'
import VueAxios from 'vue-axios'

// Set the X-CERF-TOKEN for our forms
Axios.defaults.headers.common['X-CSRF-TOKEN'] = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute('content')

// Charts/graphs
require('chartist')
// import Chartist from 'chartist'

// Vue Store management
import store from './store/'
import Base from './Base.vue'
const app = createApp(Base)

// Register customised svg icons and logo...
import SvgIcon from './components/SvgIcon.vue'
import SvgLogo from './components/SvgLogo.vue'
// FormKit (form library)
import { plugin, defaultConfig } from '@formkit/vue'
import formkitConfig from '../../formkit.config.js'
// Vue3 recaptcha
import { VueReCaptcha } from 'vue-recaptcha-v3'

app.component('SvgIcon', SvgIcon)
app.component('SvgLogo', SvgLogo)

app.use(router, VueAxios, Axios)
app.use(store) // For some reason this does not play nice if we include in group above!
app.use(plugin, defaultConfig(formkitConfig))
app.use(VueReCaptcha, {
  siteKey: GOOGLE_RECAPTCHA_SITE_KEY_LOCAL,
})
// app.use(Axios);
app.mount('#app')

export function isLoading(state) {
  return state.isLoading
}

export function getAlertToast(state) {
  return state.alertToast
}

export const isElectionsContext = () => (route) => {
  return route?.includes('elections')
}

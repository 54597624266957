import { createWebHistory, createRouter, START_LOCATION } from 'vue-router'
import store from '../store/index.js'
import Welcome from './routes/Welcome.vue'
import SignIn from './routes/SignIn.vue'
import ResetPasswordValidation from './routes/ResetPasswordValidation.vue'
import Portal from './routes/Portal.vue'
import PortalBoardMeetings from './routes/PortalBoardMeetings.vue'
import PortalDashboard from './routes/PortalDashboard.vue'
import PortalBoardResources from './routes/PortalBoardResources.vue'
import PortalResources from './routes/PortalResources.vue'
import PortalHelp from './routes/PortalHelp.vue'
import PortalSettings from './routes/PortalSettings.vue'
import ElectionsPortal from './routes/elections/ElectionsPortal.vue'
import ElectionsHome from './routes/elections/Home.vue'
import ElectionsVoting from './routes/elections/ElectionVoting.vue'

const getSchoolById = (to) => {
  const schoolId = Number.parseInt(to.params.id) || null
  const userSchools = store.getters['portal/getSchools']
  const defaultSchool = userSchools?.[0]

  const school = schoolId
    ? userSchools.find((school) => school.id === schoolId) || defaultSchool
    : defaultSchool

  return school
}

const getSchoolBySlug = (to) => {
  const schoolSlug = to.params.clientSlug || null
  const userSchools = store.getters['elections/getSchools']
  const defaultSchool = userSchools?.[0]

  const school = schoolSlug
    ? userSchools.find((school) => school.slug === schoolSlug) || defaultSchool
    : defaultSchool

  return school
}

const setCurrentElection = () => {
  // If there's only one election, set it to active
  const elections = store.getters['elections/getSchoolElections']
  // @TODO : this is causing election id not to be set in the route 
  // params meaning submit nomination button does not work
  // if (elections.length === 1) {
  //   const electionId = elections[0].id
  //   store.dispatch('elections/dispatchUpdateCurrentElection', electionId)
  // }
}

const beforeEnterElections = async (to, from, next, actions = []) => {
  const schoolSlugCurrent = to.params.clientSlug

  if (schoolSlugCurrent) {
    const school = getSchoolBySlug(to)
    const schoolSlugDefault = school?.slug

    const invalidSlug = schoolSlugCurrent !== schoolSlugDefault

    if (invalidSlug) {
      const slug = schoolSlugDefault
      store.dispatch('elections/dispatchUpdateSchool', school)
      for (const action of actions) {
        await store.dispatch(action)
      }

      setCurrentElection()
      next({ name: to.name, ...to.params, clientSlug: slug })
    } else {
      store.dispatch('elections/dispatchUpdateSchool', school)
      for (const action of actions) {
        await store.dispatch(action)
      }

      setCurrentElection()
      next()
    }
  }

  next()
}

const mapRouteDisplayOrder = (item, index) => {
  if (item.meta?.navConfig) {
    item = {
      ...item,
      meta: {
        ...item.meta,
        navConfig: {
          ...item.meta.navConfig,
          displayOrder: index,
        },
      },
    }
  }

  return item
}

const routesPortalChildren = [
  {
    path: '',
    name: 'dashboard',
    component: PortalDashboard,
    props: true,
    meta: {
      navConfig: {
        name: 'Overview',
        icon: 'dashboard',
      },
      actions: ['portal/dispatchGetMeetings'],
    },
  },
  // {
  //   path: 'board-reports',
  //   name: 'boardReports',
  //   component: PortalBoardReports,
  //   props: true,
  //   meta: {
  //     navConfig: {
  //       name: 'Board Documents',
  //       icon: 'stat',
  //     },
  //     actions: ['portal/dispatchGetReports'],
  //   },
  // },
  {
    path: 'board-meetings',
    name: 'boardMeetings',
    component: PortalBoardMeetings,
    props: true,
    meta: {
      navConfig: {
        name: 'Board Meetings',
        icon: 'calendar',
      },
      actions: ['portal/dispatchGetMeetings'],
    },
    children: [
      {
        path: 'meeting',
        name: 'boardMeeting',
        component: PortalBoardMeetings,
        props: true,
      },
    ],
  },

  {
    path: 'board-resources',
    name: 'boardResources',
    component: PortalBoardResources,
    props: true,
    meta: {
      navConfig: {
        name: 'Board Resources',
        icon: 'stat',
      },
      actions: ['portal/dispatchGetLibraryFiles'],
    },
  },
  {
    path: 'portal-resources',
    name: 'portalResources',
    component: PortalResources,
    props: true,
    meta: {
      navConfig: {
        name: 'Portal Resources',
        icon: 'board',
      },
      actions: [],
    },
  },
  {
    path: 'help',
    name: 'portalHelp',
    component: PortalHelp,
    props: true,
    meta: {
      navConfig: {
        name: 'Help & Guides',
        icon: 'help',
      },
      actions: [],
    },
  },
  {
    path: 'settings',
    name: 'settings',
    component: PortalSettings,
    props: true,
    meta: {
      settings: true,
    },
    children: [
      {
        path: 'my-board',
        name: 'myBoard',
        props: true,
        component: PortalSettings,
        meta: {
          childComponent: 'SettingsMyBoard',
          isChild: true,
          actions: ['portal/dispatchGetMembers', 'portal/dispatchGetOwners'],
        },
      },
      {
        path: 'add-board-member',
        name: 'addBoardMember',
        props: true,
        component: PortalSettings,
        meta: {
          childComponent: 'SettingsAddBoardMember',
          isChild: true,
          actions: ['portal/dispatchGetMembers'],
        },
      },
    ],
  },
].map(mapRouteDisplayOrder)

const routesElectionsChildren = [
  {
    path: ':clientSlug?/:electionId?/vote',
    name: 'schoolVote',
    component: ElectionsVoting,
    props: true,
    meta: {},
    beforeEnter: (to, from, next) =>
      beforeEnterElections(to, from, next, [
        'elections/dispatchUpdateSchoolElections',
        'elections/dispatchUpdateNationalities',
      ]),
  },
  {
    path: ':clientSlug?/:electionId?/nominate',
    name: 'schoolNominate',
    component: ElectionsHome,
    props: true,
    meta: {
      hackyViewport: true,
    },
    beforeEnter: (to, from, next) =>
      beforeEnterElections(to, from, next, [
        'elections/dispatchUpdateSchoolElections',
        'elections/dispatchUpdateNationalities',
      ]),
  },
  {
    path: ':clientSlug?/:electionId?',
    name: 'schoolElection',
    component: ElectionsHome,
    props: true,
    beforeEnter: (to, from, next) =>
      beforeEnterElections(to, from, next, [
        'elections/dispatchUpdateSchoolElections',
      ]),
  },
  {
    path: ':clientSlug?',
    name: 'schoolHome',
    component: ElectionsHome,
    props: true,
    meta: {},
    beforeEnter: (to, from, next) =>
      beforeEnterElections(to, from, next, [
        'elections/dispatchUpdateSchoolElections',
      ]),
  },
  {
    path: '',
    name: 'electionsHome',
    component: ElectionsHome,
    beforeEnter: (to, from, next) => {
      store.dispatch('elections/dispatchUpdateSchool')
      next()
    },
  },
]

const routes = [
  {
    path: '/signin',
    name: 'signIn',
    component: SignIn,
    props: (route) => ({
      emailQuery: route.query.email,
      resetPassword: route.query.resetPassword === 'true',
    }),
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordValidation,
  },

  {
    path: '/elections',
    name: 'elections',
    component: ElectionsPortal,
    props: true,
    children: routesElectionsChildren,
    beforeEnter: async () => {
      await store.dispatch('elections/dispatchUpdateSchools')
    },
  },

  {
    path: '/portal/:id?',
    name: 'portal',
    component: Portal,
    props: true,
    children: routesPortalChildren,
    meta: { middlewareAuth: true },
    beforeEnter: (to, from, next) => {
      const schoolIdCurrent = Number.parseInt(to.params?.id)
      const schooldIdDefault = getSchoolById(to)?.id
      // invalid if 'id' is not a number or not one of this user's schools
      const invalidId =
        !Number.parseInt(schoolIdCurrent) ||
        schoolIdCurrent !== schooldIdDefault

      if (invalidId) {
        // if invalid, route to default school
        const id = schooldIdDefault
        next({ name: to.name, params: { id } })
      } else {
        next()
      }
    },
  },

  {
    path: '/',
    name: 'welcome',
    component: Welcome,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const redirectToWelcome = (next) => {
  next({ name: 'welcome' })
  return
}

router.beforeEach(async (to, from, next) => {
  window.Path = from.path

  /** Do not allow access to these routes if the user is not an administrator...  */
  if (to.matched.some((record) => record.meta.middlewareAuth)) {
    if (
      from.path !== '/signin' &&
      (from === START_LOCATION ||
        (!from.path.includes('/portal') && to.path.includes('/portal')) ||
        (from.path.includes('/admin') && to.path.includes('/portal')))
    ) {
      await auth.updateUser()
    }
    const isAuthenticated = auth.isAuthenticated()
    // Kick out if unauthenticated
    if (!isAuthenticated) {
      // not auth'd - redirect to sign in
      return redirectToWelcome(next)
    } else {
      // route has changed - make sure the current school is set correctly
      const school = getSchoolById(to)
      await store.dispatch('portal/dispatchSetSchool', school)

      // Kick out if not permitted
      if (!auth.isPermitted()) {
        return redirectToWelcome(next)
      }

      // @TODO global loader and route actions
      // if (to.meta?.actions) {
      //   await store.dispatch('global/dispatchUpdateIsLoading', true)
      // for (const action of to.meta.actions) {
      //   await store.dispatch(action)
      // }
      //   await store.dispatch('global/dispatchUpdateIsLoading')
      // }
    }
  }

  next()
})

export default router

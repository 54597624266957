<template>
  <div ref="parentRef" :class="[CLASS.WRAPPER, 'flex-shrink-0', wrapperClass]">
    <!-- Loading state -->
    <div v-if="isUpdatingLogo" :class="[CLASS.INNER, 'bg-lightgrey']">
      <Vue3Lottie :animation-data="ANIMATION_LOADING.DEFAULT" />
    </div>
    <!-- /Loading state -->

    <!-- Actual logo -->
    <img
      v-else-if="imageSrc"
      :class="[CLASS.INNER, 'border-lightgray']"
      :src="imageSrc"
    />
    <!-- /Actual logo -->

    <!-- Placeholder logo -->
    <svg
      v-else-if="showPlaceholder"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      :class="[CLASS.WRAPPER, 'text-primary', placeholderWrapperClass]"
    >
      <path
        fill-rule="evenodd"
        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
        clip-rule="evenodd"
      />
    </svg>
    <!-- /Placeholder logo -->
  </div>
</template>

<script>
import { defineComponent, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { ANIMATION_LOADING } from '../constants'

export default defineComponent({
  name: 'Logo',

  components: {
    Vue3Lottie,
  },

  props: {
    showPlaceholder: { type: Boolean, default: false },
    wrapperClass: { type: String, default: '' },
    placeholderWrapperClass: { type: String, default: '' },

    isSchool: {
      type: Boolean,
      default: true,
    },

    src: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const CLASS = { WRAPPER: 'w-16 h-16', INNER: 'w-full h-full rounded-full' }
    const [parentRef] = useAutoAnimate()

    const settings = computed(() => store.getters['portal/getSettings'])

    const imageSrc = computed(() => {
      if (props.isSchool) {
        return props.src || settings.value?.logo || null
      }

      return props.src || null
    })

    const isUpdatingLogo = computed(
      () => store.getters['portal/isUpdatingLogo'],
    )

    // Logo update isn't registered by Vue as the path never changes -
    // just force refresh on homepage for now
    watch(isUpdatingLogo, async (newVal) => {
      if (!newVal) {
        await router.push({
          name: 'dashboard',
          params: { id: route.params.id },
        })

        window.location.reload()
      }
    })

    return {
      ANIMATION_LOADING,
      CLASS,
      parentRef,
      imageSrc,
      isUpdatingLogo,
    }
  },
})
</script>

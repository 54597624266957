import {
  mapUser,
  mapReport,
  mapAttendee,
  mapMeetingWithAttendees,
} from '../util.js'
import { DRAWER_SVG_PRIMARY } from '../../constants.js'

export function getSchool(state) {
  return state.school
}

export function getSettings(state) {
  return state.settings
}

export function isUpdatingLogo(state) {
  return state.isUpdatingLogo
}

export function getMeetingLocationDefaults(_state, getters) {
  const locations = getters.getSettings?.default_locations
  if (locations) {
    return JSON.parse(locations)
  }

  return []
}

export function getSchools(state) {
  return state.schools
}

export function getClientId(state) {
  return state.school?.id
}

export function getMeetingsRaw(state) {
  return state.meetings
}

export function getMeetings(_state, getters) {
  const meetings = getters.getMeetingsRaw

  if (meetings) {
    return Object.fromEntries(
      Object.entries(meetings).map(([year, yearMeetings]) => [
        year,
        yearMeetings.map(mapMeetingWithAttendees),
      ]),
    )
  }

  return []
}

export function getMeetingsForDrawer(_state, getters) {
  const meetings = getters.getMeetings

  if (meetings) {
    const primaryDrawerItems = Object.entries(meetings).map(
      ([year, yearMeetings]) => {
        return {
          title: year,
          value: parseInt(year),
          iconLeft: DRAWER_SVG_PRIMARY.LEFT,
          iconRight: DRAWER_SVG_PRIMARY.RIGHT,
          secondaryItems: yearMeetings.map((meeting) => {
            return {
              title: meeting.title,
              value: meeting.id,
              date: meeting.date,
              time: meeting.time,
              location: meeting.location_title,
              address: meeting.location_address,
              onlineLink: meeting.location_online,
              attendees: meeting.users,
              files: meeting.files,
            }
          }),
        }
      },
    )

    return primaryDrawerItems
  }

  return []
}

export function getCurrentMeeting(state) {
  const meeting = state.currentMeeting

  if (meeting) {
    return mapMeetingWithAttendees(meeting)
  }

  return null
}

export function getNextMeeting(_state, getters) {
  // Flatten all meetings into a single array
  const meetings = getters.getMeetingsRaw
  if (meetings) {
    const allMeetings = Object.values(meetings).flat()

    // Filter out past meetings
    const futureMeetings = allMeetings.filter(
      (meeting) => new Date(meeting.date) > new Date(),
    )

    // Sort the future meetings by date and return the closest one
    const sortedFutureMeetings = futureMeetings.sort(
      (a, b) => new Date(a.date) - new Date(b.date),
    )

    return sortedFutureMeetings[0] || null
  }

  return null
}

export function getDefaultAttendees(state) {
  return state.defaultAttendees.map(mapAttendee)
}

export function getLibraryMeetingFiles(state) {
  return state.libraryMeetingFiles
}

export function getLibraryResourceFiles(state) {
  return state.libraryResourceFiles
}

export function getLibraryMiscFiles(state) {
  return state.libraryMiscFiles
}

export const getLibraryFilesById = (_state, getters) => (ids) => {
  const meetingFiles = getters.getLibraryMeetingFiles.flatMap(
    (file) => file.files || [],
  )
  const resourceFiles = getters.getLibraryResourceFiles
  const miscFiles = getters.getLibraryMiscFiles
  const allFiles = [...meetingFiles, ...resourceFiles, ...miscFiles]

  // Only keep unique files! Because the same file can be attached to multiple meetings
  // and we don't want it duplicated in the file list.
  const uniqueFiles = new Map()
  allFiles.forEach((file) => {
    if (ids.includes(file.id)) {
      uniqueFiles.set(file.id, file)
    }
  })

  return [...uniqueFiles.values()]
}

export function getReports(state) {
  return state.reports
}

export function getReportsMapped(state) {
  return state.reports?.map(mapReport)
}

export function getReportsByYears(_state, getters) {
  const reports = getters.getReportsMapped
  const years = reports?.reduce(
    (acc, report) => {
      const year = new Date(report.created_at).getFullYear()
      if (!acc[year]) {
        acc[year] = []
      }
      acc[year].push(report)
      return acc
    },
    {
      ALL: reports,
    },
  )

  return years
}

export function getReportsDefaultYear(_state, getters) {
  const ALL_KEY = 'ALL'
  const years = Object.keys(getters.getReportsByYears).filter(
    (val) => val !== ALL_KEY,
  )

  const defaultYear = years.length ? Math.max(...years) : ALL_KEY

  return String(defaultYear)
}

export function getOwners(state) {
  return state.owners
}

export function getOwnersMapped(_state, getters) {
  return getters.getOwners?.map(mapUser)
}

export function getMembers(state) {
  return state.members
}

export function getMembersMapped(_state, getters) {
  return getters.getMembers?.map(mapUser)
}

export const getMember = (_state, getters) => (id) => {
  return getters.getMembersMapped.find((member) => member.id === id) || null
}

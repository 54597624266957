export const isVoting = () => (route) => {
  return route.includes('vote')
}

export const isNominating = () => (route) => {
  return route.includes('nominate')
}

export function getNationalities(state) {
  return state.nationalities
}

export function getNationalitiesMappedForFormKit(_state, getters) {
  return getters.getNationalities?.map((nationality) => ({
    label: nationality.title,
    value: nationality.id,
  }))
}

export function getOptionsGenderMappedForFormKit(state) {
  return state.optionsGender
}

export function getOptionsPriorExperienceMappedForFormKit(state) {
  return state.optionsPriorExperience
}

export function getSchools(state) {
  return state.schools
}

export function getElections(state) {
  return state.elections
}

export function getSchool(state) {
  return state.school
}

export function getSchoolId(state) {
  return state.school?.id
}

export function getSchoolSlug(state) {
  return state.school?.slug
}

export function getSchoolLogo(state) {
  return state.school?.settings?.logo
}

export function getSchoolURL(state) {
  return state.school?.settings?.client_website_url
}

export function getClientId(state) {
  return state.school?.id
}

export function getSchoolElections(state) {
  return state.schoolElections
}

export function hasSchoolElections(_state, getters) {
  return !!getters.getSchoolElections?.length
}

export function hasSchoolElectionsMultiple(_state, getters) {
  return getters.getSchoolElections?.length > 1
}

export function getCurrentElection(state) {
  return state.currentElection
}

export function getCurrentElectionId(state) {
  return state.currentElection?.id
}

export function getCurrentElectionEligibilityTerms(state) {
  return state.currentElection?.eligibility_terms
}

// Voting
export function canView(state) {
  return state.votingData.authorised
}

export function getVoterName(state) {
  return state.votingData.name
}

export function canVote(state) {
  return state.votingData.authorised && !state.votingData.tokenUsed
}

export function getSelectedCandidates(state) {
  return state.selectedCandidates
}

export function remainingCandidateSelections(state) {
  if (state.votingData.tokenUsed) {
    return 0
  }
  return state.currentElection.min_candidates - state.selectedCandidates.length
}

export function canSelectAdditionalCandidate(state) {
  return state.currentElection.min_candidates > state.selectedCandidates.length
}

export function hasVoted(state) {
  return state.votingData.tokenUsed
}

export function attemptsLeft(state) {
  return state.votingData.attemptsLeft
}

export function isTimedOut(state) {
  return state.votingData.timedOut
}

export function accessibilityMode(state) {
  return state.accessibilityMode
}
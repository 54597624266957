<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :content-classes="MODAL_CONFIG.contentClasses"
  >
    <template #content>
      <div class="h-full overflow-scroll no-scrollbar-desktop">
        <div
          v-if="!showResponse"
          class="flex flex-col items-center justify-center"
        >
          <ProfileImage
            :src="candidate.image"
            :show-placeholder="true"
            :alt="`${candidate.first_name} ${candidate.last_name}`"
            :wrapper-class="[
              'w-24 h-24 rounded-full object-cover border-4 transition-all text-white flex items-center justify-center',
            ]"
            :placeholder-wrapper-class="'w-24 h-24'"
          />
          <div :class="['font-bold mt-2 transition-all text-primary']">
            {{ candidate.last_name }}, {{ candidate.first_name }}
          </div>
        </div>

        <div
          :class="[
            'transition-all duration-300 w-full rounded-lg bg-white mt-12',
          ]"
        >
          <div
            v-if="candidate.bio"
            class="text-sm"
            v-html="candidate.bio"
          ></div>
          <p v-if="!candidate.bio" class="text-sm">Statement not provded.</p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import Modal from './Modal.vue'
import ProfileImage from '../../router/routes/elections/ProfileImage.vue'

export default defineComponent({
  name: 'ModalCandidate',

  components: {
    Modal,
    ProfileImage,
  },

  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },

  setup() {
    const store = useStore()

    const accessibilityMode = computed(
      () => store.getters['elections/accessibilityMode'] || null,
    )

    const classes = computed(() =>
      accessibilityMode.value
        ? 'text-white bg-primary p-1 text-xs rounded hover:text-secondary'
        : 'brand__link--tertiary text-xs',
    )

    const MODAL_CONFIG = computed(() => ({
      trigger: {
        text: 'View Profile',
        as: 'a',
        classes: classes.value,
      },

      contentClasses:
        'max-h-[85vh] mobile-modal-width max-w-[750px] lg:overflow-y-auto p-8 lg:p-16 flex flex-col items-center justify-center',
    }))

    const selectedElection = computed(
      () => store.getters['elections/getCurrentElection'],
    )

    return {
      MODAL_CONFIG,
      selectedElection,
    }
  },
})
</script>

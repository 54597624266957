import { API_BASE, get, post } from './api.js'

export async function getNationalities() {
  return await get(`${API_BASE}/elections/get-nationalities`)
}

export async function getSchools() {
  return await get(`${API_BASE}/elections/get-schools`)
}

export async function getElections() {
  return await get(`${API_BASE}/elections/get-elections`)
}

export async function getSchoolElections(clientId) {
  return await get(`${API_BASE}/elections/get-school-elections/${clientId}`)
}

export async function getSchoolElection(clientId, electionId) {
  return await get(
    `${API_BASE}/elections/get-school-election/${clientId}/${electionId}`,
  )
}

export async function upsertCandidate(clientId, electionId, payload) {
  return await post(
    `${API_BASE}/elections/upsert-candidate/${clientId}/${electionId}`,
    payload,
  )
}

export async function authoriseVoter(electionId, payload) {
  return await post(
    `${API_BASE}/elections/${electionId}/authorise-voter`,
    payload,
  )
}

export async function processVote(electionId, payload) {
  return await post(`${API_BASE}/elections/${electionId}/process-vote`, payload)
}

export async function sendContactForm(clientId, electionId, payload) {
  const url = `${API_BASE}/elections/send-contact-form${
    clientId ? `/${clientId}` : ''
  }${electionId ? `/${electionId}` : ''}`

  return await post(url, payload)
}

import {
  getBenchmarkOptions,
  getWelcomeReport,
  submitEnquiry,
} from '../../api/global.js'

export async function dispatchUpdateAlertToast(context, data) {
  const handleError = () => {
    // @todo trigger error messaging
    return false
  }

  try {
    if (data) {
      // Flash alert toast briefly
      context.commit('UPDATE_ALERT_TOAST', data)

      setTimeout(() => {
        context.commit('CLEAR_ALERT_TOAST', data)
      }, 10000)
    } else {
      context.commit('CLEAR_ALERT_TOAST')
    }

  } catch {
    return handleError()
  }
}

export async function dispatchUpdateIsLoading(context, value) {
  const handleError = () => {
    // @todo trigger error messaging
    return false
  }

  try {
    if (value) {
      context.commit('UPDATE_IS_LOADING')
    } else {
      context.commit('CLEAR_IS_LOADING')
    }

  } catch {
    return handleError()
  }
}

export async function dispatchGetBenchmarkOptions() {
  const handleError = () => {
    // @todo trigger error messaging
    return false
  }

  try {
    const response = await getBenchmarkOptions()
    const data = response.data

    if (data?.success) {
      return data
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchGetWelcomeReport(_context, payload) {
  const handleError = () => {
    // @todo trigger error messaging
    return false
  }

  try {
    const response = await getWelcomeReport(payload)
    const data = response.data

    if (data?.success) {
      return data
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchSubmitEnquiry(payload) {
  const handleError = () => {
    // @todo trigger error messaging
    return false
  }

  try {
    const response = await submitEnquiry(payload)
    const data = response.data

    if (data) {
      return data
    }

    return handleError()
  } catch {
    return handleError()
  }
}

<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :content-classes="MODAL_CONFIG.contentClasses"
  >
    <template #content>
      <div class="h-full overflow-scroll no-scrollbar-desktop">
        <div
          :class="[
            'transition-all duration-300 w-full rounded-lg bg-white mt-12',
          ]"
        >
          <div
            v-if="!showResponse && selectedCandidates.length"
            class="flex flex-col items-center justify-center flex-wrap"
          >
            <h4 class="text-xl">Selected candidates:</h4>
            <div
              v-for="candidate in selectedCandidates"
              :key="candidate.id"
              class="flex flex-col items-center justify-center w-full"
            >
              <div :class="['mt-2 transition-all text-primary text-center']">
                {{ candidate.last_name }}, {{ candidate.first_name }}
              </div>
            </div>

            <div class="text-center mt-12">
              <p class="font-bold text-tertiary">
                Are you sure you want to submit your vote for these candidates?
              </p>
              <p class="text-sm">
                Submitting a vote is permanent and cannot be undone. You will
                not be able to change your votes after submitting.
              </p>
              <div class="flex flex-col space-y-4 items-center justify-center">
                <button
                  type="button"
                  class="brand__button--primary space-x-0 mt-4 mb-2"
                  @click.stop="vote"
                >
                  {{ confirmText() }}
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="showResponse"
            class="text-center flex flex-col items-center justify-center gap-4"
          >
            <div
              v-if="responseRef.icon"
              :class="[
                'flex items-center justify-center text-white p-4 rounded-full',
                responseRef?.icon === 'success'
                  ? 'bg-primary text-secondary'
                  : 'bg-red-500 text-white',
              ]"
            >
              <svg
                v-if="responseRef.icon === 'success'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-8 h-8"
              >
                <path
                  fill-rule="evenodd"
                  d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
                  clip-rule="evenodd"
                />
              </svg>
              <svg
                v-if="responseRef.icon === 'failed'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-8 h-8"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <p
              :class="[
                responseRef?.icon === 'success'
                  ? 'text-primary'
                  : 'text-red-500',
                'text-center font-bold',
              ]"
            >
              <span v-if="responseRef?.icon === 'success'">Success!</span
              ><span v-else>Error!</span>
            </p>
            <p
              v-if="responseRef.text"
              class="text-center text-sm text-truegray-500"
            >
              {{ responseRef.text }}.
            </p>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Modal from './Modal.vue'

export default defineComponent({
  name: 'ModalCandidate',

  components: {
    Modal,
  },

  setup() {
    const store = useStore()
    const route = useRoute()

    const MODAL_CONFIG = {
      trigger: {
        text: 'Submit Vote',
        as: 'button',
        classes: 'brand__button--primary',
      },

      contentClasses:
        'max-h-[85vh] mobile-modal-width max-w-[750px] lg:overflow-y-auto p-8 lg:p-16 flex flex-col items-center justify-center',
    }

    const selectedElection = computed(
      () => store.getters['elections/getCurrentElection'],
    )

    const canVote = computed(() => store.getters['elections/canVote'])

    const selectedCandidates = computed(
      () => store.getters['elections/getSelectedCandidates'],
    )

    const confirmText = () => {
      if (selectedCandidates.value.length > 1) {
        return 'Confirm Votes'
      } else {
        return 'Confirm Vote'
      }
    }

    const vote = async () => {
      const payload = {
        candidateIds: selectedCandidates.value.map((candidate) => candidate.id),
        electionId: selectedElection.value.id,
        votingToken: route.query.votingToken,
      }
      const response = await store.dispatch(
        'elections/dispatchAuthorisedVote',
        payload,
      )
      const responseData = response?.data
      if (responseData?.success) {
        responseRef.value.text =
          'Thank you. Your vote(s) have been successfully cast. You can now close this browser window'
        responseRef.value.icon = 'success'
      } else if (!responseData?.success) {
        if (responseData.alert) {
          responseRef.value.text = responseData.alert?.message
        } else if (responseData.timeout) {
          responseRef.value.text =
            'Invalid voting token. You have been timed out. Please try again later, or reach out to us if you feel this is in error'
        }
        responseRef.value.icon = 'failed'
      }

      showResponse.value = true
    }

    const showResponse = ref(false)
    const responseRef = ref({
      text: null,
      icon: null,
    })

    return {
      MODAL_CONFIG,
      vote,
      selectedElection,
      canVote,
      showResponse,
      responseRef,
      selectedCandidates,
      confirmText,
    }
  },
})
</script>

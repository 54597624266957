<template>
  <div class="w-full lg:px-8">
    <AlertToast />

    <!-- Header -->
    <div
      class="w-full max-w-7xl mx-auto px-6 lg:px-0 flex justify-between lg:h-[10vh] text-white"
    >
      <div class="flex-1 flex items-center justify-between gap-x-8 py-4">
        <div class="">
          <!-- @todo logo here? -->
          <p class="text-secondary text-xs m-0">Schooled Elections Manager</p>
        </div>
        <div class="flex items-center justify-end gap-x-8">
          <!-- Skip Links -->
          <nav aria-label="Skip links" class="skip-links">
            <a href="#mainContent" class="sr-only focus:not-sr-only"
              >Skip to main content</a
            >
            <a href="#menu" class="sr-only focus:not-sr-only">Skip to menu</a>
            <a href="#footer" class="sr-only focus:not-sr-only"
              >Skip to footer</a
            >
          </nav>
          <!-- End of Skip Links -->

          <button
            type="button"
            class="sr-only focus:not-sr-only text-xs text-secondary"
            @click="toggleAccessibilityMode"
          >
            Accessibility Mode
          </button>
          <nav id="menu" class="flex items-center justify-end gap-x-8">
            <ContactFormModal>
              <template #trigger>
                <button
                  type="button"
                  aria-label="Contact"
                  class="brand__link--white group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5 block lg:hidden"
                  >
                    <path
                      d="M3 4a2 2 0 0 0-2 2v1.161l8.441 4.221a1.25 1.25 0 0 0 1.118 0L19 7.162V6a2 2 0 0 0-2-2H3Z"
                    />
                    <path
                      d="m19 8.839-7.77 3.885a2.75 2.75 0 0 1-2.46 0L1 8.839V14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V8.839Z"
                    />
                  </svg>

                  <span class="hidden sm:flex group-hover:text-secondary">
                    Contact
                  </span>
                </button>
              </template>
            </ContactFormModal>

            <ModalFindMySchool>
              <template #trigger>
                <button
                  type="button"
                  aria-label="Contact"
                  class="brand__link--white group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5 block lg:hidden"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="hidden sm:flex group-hover:text-secondary">
                    Find My School
                  </span>
                </button>
              </template>
            </ModalFindMySchool>
          </nav>
        </div>

        <!--
          <button
          v-if="isSuperAdmin"
          type="button"
          class="brand__button--secondary"
          @click="routeToAdminArea"
          >
          <span class="hidden sm:flex">Admin Area</span>
          <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-4 h-4"
          >
          <path
          d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"
          />
          <path
          d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"
          />
          </svg>
          </button> 
        -->
      </div>
    </div>
    <!-- /Header -->

    <!-- App view -->
    <div
      :class="[
        'bg-white w-full max-w-7xl mx-auto lg:rounded-3xl overflow-hidden lg:flex',
        hackyViewport ? '' : 'lg:h-[85vh]',
      ]"
    >
      <!--
        <MobileNav
        class="flex lg:hidden"
        /> 
      -->
      <!-- View content -->
      <router-view
        id="mainContent"
        class="w-full overflow-y-scroll z-10 min-h-screen lg:min-h-0"
      />
    </div>
    <!-- /App view -->

    <PageFooter v-if="!isElectionsContext" />
    <ElectionsFooter v-if="isElectionsContext" id="footer" />
  </div>
</template>

<script>
import { defineComponent, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AlertToast from '../../../components/AlertToast.vue'
import PageFooter from '../../../components/PageFooter.vue'
import ElectionsFooter from '../../../router/routes/elections/ElectionsFooter.vue'
import ContactFormModal from './ContactFormModal.vue'
import ModalFindMySchool from './ModalFindMySchool.vue'

export default defineComponent({
  components: {
    AlertToast,
    PageFooter,
    ElectionsFooter,
    ContactFormModal,
    ModalFindMySchool,
  },

  setup() {
    const route = useRoute()
    const store = useStore()

    const hackyViewport = computed(() => route.meta.hackyViewport)

    const isElectionsContext = computed(() =>
      store.getters['global/isElectionsContext'](route.fullPath),
    )
    const isSuperAdmin = computed(() => store.getters['auth/isSuperAdmin'])
    const settings = computed(() => store.getters['portal/getSettings'])
    const selectedSchool = computed(() => {
      return store.getters['portal/getSchool'] || null
    })

    const routeToAdminArea = () => {
      const currentUrl = window.location.href
      let parts = currentUrl.split('/')
      if (parts.length > 4 && parts[3] === 'portal') {
        parts.splice(3, 1) // Remove the 'portal' part
        parts = parts.slice(0, 4) // Keep only the first 4 parts after removal
        parts.splice(3, 0, 'admin-panel/resources/clients')
      }

      window.location.href = parts.join('/')
    }

    /**
     * Always re-get settings! Because several routes/pages
     * require them and this is the most reliable way to keep in sync.
     */
    const dispatchRouteActions = async () => {
      const actions = []
      if (!isElectionsContext.value) {
        actions.push('portal/dispatchGetSettings')
      }

      if (route?.meta?.actions) {
        actions.push(...route.meta.actions)
      }

      store.dispatch('global/dispatchUpdateIsLoading', true)
      await Promise.all(actions.map((action) => store.dispatch(action)))
      store.dispatch('global/dispatchUpdateIsLoading')

      const params = new URLSearchParams(window.location.search)
      if (params.get('accessibility') === 'true') {
        store.dispatch('elections/dispatchUpdateAccessibilityMode', true)
      }
    }

    const toggleAccessibilityMode = () => {
      store.dispatch('elections/dispatchUpdateAccessibilityMode', true)
    }

    const openFindSchoolModal = () => {
      // @todo implement modal + func
    }

    watch(() => [route.name, route.params], dispatchRouteActions)

    onMounted(() => dispatchRouteActions())

    return {
      isElectionsContext,
      isSuperAdmin,
      routeToAdminArea,
      settings,
      selectedSchool,
      openFindSchoolModal,
      hackyViewport,
      toggleAccessibilityMode,
    }
  },
})
</script>

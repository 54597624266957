<template>
  <div
    v-if="showSuccessState"
    class="text-center flex flex-col items-center justify-center gap-4 rounded border border-truegray-300 p-8 bg-white mt-8"
  >
    <div
      :class="[
        'flex items-center justify-center text-white p-4 rounded-full bg-primary text-secondary',
      ]"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-8 h-8"
      >
        <path
          fill-rule="evenodd"
          d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <p :class="['text-center font-bold text-primary']">Success!</p>
    <p class="text-center text-sm text-truegray-500">
      Thanks for your nomination. The team will be in touch to let you know if
      you are accepted as a candidate after review.
    </p>
  </div>
  <FormKit
    v-else
    v-model="form"
    type="form"
    :config="{
      classes: {
        label: 'text-primary',
        legend: 'text-primary font-normal',
        help: 'text-tertiary',
        input: 'w-full',
        outer: '$remove:max-w-[20em]',
      },
    }"
    :submit-attrs="{
      inputClass: '$reset brand__button--tertiary',
    }"
    :actions="false"
    @submit="onSubmit"
  >
    <section v-show="isShowingNominationType" :class="CLASS_SECTION">
      <FormKit
        type="select"
        name="nominationType"
        label="I am nominating..."
        :options="OPTIONS_NOMINATION_TYPES"
        select-icon="caretDown"
        placeholder="Select an option"
        :label-class="LABEL_CLASS"
      />
    </section>

    <section v-show="isShowingNominatorDetails" :class="CLASS_SECTION">
      <h5 class="text-primary mb-3">Your Details</h5>
      <div>
        <FormKit
          id="nFullName"
          type="text"
          name="nFullName"
          label="Your full name *"
          :validation="isNominatingSelf ? '' : 'required'"
          validation-label="Your full name"
          :label-class="LABEL_CLASS"
        />
        <FormKit
          id="nEmail"
          type="email"
          name="nEmail"
          label="Your email *"
          :validation="isNominatingSelf ? '' : 'required|email'"
          validation-label="Your email"
          :label-class="LABEL_CLASS"
        />
      </div>
    </section>

    <section v-show="isShowingCandidateDetails" :class="CLASS_SECTION">
      <h5 class="text-primary mb-3">Candidate (Nominee) Details</h5>
      <div :class="CLASS_GRID">
        <FormKit
          type="text"
          name="cTitle"
          label="Title *"
          placeholder="Mr, Mrs, Ms, etc."
          validation="required"
          validation-label="Title"
          :label-class="LABEL_CLASS"
        />
      </div>
      <div :class="CLASS_GRID">
        <FormKit
          type="text"
          name="cFirstName"
          label="First name *"
          validation="required"
          validation-label="First name"
          :label-class="LABEL_CLASS"
        />
        <FormKit
          type="text"
          name="cLastName"
          label="Last name *"
          validation="required"
          validation-label="Last name"
          :label-class="LABEL_CLASS"
        />

        <hr class="col-span-2 my-4 border-tertiary" />

        <div class="col-span-2">
          <div class="flex items-center w-full">
            <FormKit
              type="file"
              label="Profile image *"
              name="cImage"
              accept=".jpg,.png"
              no-files-icon="fileImage"
              file-item-icon="fileImage"
              file-remove-icon="close"
              help="Ensure the focus of your image is central"
              validation="required"
              :label-class="LABEL_CLASS"
            />
            <ProfileImage
              :src="imageSrc"
              :show-placeholder="true"
              wrapper-class="my-4 mx-8 bg-white w-24 h-24 flex items-center justify-center rounded-full object-cover border-4 transition-all"
            />
          </div>
        </div>

        <FormKit
          type="select"
          name="cGender"
          label="Gender *"
          :options="OPTIONS_GENDER"
          select-icon="caretDown"
          placeholder="Select gender"
          validation="required"
          validation-label="Gender"
          :label-class="LABEL_CLASS"
        />

        <FormKit
          type="checkbox"
          name="cNationalities"
          label="Nationalities *"
          :options="OPTIONS_NATIONALITIES"
          help="Select as many as apply"
          decorator-icon="check"
          validation="required"
          validation-label="Nationality"
          :label-class="LABEL_CLASS"
        />

        <hr class="col-span-2 my-4 border-tertiary" />

        <FormKit
          type="email"
          name="cEmail"
          label="Email address *"
          validation="required|email"
          validation-label="Email address"
          :label-class="LABEL_CLASS"
        />
        <FormKit
          type="tel"
          name="cPhone"
          label="Phone number *"
          validation="required|number"
          validation-label="Phone number"
          :label-class="LABEL_CLASS"
        />

        <div class="col-span-2 flex flex-col">
          <FormKit
            name="cBio"
            label="Bio *"
            :validation-rules="{
              valid: ({ value }) => {
                const strippedContent = value.replace(/<[^>]*>?/gm, '')
                const wordCount = strippedContent.trim().split(/\s+/).length
                return wordCount <= 350 && wordCount > 0
              },
            }"
            :validation-messages="{
              valid: 'Bio is required and must not exceed 350 words.',
            }"
            help="Maximum of 350 words"
            validation="required|valid"
            validation-label="Bio"
            inner-class="$remove:px-3 $remove:py-2"
            outer-class="$remove:mb-4"
            :label-class="LABEL_CLASS"
          >
            <template #input>
              <Editor @update:content="updateEditorContent" />
            </template>
          </FormKit>
          <div class="text-xs text-truegray-500 mt-2">
            {{ wordCount }} / 350 words
          </div>
        </div>

        <FormKit
          type="select"
          name="cPrior"
          :options="OPTIONS_PRIOR_EXPERIENCE"
          select-icon="caretDown"
          label="Prior experience *"
          placeholder="Select prior experience"
          validation="required"
          validation-label="Prior experience"
          :label-class="LABEL_CLASS"
        />

        <hr class="col-span-2 my-4 border-tertiary" />

        <h5 class="col-span-2 text-primary">Address</h5>
        <FormKit
          type="text"
          name="cAddress1"
          label="Address Line 1 *"
          validation="required"
          validation-label="Address Line 1"
          :label-class="LABEL_CLASS"
        />
        <FormKit
          type="text"
          name="cAddress2"
          label="Address Line 2"
          :label-class="LABEL_CLASS"
        />
        <FormKit
          type="text"
          name="cTown"
          label="Town/city *"
          validation="required"
          validation-label="Town/city"
          :label-class="LABEL_CLASS"
        />
        <FormKit
          type="text"
          name="cRegion"
          label="Region *"
          validation="required"
          validation-label="Region"
          :label-class="LABEL_CLASS"
        />
        <FormKit
          type="text"
          name="cPostcode"
          label="Postcode *"
          validation="required|number"
          validation-label="Postcode"
          :label-class="LABEL_CLASS"
        />

        <hr class="col-span-2 my-4 border-primary" />

        <FormKit
          type="checkbox"
          name="cTerms"
          decorator-icon="check"
          validation="accepted"
          validation-label="Eligibility Terms"
          :label-class="LABEL_CLASS"
        >
          <template #label>
            I have read and agree to the

            <EligibilityTermsModal>
              <template #trigger>
                <a class="brand__link--tertiary"> Eligibility Terms </a>
              </template>
            </EligibilityTermsModal>
          </template>
        </FormKit>
      </div>
    </section>

    <div
      :class="[
        'flex gap-2 mt-6 justify-center',
        isLastStep ? 'items-center flex-col' : '',
        isShowingNominatorDetails ? 'items center' : '',
      ]"
    >
      <FormKit
        v-show="isLastStep"
        type="submit"
        label="Submit nomination"
        input-class="$reset brand__button--primary"
        :disabled="isSubmitting"
      >
        <span v-if="isSubmitting" class="mr-1 w-5 h-5">
          <Vue3Lottie :animation-data="ANIMATION_LOADING.DARK" />
        </span>
        Submit nomination
      </FormKit>

      <FormKit
        v-show="currentStep !== STEPS[0]"
        type="button"
        label="Cancel"
        input-class="$reset brand__button--trans-primary"
        outer-class="$remove:grow"
        :label-class="LABEL_CLASS"
        :disabled="isSubmitting"
        @click="prevStep"
      />

      <FormKit
        v-show="!isLastStep"
        type="button"
        label="Next"
        input-class="$reset brand__button--primary"
        :disabled="isNextDisabled"
        @click="nextStep"
      />
    </div>
  </FormKit>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { getNode } from '@formkit/core'
import Editor from './Editor.vue'
import ProfileImage from './ProfileImage.vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../../constants.js'
import EligibilityTermsModal from './EligibilityTermsModal.vue'

export default defineComponent({
  name: 'FormNomination',

  components: {
    Vue3Lottie,
    Editor,
    ProfileImage,
    EligibilityTermsModal,
  },

  setup() {
    const store = useStore()

    // Store/constants
    const OPTIONS_GENDER = computed(
      () => store.getters['elections/getOptionsGenderMappedForFormKit'],
    )

    const OPTIONS_NATIONALITIES = computed(
      () => store.getters['elections/getNationalitiesMappedForFormKit'],
    )

    const OPTIONS_PRIOR_EXPERIENCE = computed(
      () =>
        store.getters['elections/getOptionsPriorExperienceMappedForFormKit'],
    )

    const CLASS_SECTION = 'flex flex-col gap-2 mt-6 mb-4'
    const CLASS_GRID = 'grid grid-cols-2 gap-8 w-full'
    const LABEL_CLASS = 'text-primary font-normal'

    const SELF_KEY = 'self'
    const OPTIONS_NOMINATION_TYPES = [
      {
        label: 'Myself',
        value: SELF_KEY,
      },
      {
        label: 'Someone else',
        value: 'other',
      },
    ]

    // Form/inputs
    const form = ref({
      nominationType: null,
      nEmail: null,
      nFullName: null,
      cTitle: null,
      cFirstName: null,
      cLastName: null,
      cImage: null,
      cGender: null,
      cNationalities: [],
      cEmail: null,
      cPhone: null,
      cBio: null,
      cPrior: null,
      cAddress1: null,
      cAddress2: null,
      cTown: null,
      cRegion: null,
      cPostcode: null,
      cTerms: false,
    })

    const updateEditorContent = (val) => {
      form.value.cBio = val
    }

    const wordCount = computed(() => {
      const strippedContent = form.value.cBio?.replace(/<[^>]*>?/gm, '') || ''
      return strippedContent.trim().split(/\s+/).length
    })

    // Profile image
    const imageInput = computed(() => form.value?.cImage)
    const imageData = computed(() => imageInput.value?.[0] || null)
    const imageFile = computed(() => imageData.value?.file || null)
    const imageSrc = computed(() => {
      // If a new file is selected, preview that.
      if (imageFile.value) {
        return URL.createObjectURL(imageFile.value)
      }

      // Otherwise preview the existing image (or nothing if none exists).
      return typeof imageInput.value === 'string' ? imageInput.value : null
    })

    // Google recaptcha
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const runRecaptcha = async () => {
      // Wait until recaptcha has been loaded
      await recaptchaLoaded()

      // Execute reCAPTCHA with form action
      const action = 'candidate_signup'
      const token = await executeRecaptcha(action)
      return { token, action }
    }

    // Steps
    const isNominatingSelf = computed(
      () => form.value?.nominationType === SELF_KEY,
    )
    const STEP_KEYS = ['nominationType', 'nominatorDetails', 'candidateDetails']

    const STEPS = computed(() => {
      const steps = [STEP_KEYS[0], STEP_KEYS[2]]
      if (!isNominatingSelf.value) {
        steps.splice(1, 0, STEP_KEYS[1])
      }
      return steps
    })

    const currentStep = ref(STEPS.value[0])
    const nextButtonText = computed(() =>
      isLastStep.value ? 'Submit nomination' : 'Next',
    )

    const isShowingNominationType = computed(
      () => currentStep.value === STEP_KEYS[0],
    )

    const isShowingNominatorDetails = computed(
      () => currentStep.value === STEP_KEYS[1],
    )

    const isShowingCandidateDetails = computed(
      () => currentStep.value === STEP_KEYS[2],
    )

    const isLastStep = computed(() => isShowingCandidateDetails.value)

    const isNextDisabled = computed(() => {
      if (currentStep.value === STEP_KEYS[0]) {
        return isSubmitting.value || !form.value.nominationType
      }

      if (currentStep.value === STEP_KEYS[1]) {
        const nFullNameValid = getNode('nFullName')?.context?.state?.valid
        const nEmailValid = getNode('nEmail')?.context?.state?.valid
        return isSubmitting.value || !nFullNameValid || !nEmailValid
      }

      return isSubmitting.value
    })

    const nextStep = () => {
      const currentIndex = STEPS.value.indexOf(currentStep.value)
      if (currentIndex < STEPS.value.length - 1) {
        currentStep.value = STEPS.value[currentIndex + 1]
      }
    }

    const prevStep = () => {
      const currentIndex = STEPS.value.indexOf(currentStep.value)
      if (currentIndex > 0) {
        currentStep.value = STEPS.value[currentIndex - 1]
      }
    }

    // State
    const showSuccessState = ref(false)
    const isSubmitting = ref(false)
    const onSubmit = async () => {
      isSubmitting.value = true
      const recaptchaData = await runRecaptcha()

      const payload = new FormData()
      Object.keys(form.value).forEach((key) => {
        if (key === 'cImage' && imageFile.value) {
          payload.append('cImage', imageFile.value)
        } else if (form.value[key] !== null) {
          payload.append(key, form.value[key])
        }
      })

      payload.append('recaptchaData', JSON.stringify(recaptchaData))

      const success = await store.dispatch(
        'elections/dispatchUpsertCandidate',
        payload,
      )

      if (success) {
        showSuccessState.value = true
      }

      isSubmitting.value = false
    }

    return {
      ANIMATION_LOADING,
      CLASS_SECTION,
      CLASS_GRID,
      LABEL_CLASS,
      OPTIONS_GENDER,
      OPTIONS_NATIONALITIES,
      OPTIONS_PRIOR_EXPERIENCE,
      OPTIONS_NOMINATION_TYPES,
      STEPS,
      currentStep,
      isNextDisabled,
      isLastStep,
      nextButtonText,
      nextStep,
      prevStep,

      form,
      wordCount,
      imageSrc,
      isNominatingSelf,
      isShowingNominationType,
      isShowingNominatorDetails,
      isShowingCandidateDetails,

      updateEditorContent,
      showSuccessState,
      isSubmitting,
      onSubmit,
    }
  },
})
</script>

<style>
.grecaptcha-badge {
  z-index: 100;
}
</style>

<template>
  <div class="h-screen flex bg-white">
    <div class="flex min-h-full flex-1">
      <div
        class="w-full flex flex-1 flex-col items-center sm:items-start sm:justify-center px-8 py-12 sm:px-12 lg:flex-none lg:px-20 xl:px-24"
      >
        <img
          src="/img/logo/schooled-logo-inverted.svg"
          alt="Schooled Logo"
          class="w-80 h-80 sm:hidden"
        />

        <div class="w-full z-10 relative space-y-4 max-w-sm">
          <div class="flex flex-col items-center sm:items-start gap-3 mb-10">
            <h3 class="leading-10 text-center sm:text-left">
              Welcome to the Schooled App
            </h3>
            <span class="italic">Which type of user are you?</span>
          </div>
          <div class="flex items-center justify-center flex-col space-y-4">
            <a class="w-full" href="/admin">
              <button
                class="brand__button--tertiary w-full p-4 !h-16"
                type="button"
              >
                I'm a Schooled Admin
              </button>
            </a>
            <div class="w-1/4 h-px bg-truegray-300"></div>
            <router-link :to="{ name: 'signIn' }" class="w-full">
              <button
                class="brand__button--tertiary w-full p-4 !h-16"
                type="button"
              >
                I'm a Schooled Client
              </button>
            </router-link>
          </div>
        </div>
        <!-- Show the enquiry form -->
      </div>
    </div>
    <div
      class="hidden w-0 flex-1 sm:flex items-center justify-center w-full h-full bg-primary"
    >
      <img
        src="/img/logo/schooled-logo-trans.svg"
        alt="Schooled Logo"
        class="w-80 h-80"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ANIMATION_LOADING } from '../../constants.js'

export default defineComponent({
  setup() {
    return {
      ANIMATION_LOADING,
    }
  },
})
</script>

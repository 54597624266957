<template>
  <div class="w-full">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
      <div class="px-4 sm:px-0">
        <h6>Meeting Defaults</h6>
        <p class="mt-1 text-sm">
          Make meeting management easy by storing default options to quickly
          populate each meeting.
        </p>
      </div>
      <div
        class="bg-white shadow-sm sm:rounded-xl md:col-span-2 px-4 py-6 sm:p-8"
      >
        <h5 class="text-h6 mb-4">Locations</h5>
        <div
          v-if="defaultLocations.length"
          ref="meetingsParent"
          class="flex flex-col space-y-2 mb-6"
        >
          <div
            v-for="(item, index) in defaultLocations"
            :key="item.title"
            class="flex flex-col hover:opacity-100 transition-all"
            :class="!item.default ? 'opacity-60' : 'opacity-100'"
          >
            <div class="flex space-x-2 items-center mb-1">
              <p class="font-bold mb-0">
                {{ item.title }}
              </p>
              <div v-if="item.default" class="text-tertiary flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-4 mb-1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <p class="text-tertiary mb-0 text-xs ml-2">(default)</p>
              </div>
            </div>
            <p v-if="item.address" class="text-truegray-500 italic mb-0">
              {{ item.address }}
            </p>
            <div class="flex items-center justify-end space-x-4">
              <button
                v-if="!item.default"
                type="button"
                class="brand__button--primary-tertiary text-xs py-1 px-3 mb-2 h-8"
                @click="() => markDefault(index)"
              >
                Mark as Default
              </button>

              <button
                type="button"
                class="brand__button bg-primary text-white hover:bg-red-400 text-xs py-1 px-3 mb-2 h-8"
                @click="() => deleteLocation(index)"
              >
                Delete
              </button>
            </div>
            <div
              v-if="index < defaultLocations.length - 1"
              class="w-full h-px bg-truegray-300 my-2"
            ></div>
          </div>
        </div>
        <div v-else class="">
          <p class="text-truegray-500">
            No default locations set. Get started by adding one below!
          </p>
        </div>
        <button
          type="button"
          class="rounded-full p-2 bg-primary hover:bg-tertiary text-white flex items-center justify-center"
          @click="toggleForm"
        >
          <svg
            v-if="!formVisible"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-3 h-3"
          >
            <path
              d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"
            />
          </svg>

          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-3 h-3"
          >
            <path
              fill-rule="evenodd"
              d="M4 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H4.75A.75.75 0 0 1 4 10Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div ref="formParent">
          <div v-if="formVisible" class="mt-8">
            <FormKit id="addLocationForm" type="form" @submit="onSubmit">
              <FormKit
                type="text"
                name="title"
                label="Meeting location title (required)"
                validation="required|length:8"
              />

              <FormKit
                type="text"
                name="address"
                label="Meeting location address (optional)"
              />

              <FormKit
                type="checkbox"
                label="Mark as default"
                help="The default location will be selected automatically when creating new meetings"
                name="default"
                decorator-icon="check"
                :value="false"
                outer-class="pb-6"
              />
            </FormKit>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { reset } from '@formkit/core'
import { ANIMATION_LOADING } from '../../constants.js'
import { useAutoAnimate } from '@formkit/auto-animate/vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const [meetingsParent] = useAutoAnimate()
    const [formParent] = useAutoAnimate()

    const defaultLocations = computed(
      () => store.getters['portal/getMeetingLocationDefaults'],
    )

    const updateDefaultStatus = (locations, index) => {
      return locations.map((item, i) => ({
        ...item,
        default: i === index,
      }))
    }

    const markDefault = async (index) => {
      const locationsCopy = updateDefaultStatus(defaultLocations.value, index)
      // Send locationsCopy to the backend
      await submitJSON(locationsCopy)
    }

    const deleteLocation = async (index) => {
      const locationsCopy = [...defaultLocations.value]
      locationsCopy.splice(index, 1)
      // Send locationsCopy to the backend
      await submitJSON(locationsCopy)
    }

    const formVisible = ref(false)

    const toggleForm = () => {
      formVisible.value = !formVisible.value
    }

    const onSubmit = async (data) => {
      let locationsCopy = [...defaultLocations.value, data]
      if (data.default) {
        locationsCopy = updateDefaultStatus(
          locationsCopy,
          locationsCopy.length - 1,
        )
      }
      await submitJSON(locationsCopy)
      reset('addLocationForm')
      formVisible.value = false
    }

    const submitJSON = async (array) => {
      const payload = JSON.stringify(array)
      await store.dispatch(
        'portal/dispatchUpdateMeetingLocationDefaults',
        payload,
      )
    }

    return {
      ANIMATION_LOADING,
      isAdmin,
      isLoading,

      meetingsParent,
      formParent,

      formVisible,
      toggleForm,

      defaultLocations,
      markDefault,
      deleteLocation,
      onSubmit,
    }
  },
})
</script>

<template>
  <main class="bg-gray-primary">
    <PageHeader title="Board Resources">
      <template #desc>
        <div class="w-full pb-4">
          <p class="text-sm mb-0">
            Files that will be used regularly in meetings can be uploaded as
            Board Resources.
          </p>
          <p class="text-sm mb-0">
            For example, your Strategic Plan, Annual Plan or Karakia.
          </p>
        </div>
      </template>
    </PageHeader>

    <PageContent>
      <template #default>
        <template v-if="isAdmin">
          <ModalFileLibrary :is-board-resources-context="true" />

          <hr class="mt-6 mb-8" />
        </template>

        <div ref="parentRef" :class="['w-full']">
          <div v-if="isLoading" class="mx-auto w-20 h-20">
            <Vue3Lottie :animation-data="ANIMATION_LOADING.DARK" />
          </div>
          <FilesList
            v-else-if="hasFiles"
            :files="files"
            :is-board-resources-context="true"
          />
          <div v-else>
            <span class="brand__text--info">No board resources</span>
          </div>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import ModalFileLibrary from '../../components/modal/ModalFileLibrary.vue'
import FilesList from '../../components/FilesList.vue'

export default defineComponent({
  components: {
    Vue3Lottie,
    PageHeader,
    PageContent,
    ModalFileLibrary,
    FilesList,
  },

  setup() {
    const store = useStore()
    const isLoading = computed(() => store.getters['global/isLoading'])

    const [parentRef] = useAutoAnimate()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])

    const files = computed(
      () => store.getters['portal/getLibraryResourceFiles'],
    )
    const hasFiles = computed(() => files.value?.length)

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      parentRef,
      files,
      hasFiles,
    }
  },
})
</script>

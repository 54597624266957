<template>
  <div class="p-10">
    <h3 v-if="!results & !voting" :class="['mb-8 text-center']">Candidates</h3>
    <div v-if="voting" class="">
      <h4 class="text-center mb-4 text-xl text-truegray-500">
        Select candidate(s)
      </h4>
      <div
        class="mb-6 py-2 px-4 rounded-full bg-primary text-center w-[max-content] flex items-center justify-center mx-auto"
      >
        <p class="text-white text-xs font-bold m-0">
          Remaining Candidate Selections:
          <span class="text-bold text-sm text-secondary">{{
            remainingCandidateSelections
          }}</span>
        </p>
      </div>
    </div>
    <h3 v-if="results" class="mb-8 text-center lg:text-left">
      Election Results:
    </h3>
    <div
      v-if="sortedCandidates"
      :class="[
        'grid',
        voting
          ? 'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4'
          : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
      ]"
    >
      <div
        v-for="candidate in sortedCandidates"
        :key="candidate.id"
        :class="[
          'flex flex-col items-center transition-all duration-300 rounded-lg cursor-pointer',
          isCandidateSelected(candidate) ? '' : 'hover:bg-truegray-100',
          voting ? 'p-4' : 'p-8',
        ]"
        role="button"
        tabindex="0"
        @click="() => toggleSelectedCandidates(candidate)"
      >
        <div class="relative" role="button" tabindex="0">
          <ProfileImage
            :src="candidate.image"
            :show-placeholder="true"
            :alt="`${candidate.first_name} ${candidate.last_name}`"
            :wrapper-class="[
              'w-24 h-24 rounded-full object-cover border-4 transition-all flex items-center justify-center',
              isCandidateSelected(candidate)
                ? 'text-white border-secondary'
                : 'border-white',
            ]"
            :placeholder-wrapper-class="'w-24 h-24'"
            role="button"
            tabindex="0"
          />
          <div
            v-if="candidate.elected || isCandidateSelected(candidate)"
            class="absolute right-2 top-0 w-6 h-6 bg-primary rounded-full flex items-center justify-center text-secondary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-3 h-3"
            >
              <path
                fill-rule="evenodd"
                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>

        <div
          :class="[
            'font-bold transition-all text-center flex flex-col space-y-2',
            candidate.elected ? 'mt-1' : 'mt-2',
            isCandidateSelected(candidate) ? '' : '',
          ]"
        >
          <div
            v-if="candidate.elected"
            class="bg-primary px-2 font-bold text-secondary text-xs rounded w-[max-content] mx-auto"
          >
            Elected
          </div>
          {{ candidate.last_name }}, {{ candidate.first_name }}
        </div>
        <div class="flex items-center justify-center mt-2" @click.stop>
          <ModalCandidate :candidate="candidate" :voting="voting" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import ModalCandidate from '../modal/ModalCandidate.vue'
import ProfileImage from '../../router/routes/elections/ProfileImage.vue'

export default defineComponent({
  name: 'ListCandidates',

  components: {
    ModalCandidate,
    ProfileImage,
  },

  props: {
    candidates: {
      type: Array,
      required: true,
    },

    voting: {
      type: Boolean,
      default: false,
    },

    results: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore()
    const sortedCandidates = ref([...props.candidates])

    const selectedCandidates = computed(
      () => store.getters['elections/getSelectedCandidates'],
    )

    const remainingCandidateSelections = computed(
      () => store.getters['elections/remainingCandidateSelections'],
    )

    const isCandidateSelected = (candidate) => {
      return selectedCandidates.value.some((c) => c.id === candidate.id)
    }

    const canVote = computed(() => store.getters['elections/canVote'])

    const toggleSelectedCandidates = (candidate) => {
      if (props.voting && canVote) {
        store.dispatch('elections/toggleSelectedCandidates', candidate)
      }
    }

    onMounted(() => {
      if (props.results) {
        sortedCandidates.value.sort((a, b) => b.elected - a.elected)
      }
    })

    return {
      sortedCandidates,
      selectedCandidates,
      toggleSelectedCandidates,
      remainingCandidateSelections,
      canVote,
      isCandidateSelected,
    }
  },
})
</script>

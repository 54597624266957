<template>
  <main class="bg-gray-primary">
    <PageHeader title="Portal Help & Guides" />

    <PageContent>
      <template #default>
        <div class="w-full mb-16">
          <h5 class="mb-8">Walkthrough</h5>
          <video width="1000" class="w-full rounded-xl" controls>
            <source
              src="https://cessl-app.s3.ap-southeast-2.amazonaws.com/all-resources/Schooled+Portal+Walkthrough.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </div>

        <div class="w-full mb-12">
          <h5 class="mb-4">Written Guide</h5>
          <a
            v-if="isAdmin"
            href="https://cessl-app.s3.ap-southeast-2.amazonaws.com/all-resources/How+To+Use+The+Schooled+Board+Portal+-+For+Administrators.pdf"
            rel="noopener noreferrer"
            target="_blank"
            class="rounded-xl border border-primary hover:border-secondary transition-all bg-primary cursor-pointer p-4 w-max flex flex-col group gap-4 mb-4"
          >
            <p
              class="text-bold text-white m-0 text-sm group-hover:text-white hover:text-white"
            >
              How To Use The Schooled Board Portal - For Administrators
            </p>
            <p
              class="text-xs group-hover:text-secondary hover:text-secondary text-secondary m-0"
            >
              Read more
            </p>
          </a>
          <a
            href="https://cessl-app.s3.ap-southeast-2.amazonaws.com/all-resources/How+To+Use+The+Schooled+Board+Portal+-+For+Board+Members.pdf"
            rel="noopener noreferrer"
            target="_blank"
            class="rounded-xl border border-primary hover:border-secondary transition-all bg-primary cursor-pointer p-4 w-max flex flex-col group gap-4"
          >
            <p
              class="text-bold text-white m-0 text-sm group-hover:text-white hover:text-white"
            >
              How To Use The Schooled Board Portal - For Members
            </p>
            <p
              class="text-xs group-hover:text-secondary hover:text-secondary text-secondary m-0"
            >
              Read more
            </p>
          </a>
        </div>

        <div class="w-full mb-12">
          <h5 class="mb-4">
            Got a question or need assistance? We're here to help!
          </h5>
          <p class="text-xs">
            For technical assistance or support, contact us at
            <a href="mailto:help@digitaljourney.org" class="text-xs"
              >help@digitaljourney.org</a
            >
          </p>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ANIMATION_LOADING } from '../../constants.js'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'

export default defineComponent({
  name: 'PortalHelp',

  components: {
    PageHeader,
    PageContent,
  },

  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const route = useRoute()

    const cards = []

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      route,
      cards,
    }
  },
})
</script>

<template>
  <!-- Static sidebar for desktop -->
  <div class="hidden lg:block lg:w-[18rem] 3xl:w-96">
    <div
      class="lg:justify-between lg:h-full lg:flex lg:w-96 lg:flex-col shadow-lg"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto p-6">
        <div class="flex py-4 shrink-0 items-center w-full space-x-4 relative">
          <Logo :show-placeholder="true" />

          <div class="flex flex-col w-full">
            <h6 v-if="selectedSchoolName" class="text-primary">
              {{ selectedSchoolName }}
            </h6>
            <a
              v-if="canSelectSchool"
              class="w-full flex justify-between items-center mt-1"
              @click="toggleSchoolSelection"
            >
              <span class="text-sm">Switch school</span>
              <svg-icon
                size="16"
                icon-name="arrow"
                aria-hidden="true"
                :class="{ 'transition rotate-180': isSelectingSchool }"
              />
            </a>
            <div
              v-if="isSelectingSchool"
              class="absolute right-0 top-20 mt-2 mx-0 w-full bg-white border border-gray-secondary rounded-lg shadow-lg overflow-hidden"
            >
              <ul
                ref="schoolsListRef"
                class="max-h-44 w-full overflow-y-scroll"
              >
                <li
                  v-for="item in schools"
                  :key="item.uuid"
                  class="flex flex-row gap-1 items-center border-b border-gray-secondary group py-1 px-4 cursor-pointer"
                  :class="{
                    'bg-gray-secondary': isSelectedSchool(item.id),
                  }"
                >
                  <a
                    :class="[
                      'w-full text-xs font-normal',
                      isSelectedSchool(item.id)
                        ? 'brand__link--tertiary'
                        : 'brand__link--primary',
                    ]"
                    @click="() => selectSchool(item.id)"
                  >
                    {{ item.name }}
                  </a>
                  <svg
                    v-if="item.is_user_school"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-4 h-4 text-tertiary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </li>
              </ul>
              <div
                v-if="schools.length > 6"
                class="flex p-2 justify-center cursor-pointer"
                @click="scrollSchoolsList"
              >
                <svg-icon
                  aria-hidden="true"
                  size="16"
                  icon-name="arrow"
                  class="animate-bounce text-tertiary hover:text-primary"
                />
              </div>
            </div>
          </div>
        </div>

        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navRoutes" :key="item.name">
                  <router-link
                    :to="{ name: item.name, params: { id: route.params.id } }"
                    :class="[
                      'brand__link--primary group flex items-center gap-x-4 rounded-md px-6 py-4',
                      {
                        'bg-gray-primary': isCurrentRoute(item),
                      },
                    ]"
                  >
                    <svg-icon
                      size="16"
                      :icon-name="item.meta?.navConfig?.icon"
                      aria-hidden="true"
                      :class="[
                        'shrink-0',
                        {
                          'text-primary': isCurrentRoute(item),
                        },
                      ]"
                    />
                    {{ item.meta?.navConfig?.name }}
                  </router-link>
                </li>

                <!-- <ModalVideo /> -->
              </ul>
            </li>
            <li class="mt-auto relative">
              <div
                :class="
                  servicesOpen
                    ? 'h-56 py-2 opacity-100'
                    : 'h-0 p-0 overflow-hidden pointer-events-none top-2 opacity-0'
                "
                class="flex flex-col justify-center space-y-2 px-2 transition-all z-10 absolute bottom-[150%] left-0 w-full bg-white border-t-2 border-b-2 border-primary"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/financial-services#accounts"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Accounting Services</a
                >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/financial-services#payroll"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Payroll Services</a
                >

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/services/elections/"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Elections</a
                >

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/board-governance-support#assets"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Fixed Asset Stocktakes</a
                >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/board-governance-support#consulting"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Board Secretarial Services</a
                >

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/training-and-events/"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Training & Conferences</a
                >

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/board-governance-support#consulting"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Consulting Services</a
                >

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.schooled.nz/board-governance-support#consulting"
                  class="text-primary hover:text-tertiary text-xs font-base"
                  >Principal Appointment</a
                >
              </div>
              <a
                class="brand__link--primary flex items-center z-20 pl-1"
                @click="toggleServices"
              >
                <svg
                  :class="servicesOpen ? '' : 'rotate-180'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 mr-4 transform transition-transform"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                  />
                </svg>
                <span>Other Services</span>
              </a>
            </li>
            <li
              class="brand__link--primary -mx-6 border-b border-t px-6 py-6 flex justify-between items-center"
              :class="[
                {
                  'bg-gray-primary': isCurrentRoute({ name: KEY_SETTINGS }),
                },
              ]"
            >
              <router-link
                :to="{ name: 'settings', params: { id: route.params.id } }"
                class="brand__link--primary flex items-center gap-x-3 text-sm group"
              >
                <div
                  class="flex justify-center items-center shrink-0 h-8 w-8 rounded-full bg-primary group-hover:bg-tertiary"
                >
                  <span class="font-bold text-white text-sm">
                    {{ selectedSchoolName?.slice(0, 2) }}
                  </span>
                </div>
                <span> Settings </span>
              </router-link>
            </li>

            <li class="flex justify-between items-center h-px pb-2">
              <div class="brand__text--info flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4 mr-2 flex-shrink-0"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span
                  class="text-sm max-w-[12rem] truncate overflow-ellipsis pr-4"
                >
                  {{ user.name }}
                </span>
              </div>
              <a
                v-if="!isSuperAdmin"
                title="Sign out"
                class="brand__link--gray flex items-center"
                @click="signOut"
              >
                <svg-icon size="24" icon-name="signout" aria-hidden="true" />
                <span class="sr-only">Sign Out</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Logo from './Logo.vue'

export default defineComponent({
  components: {
    Logo,
  },

  setup() {
    const store = useStore()
    const isSuperAdmin = store.getters['auth/isSuperAdmin']
    const user = store.getters['auth/getUser']

    const router = useRouter()
    const route = useRoute()
    const navRoutes = router
      .getRoutes()
      .filter((route) => route.meta?.navConfig)
      .sort(
        (a, b) =>
          a.meta?.navConfig?.displayOrder - b.meta?.navConfig?.displayOrder,
      )
    const KEY_SETTINGS = 'settings'

    const isCurrentRoute = (item) => {
      // Nested routes (e.g., Meetings -> Meeting): check if the first/immediate parent route matches
      // this nav item - the parent is the penultimate entry in the matched routes array.
      const matchedRoutes = route.matched
      const penultimateIndex = matchedRoutes.length - 2
      const parentRoute = matchedRoutes[penultimateIndex]
      if (parentRoute && parentRoute.name === item.name) {
        return true
      }

      return route.name === item.name
    }

    const schoolsListRef = ref(null)
    const isSelectingSchool = ref(false)

    const schools = computed(() => {
      const schools = store.getters['portal/getSchools']
      const schoolsSorted = schools.sort((a, b) => {
        if (a.is_user_school === b.is_user_school) {
          // If both have the same is_user_school value, sort alphabetically
          return a.name.localeCompare(b.name)
        } else if (a.is_user_school) {
          // If a.is_user_school is true, a should come first
          return -1
        } else {
          // If b.is_user_school is true, b should come first
          return 1
        }
      })

      const lastUserSchool =
        schoolsSorted.findLast((school) => !!school.is_user_school) || null
      if (lastUserSchool) {
        lastUserSchool.last_user_school = true
      }

      return schoolsSorted
    })

    const selectedSchool = computed(() => {
      return store.getters['portal/getSchool'] || null
    })

    const selectedSchoolSettings = computed(() => {
      return store.getters['portal/getSettings'] || null
    })

    const selectedSchoolId = computed(() => {
      return store.getters['portal/getClientId']
    })

    const selectedSchoolName = computed(() => {
      return selectedSchool.value?.name
    })

    const canSelectSchool = computed(() => {
      return schools.value.length > 1
    })

    const isSelectedSchool = (id) => {
      return id === selectedSchoolId.value
    }

    const scrollSchoolsList = () => {
      const scrollOptions = {
        top: schoolsListRef.value.scrollTop + 150,
        behavior: 'smooth',
      }
      schoolsListRef.value.scrollTo(scrollOptions)
    }

    const toggleSchoolSelection = () => {
      isSelectingSchool.value = !isSelectingSchool.value
    }

    const selectSchool = async (id) => {
      if (id !== selectedSchoolId.value) {
        await store.dispatch('portal/dispatchSetSchool', id)

        // reroute to update params and refresh school data
        router.push({ name: 'dashboard', params: { id } })
      }

      toggleSchoolSelection()
    }

    const signOut = async () => {
      await store.dispatch('portal/dispatchSignOut')
      router.push({ path: '/' })
    }

    const servicesOpen = ref(false)

    const toggleServices = () => {
      servicesOpen.value = !servicesOpen.value
    }

    return {
      isSuperAdmin,
      user,
      route,
      navRoutes,
      KEY_SETTINGS,
      isCurrentRoute,
      schools,
      schoolsListRef,
      selectedSchool,
      selectedSchoolSettings,
      selectedSchoolId,
      selectedSchoolName,
      isSelectedSchool,
      canSelectSchool,
      isSelectingSchool,
      scrollSchoolsList,
      toggleSchoolSelection,
      selectSchool,
      signOut,
      servicesOpen,
      toggleServices,
    }
  },
})
</script>

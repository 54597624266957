import global from './global'
import auth from './auth'
import portal from './portal'
import { createStore } from 'vuex'

const store = createStore({
  modules: {
    global,
    auth,
    portal,
  },
})

export default store

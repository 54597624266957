import { API_BASE, API_BASE_AUTH, get, post } from './api.js'

export async function getUser() {
  return get(`${API_BASE}${API_BASE_AUTH}/user`)
}

export async function resetPassword(payload) {
  return post(`${API_BASE}${API_BASE_AUTH}/reset-password`, payload)
}

export async function validateResetPassword(payload) {
  return post(`${API_BASE}${API_BASE_AUTH}/validate-reset-password`, payload)
}

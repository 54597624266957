<template>
  <div
    class="w-full flex justify-between items-center lg:h-[5vh] max-w-7xl py-4 px-6 mx-auto"
  >
    <span class="text-white text-xs">
      © {{ new Date().getFullYear() }} Schooled Elections Manager. Designed in
      partnership with
      <a
        :href="LINKS.HAIL"
        target="_blank"
        rel="noopener noreferrer"
        class="brand__link--white-secondary underline text-xs"
        aria-label="Hail (opens in a new tab)"
      >
        Hail
      </a>
    </span>
    <div class="flex space-x-4">
      <ModalElectionPrivacy />
      <ModalElectionTerms />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ModalElectionTerms from '../../../components/modal/ModalElectionTerms.vue'
import ModalElectionPrivacy from '../../../components/modal/ModalElectionPrivacy.vue'
import { LINKS } from '../../../constants.js'

export default defineComponent({
  name: 'PageFooter',

  components: {
    ModalElectionTerms,
    ModalElectionPrivacy,
  },

  setup() {
    return {
      LINKS,
    }
  },
})
</script>
